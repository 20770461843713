import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Params } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { AccountTypeCode, HoldingTypeEnum } from '@app/etfs-equities/enums';
import { TweOpenOrdersOrderStatusCodeTypesEnum } from '@app/etfs-equities/enums/open-orders/open-orders.enum';
import { QueryParamsForTransactionType } from '@app/etfs-equities/enums/order.enums';
import { Account, JsonContent, OpenOrder } from '@app/etfs-equities/models';
import { TradeTicketService, WindowService } from '@app/etfs-equities/services';
import { selectAccountIsMargin, TayneState } from '@app/etfs-equities/store';
import { HoldingsUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import {
  DetailType,
  NotificationContentType,
  NotificationDisplayType,
  ProductListTableButtonDetails,
  ProductListTableColumnDescription,
  ProductListTableLinkDetails,
  ProductListTableNotificationDetails,
  ProductListTableRow,
  ProductListTableRowButtonType,
  ProductListTableRowContentStyle,
  ProductListTableTextDetails,
  ResponsiveBreakpoint,
} from '@vanguard/trade-ui-components-lib-ng-18';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'twe-holdings-table',
  templateUrl: './holdings-table.component.html',
  styleUrl: './holdings-table.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingsTableComponent implements OnInit, OnDestroy {
  // Private variables...
  private currentPage: number = null;
  private readonly unsubscribe$ = new Subject<void>();

  // Public variables...
  @Input({ required: true }) title: string;
  @Input({ required: true }) holdings: Account.MappedHolding[];
  @Input({ required: true }) selectedAccount: Account.Account;
  @Input({ required: true }) openOrders: OpenOrder[];

  @Output()
  openMutualFundModal = new EventEmitter<Account.MappedHolding>();

  @Output()
  openOrderInProgressModal = new EventEmitter<Params>();

  content: JsonContent = content;
  columnDescriptions: ProductListTableColumnDescription[];
  isBeacon: boolean;

  // Observables
  accountIsMargin$: Observable<boolean>;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly windowService: WindowService,
    private readonly tradeTicketService: TradeTicketService
  ) {}

  ngOnInit(): void {
    this.columnDescriptions = this.getColumnDescriptions(this.content.holdings.tableHeaders);
    this.isBeacon = this.windowService.getIsBeacon();

    this.accountIsMargin$ = this.store.select(selectAccountIsMargin);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePaginationChange(event: number, header: HTMLElement): void {
    // skip init call
    if (this.currentPage && this.currentPage !== event) {
      header?.scrollIntoView({ behavior: 'smooth' });
    }
    this.currentPage = event;
  }

  private onClickedSubject(callback: () => void): Subject<void> {
    const onClickedSubject$ = new Subject<void>();
    onClickedSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      callback();
    });

    return onClickedSubject$;
  }

  private getActions(holding: Account.MappedHolding, holdingType: HoldingTypeEnum): ProductListTableButtonDetails[] {
    switch (holdingType) {
      case HoldingTypeEnum.ETF:
      case HoldingTypeEnum.STOCK:
        return [
          {
            detailType: DetailType.BUTTON,
            buttonType: ProductListTableRowButtonType.BUTTON,
            labelText: this.content.labels.buy,
            onClicked: this.onClickedSubject(() => {
              this.checkForNavigateToTradeTicket(QueryParamsForTransactionType.BUY, holding.symbol);
            }),
          },
          {
            detailType: DetailType.BUTTON,
            buttonType: ProductListTableRowButtonType.BUTTON,
            labelText: this.content.labels.sell,
            onClicked: this.onClickedSubject(() => {
              this.checkForNavigateToTradeTicket(QueryParamsForTransactionType.SELL, holding.symbol);
            }),
          },
        ];
      case HoldingTypeEnum.MUTUAL_FUND:
        return this.isBeacon
          ? []
          : [
              {
                detailType: DetailType.BUTTON,
                buttonType: ProductListTableRowButtonType.BUTTON,
                labelText: this.content.labels.trade,
                onClicked: this.onClickedSubject(() => {
                  this.openMutualFundModal.emit(holding);
                }),
              },
            ];
      case HoldingTypeEnum.BOND:
      case HoldingTypeEnum.CD:
        return this.isBeacon
          ? []
          : [
              {
                detailType: DetailType.BUTTON,
                buttonType: ProductListTableRowButtonType.BUTTON,
                labelText: this.content.labels.trade,
                onClicked: this.onClickedSubject(() => {
                  this.navigateToFixedIncomeTrading(holding, holdingType);
                }),
              },
            ];
      case HoldingTypeEnum.OPTION:
        return this.isBeacon
          ? []
          : [
              {
                detailType: DetailType.BUTTON,
                buttonType: ProductListTableRowButtonType.BUTTON,
                labelText: this.content.labels.buy,
                onClicked: this.onClickedSubject(() => {
                  this.navigateToOptions(QueryParamsForTransactionType.BUY, holding);
                }),
              },
              {
                detailType: DetailType.BUTTON,
                buttonType: ProductListTableRowButtonType.BUTTON,
                labelText: this.content.labels.sell,
                onClicked: this.onClickedSubject(() => {
                  this.navigateToOptions(QueryParamsForTransactionType.SELL, holding);
                }),
              },
            ];
      default:
        return [];
    }
  }

  getTableRows(isMarginAccount: boolean): ProductListTableRow[] {
    this.currentPage = null;
    return (
      this.holdings?.map((holding) => {
        const holdingType = HoldingsUtil.getHoldingType(holding.raw);
        const actions = this.getActions(holding, holdingType);

        return {
          cells: [
            {
              lines: [
                {
                  details: this.getHoldingDetails(holding, holdingType),
                },
              ],
            },
            {
              lines: [
                {
                  details: this.getCurrentPositionDetails(holding, isMarginAccount),
                },
              ],
            },
            {
              lines: [
                {
                  textAlignRight: true,
                  details: [...actions],
                },
              ],
            },
          ],
        };
      }) || []
    );
  }

  private navigateToFixedIncomeTrading(holding: Account.MappedHolding, holdingType: HoldingTypeEnum) {
    const url = `${environment.fixedIncomeTradingUrl}/router?accountId=${this.selectedAccount.accountId}&cusip=${holding.raw.cusip}&holdingKey=${holding.raw.positionId}&investmentType=${holdingType}`;

    this.windowService.navigateToExternalLink(url, '_blank');
  }

  private checkForNavigateToTradeTicket(transactionType: QueryParamsForTransactionType, symbol: string) {
    const queryParams: Params = {
      accountId: this.selectedAccount.accountId,
      ticker: symbol,
      transactionType: transactionType,
    };
    if (this.tradeTicketService.isOrderInProgress()) {
      this.openOrderInProgressModal.emit(queryParams);
    } else {
      this.navigateToTradeTicket(queryParams);
    }
  }

  private navigateToTradeTicket(queryParams: Params) {
    this.tradeTicketService.clearForm();

    this.windowService.router.navigate([CONSTANTS.TRADE_PATH], {
      queryParams,
    });
  }

  private navigateToOptions(transactionType: QueryParamsForTransactionType, holding: Account.MappedHolding) {
    const url = `${environment.bsrPath}?investmentType=OPTION&accountId=${this.selectedAccount.accountId}&ticker=${holding.symbol}&transactionType=${transactionType}&HldId=${holding.raw.positionId}`;

    this.windowService.navigateToExternalLink(url, '_blank');
  }

  private getColumnDescriptions(headers: { text: string; wide: boolean }[]): ProductListTableColumnDescription[] {
    const descriptions = [];

    headers.forEach((header, index) => {
      descriptions.push({
        alignRightDesktop: index !== 0,
        wide: header.wide,
        columnHeader: {
          text: header.text,
          visuallyHideForMobile: true,
          visuallyHideForBreakpoint: ResponsiveBreakpoint.BELOW_LG,
        },
      });
    });

    // last column with action buttons
    descriptions.push({
      columnHeader: {
        text: this.content.labels.actions,
        visuallyHideForDesktop: true,
        visuallyHideForMobile: true,
        visuallyHideForBreakpoint: ResponsiveBreakpoint.BELOW_XXXL,
      },
      wide: true,
    });

    return descriptions;
  }

  private getCurrentPositionDetails(
    holding: Account.MappedHolding,
    isMarginAccount: boolean
  ): ProductListTableTextDetails[] {
    const details: ProductListTableTextDetails[] = [
      {
        detailType: DetailType.TEXT,
        text: `$${(+holding.raw.balance)?.toFixed(2)}`,
        contentStyle: ProductListTableRowContentStyle.BOLD,
      },
      {
        detailType: DetailType.TEXT,
        text: `${holding.quantity?.toFixed(2)} shares`,
      },
    ];

    if (isMarginAccount) {
      details.push({
        detailType: DetailType.TEXT,
        text: holding.raw.brokeragePosition.accountType,
      });
    }

    return details;
  }

  private getHoldingDetails(
    holding: Account.MappedHolding,
    holdingType: HoldingTypeEnum
  ): Array<ProductListTableLinkDetails | ProductListTableTextDetails> {
    const details = [];
    const text = holding.raw.ticker || holding.raw.cusip;
    const linkDetail = {
      detailType: DetailType.LINK,
      text,
      fontWeight: 'bold',
      variant: 'secondary-independent',
      ariaLabel: text,
      action: {
        actionType: 'link',
        href: '',
        target: '_blank',
      },
    };

    switch (holdingType) {
      case HoldingTypeEnum.ETF:
        if (!this.isBeacon && holding.raw.ticker) {
          linkDetail.action.href = `${environment.investorDomain}/investment-products/etfs/profile/${holding.raw.ticker}`;
          details.push(linkDetail);
          break;
        }
      case HoldingTypeEnum.STOCK:
        if (!this.isBeacon && holding.raw.ticker) {
          linkDetail.action.href = `${environment.investorDomain}/investment-products/stocks/profile/${holding.raw.ticker}`;
          details.push(linkDetail);
          break;
        }
      case HoldingTypeEnum.MUTUAL_FUND:
        if (!this.isBeacon && holding.raw.ticker) {
          linkDetail.action.href = `${environment.investorDomain}/investment-products/mutual-funds/profile/${holding.raw.ticker}`;
          details.push(linkDetail);
          break;
        }
      default:
        details.push({
          detailType: DetailType.TEXT,
          text,
          contentStyle: ProductListTableRowContentStyle.BOLD,
        });
        break;
    }

    details.push({
      detailType: DetailType.TEXT,
      text: holding.name,
    });

    const openOrderNotificationDetails = this.getOpenOrderNotificationDetails(holding);

    if (!!openOrderNotificationDetails) {
      details.push(openOrderNotificationDetails);
    }

    return details;
  }

  private getOpenOrderNotificationDetails(holding: Account.MappedHolding): ProductListTableNotificationDetails | null {
    const accountTypeCodeIsShort = holding.raw.brokeragePosition.accountTypeCode === AccountTypeCode.SHORT;

    const badgeCount = this.openOrders?.reduce((acc, order) => {
      if (
        order.orderStatusCode === TweOpenOrdersOrderStatusCodeTypesEnum.OPEN &&
        order.ticker === holding.raw.ticker &&
        ((order.securityAccountType === 'SHORT' && accountTypeCodeIsShort) ||
          (order.securityAccountType !== 'SHORT' && !accountTypeCodeIsShort))
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);

    if (badgeCount) {
      return {
        text: this.content.labels.viewOpenOrders,
        detailType: DetailType.NOTIFICATION,
        contentType: NotificationContentType.LINK,
        ariaLabel: this.content.labels.viewOpenOrders,
        displayContent: {
          type: NotificationDisplayType.BADGE,
          badgeCount: badgeCount,
        },
        onClicked: this.onClickedSubject(() => {
          this.windowService.router.navigate([CONSTANTS.OPEN_ORDERS_PATH]);
        }),
      };
    }

    return null;
  }
}
