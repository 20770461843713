<c11n-modal-dialog
  #modal
  [headingText]="content?.extendedHoursDisclosureModal.title"
  [primaryButtonLabel]="content?.extendedHoursDisclosureModal.acknowledgeDisclosure"
  [secondaryButtonLabel]="content?.extendedHoursDisclosureModal.cancel"
  (primaryClick)="acceptAgreement()"
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md">{{ content?.extendedHoursDisclosureModal.paragraph1 }}</p>
    <p class="c11n-text-md">{{ content?.extendedHoursDisclosureModal.paragraph2 }}</p>
    <p class="c11n-text-lg" [innerHTML]="content?.extendedHoursDisclosureModal.policies"></p>
    <ol role="list" class="c11n-list">
      <li class="c11n-text-md-bold c11n-space-stack-2x c11n-space--force">
        {{ content?.extendedHoursDisclosureModal.afterMarketTrading }}</li
      >
    </ol>
    <p [innerHTML]="content?.extendedHoursDisclosureModal.sessionTimes"></p>
    <p [innerHTML]="content?.extendedHoursDisclosureModal.allowableOrderTypes"></p>
    <p [innerHTML]="content?.extendedHoursDisclosureModal.orderSize"></p>
  </div>
</c11n-modal-dialog>
