import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { TradeTicketForm } from '@app/etfs-equities/models';
import { selectTradeTicket, TayneState } from '@app/etfs-equities/store';
import { select, Store } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-18';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const selectSharesGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const store = inject(Store<TayneState>);
  const router = inject(Router);
  return store.pipe(
    take(1),
    select(selectTradeTicket),
    map((tradeTicket: TradeTicketForm) => {
      const path = route.url[0].path;

      if (!tradeTicket || tradeTicket.costBasisMethod !== CostBasisMethod.SPEC_ID) {
        switch (path) {
          case 'edit-cost-basis':
            router.navigate([CONSTANTS.EDIT_COST_BASIS_PATH], { queryParams: route.queryParams });
            return false;
          case 'extended-hours':
            router.navigate([CONSTANTS.EXTENDED_TRADING_PATH], { queryParams: route.queryParams });
            return false;
          default:
            router.navigate([CONSTANTS.TRADE_PATH], { queryParams: route.queryParams });
            return false;
        }
      }
      return true;
    })
  );
};
