<ng-container
  *ngIf="{
    order: (order$ | async),
    shouldDisplayProspectusLink: (shouldDisplayProspectusLink$ | async),
    isSellingAllHeldShares: (isSellingAllHeldShares$ | async),
    dollarBasedTradesEnabled: (dollarBasedTradesEnabled$ | async),
    isEditCostBasis: (isEditCostBasis$ | async),
    isScreenMediumOrLess: (isScreenMediumOrLess$ | async),
    isEveningOrder: (isEveningOrder$ | async),
  } as view"
>
  <twe-triggered-rules-modal [acceptedRulesNextStep]="AcceptedRulesNextStep.SUBMIT"></twe-triggered-rules-modal>

  <twe-generic-error-modal [isChangeOrder]="!!view.order?.orderId"></twe-generic-error-modal>

  <twe-timeout-error-modal #timeoutErrorModal></twe-timeout-error-modal>

  <div class="container-fluid" data-testid="preview-container">
    <h2 class="preview-order-headline">{{ content.labels.reviewYourOrder }}</h2>

    <div class="row">
      <div class="col-lg-6 left-column">
        <twe-order-details [enableProspectusLink]="!isBeacon"></twe-order-details>
      </div>

      <div class="col-lg-6 right-column">
        <div class="c11n-space-stack-3x">
          <twe-quote *ngIf="!view.isEditCostBasis" [isAfterMarket]="view.isEveningOrder"></twe-quote>
        </div>
        <twe-executed-price
          class="d-none d-lg-block c11n-space-stack-3x c11n-space--force"
          *ngIf="view.isEditCostBasis"
        ></twe-executed-price>

        <p
          class="notice-text"
          data-testid="sell-transaction-message"
          *ngIf="
            (!view.isEditCostBasis && view.order?.transactionType === transactionTypes.SELL) ||
            view.order?.transactionType === transactionTypes.SELL_SHORT
          "
          >{{ content.dynamicPreviewSellFooter }}</p
        >

        <p
          class="notice-text"
          data-testid="sell-transaction-message-sell-all"
          *ngIf="!view.isEditCostBasis && view.dollarBasedTradesEnabled && view.isSellingAllHeldShares"
        >
          <span class="notice-text--bolded">{{ content.labels.important }} </span>
          {{ content.dynamicPreviewSellAll }}
        </p>

        <div
          class="notice-text"
          *ngIf="
            !view.isEditCostBasis &&
            (view.order?.transactionType === transactionTypes.BUY ||
              view.order?.transactionType === transactionTypes.BUY_TO_COVER ||
              view.order?.amountType === amountTypes.DOLLARS)
          "
          data-testid="txt-prospectus-notice"
        >
          <p
            *ngIf="
              view.order?.transactionType === transactionTypes.BUY ||
              view.order?.transactionType === transactionTypes.BUY_TO_COVER
            "
            class="c11n-stack-space-3x"
            >{{ content.submitOrderDisclaimer.body1 }} </p
          ><p
            *ngIf="
              view.order?.amountType === amountTypes.DOLLARS ||
              (!view.order?.orderId && view.shouldDisplayProspectusLink)
            "
            class="mb-0"
            >{{ content.submitOrderDisclaimer.body2 }}
          </p>
          <ul>
            <li *ngIf="view.order?.amountType === amountTypes.DOLLARS">
              {{ content.submitOrderDisclaimer.dollarBasedTrading1 }}
              <button
                c11n-link
                fontWeight="bold"
                variant="primary-reinforced"
                (click)="tradeHelpModal.open(tradeHelpDollarBasedTradingTab)"
              >
                {{ content.submitOrderDisclaimer.dollarBasedTermsLink }}</button
              >{{ content.submitOrderDisclaimer.dollarBasedTrading2 }}
            </li>
            <li *ngIf="view.shouldDisplayProspectusLink">
              <p class="mb-0">
                {{ content.submitOrderDisclaimer.prospectusLinkContent }}
              </p>
            </li>
          </ul>
        </div>

        <div class="preview-buttons">
          <c11n-button
            appSubmitButtonOnce
            class="preview-buttons__button"
            data-testid="btn-preview-submit"
            buttonType="button"
            size="medium"
            variant="primary"
            [fullWidth]="view.isScreenMediumOrLess"
            [isWaiting]="orderService?.isSubmitting"
            [labelText]="content.labels.submit"
            [disabled]="loadingService?.isLoading()"
            (clickFunc)="submit()"
          ></c11n-button>

          <c11n-button
            [attr.data-testid]="'btn-preview-edit'"
            class="preview-buttons__button"
            buttonType="button"
            size="medium"
            variant="secondary"
            [fullWidth]="view.isScreenMediumOrLess"
            [isInvalid]="orderService?.isSubmitting"
            [labelText]="content.labels.editOrder"
            (clickEvent)="edit(view.isEditCostBasis)"
          ></c11n-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<twe-trade-help-modal #tradeHelpModal [hasAnimation]="false"></twe-trade-help-modal>
