<div *ngIf="selectedAccount && !orderRetrievalFailed" class="c11n-space-stack-5x c11n-space--force">
  <div *ngIf="!openOrdersExtended?.length && !isLoading" class="container-fluid open-orders-unavailable">
    <twe-service-unavailable-msg>
      <p class="twe-c11n-color-ink-400 c11n-space-stack-1x" data-testid="noOpenOrdersFoundInfo">
        {{ content.openOrders.noOpenOrdersFound }}
      </p>
      <p>
        <a c11n-link [routerLink]="TRADE_PATH">{{ content.backToTrading }}</a>
      </p>
    </twe-service-unavailable-msg>
  </div>

  <ul class="twe-unstyled-list" [attr.aria-label]="content.openOrders?.openOrders" data-testid="list-open-order-cards">
    <li *ngFor="let order of openOrdersExtended" class="c11n-space-stack-4x">
      <div class="visually-hidden">
        {{ order }}
        {{ content.openOrders.orderForSymbol }}
        {{ order.ticker }}
        {{ order.securityDescription }}
        {{ content.openOrders.orderDate + ' ' + (order.enteredTimeStamp | date : 'MM/dd/yyyy') }}.
        {{ content.openOrders.navigationInstructions }}
      </div>

      <div class="twe-card" [attr.data-testid]="'open-order-card-' + order.ticker">
        <twe-card-cap
          isShortCap="true"
          [backgroundColor]="$any(order.orderColor)"
          [leftContent]="order.orderStatusDisplayText"
          [rightContent]="content.openOrders.orderDate + ' ' + (order.enteredTimeStamp | date : 'MM/dd/yyyy')"
        ></twe-card-cap>

        <div class="open-order-item">
          <div class="open-order-item-table-wrapper">
            <div class="d-flex justify-content-start align-items-baseline flex-wrap">
              <div class="text-truncate c11n-text-lg-bold open-order-item__symbol" data-testid="open-order-card-data">
                <span *ngIf="order.ticker" class="visually-hidden">{{ content.labels.symbol }}</span>
                <span *ngIf="!order.ticker" class="c11n-text-sm-bold">{{ order.cusip }}</span>
                {{ order.ticker }}
              </div>

              <div class="text-truncate c11n-text-sm-bold" data-testid="open-order-card-data">
                <span *ngIf="order.securityDescription" class="visually-hidden">{{
                  content.openOrders.securityName
                }}</span>
                <ng-container
                  *ngIf="
                    order.securityType === securityClassTypes.CALL_OPTION.toString() ||
                      order.securityType === securityClassTypes.PUT_OPTION.toString();
                    else notOption
                  "
                >
                  <span class="open-order-item--margin-long-description">{{ order.securityDescription }}</span>
                </ng-container>
                <ng-template #notOption>
                  <span>
                    {{ order.securityDescription }}
                  </span>
                </ng-template>
              </div>
            </div>

            <div class="open-order-item__cell-container">
              <twe-open-order-item-cell
                [label]="content.labels.transaction"
                [value]="orderInstructionCodeLabel[order.orderInstructionCode]"
                [subValue]="isMarginAccount ? order.securityAccountType : null"
              ></twe-open-order-item-cell>

              <twe-open-order-item-cell
                *ngIf="
                  order.amountIndicator === amountIndicators.DOLLARS &&
                  order.securityType === securityClassTypes.VANGUARD_ETF.toString()
                "
                [label]="content.labels.amount"
                value="{{ order.orderValue | tweSubDollarCurrency }}"
              ></twe-open-order-item-cell>

              <twe-open-order-item-cell
                label="{{
                  securityClasses.includes(order.securityType) ||
                  (order.orderSecurityType.includes('MUTUAL_FUND') && order.amountIndicator !== 'DOLS')
                    ? content.labels.shares
                    : content.labels.dollars
                }}"
                [value]="getNumberOfSharesOrDollars(order)"
                [subValue]="order.allOrNone == '1' ? content.labels.allOrNone : null"
                [secondSubValue]="
                  order.statusDetail == statusDetailEnum.PARTIAL_EXECUTION ? content.labels.executed : null
                "
              ></twe-open-order-item-cell>

              <twe-open-order-item-cell
                *ngIf="order.statusDetail == statusDetailEnum.PARTIAL_EXECUTION"
                label="&nbsp;"
                value="{{ order.remainingQuantity | number : '1.4-4' }}"
                [subValue]="content.labels.open"
              ></twe-open-order-item-cell>

              <ng-container *ngIf="securityClasses.includes(order.securityType)">
                <twe-open-order-item-cell
                  [label]="content.labels.orderType"
                  [value]="orderCategoryLabel[order.orderCategory]"
                ></twe-open-order-item-cell>

                <twe-open-order-item-cell
                  label="{{
                    order.orderCategory === orderCategoryEnum.STOP ||
                    order.orderCategory === orderCategoryEnum.STOP_LIMIT
                      ? content.labels.stop
                      : order.orderCategory === orderCategoryEnum.LIMIT
                      ? content.labels.limit
                      : ''
                  }} {{ content.labels.price }}"
                  value="{{
                    order.orderCategory === orderCategoryEnum.MARKET && order.statusDetail !== statusDetailEnum.EXECUTED
                      ? ' - '
                      : order.orderCategory === orderCategoryEnum.MARKET
                      ? (order.executedPrice | tweSubDollarCurrency)
                      : (order.price | tweSubDollarCurrency)
                  }}"
                ></twe-open-order-item-cell>

                <twe-open-order-item-cell
                  class="d-none d-md-block"
                  *ngIf="order.orderCategory === orderCategoryEnum.STOP_LIMIT"
                  [label]="content.labels.limit + ' ' + content.labels.price"
                  value="{{ order.stopLimitPrice | tweSubDollarCurrency }}"
                ></twe-open-order-item-cell>
              </ng-container>

              <twe-open-order-item-cell
                *ngIf="order.costBasisMethod"
                [label]="content.labels.costBasis"
                [value]="order.costBasisMethod"
              ></twe-open-order-item-cell>

              <twe-open-order-item-cell
                *ngIf="!order.orderSecurityType.includes('MUTUAL_FUND') || (order.securityType && order.orderDuration)"
                [label]="content.labels.duration"
                value="{{ order.orderDuration | tweOpenOrderDuration }}"
                [subValue]="
                  order.orderDuration === 'GTC'
                    ? content.labels.expires + ' ' + (order.settlementDate | date : 'MM/dd/yyyy')
                    : null
                "
              ></twe-open-order-item-cell>
            </div>
          </div>
          <twe-open-order-item-actions
            [order]="order"
            [numberOfSharesOrDollars]="getNumberOfSharesOrDollars(order)"
          ></twe-open-order-item-actions>
        </div>
      </div>
    </li>
  </ul>
</div>
