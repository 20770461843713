<c11n-modal-dialog
  #modal
  modalId="mixedMethodWarningModalId"
  [headingText]="content.selectSharesPage.mixedMethodsModal.heading"
  [primaryButtonLabel]="content.selectSharesPage.mixedMethodsModal.buttonText"
  (primaryClick)="emitPrimary()"
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md">
      {{ content.selectSharesPage.mixedMethodsModal.body1 }}
      <a *ngIf="!isBeacon" c11n-link variant="primary-reinforced" [attr.href]="secureMessagesUrl" target="_blank">{{
        content.selectSharesPage.mixedMethodsModal.secureMessageText
      }}</a>
      <ng-container *ngIf="isBeacon">
        {{ content.selectSharesPage.mixedMethodsModal.secureMessageText }}
      </ng-container>
      {{ content.selectSharesPage.mixedMethodsModal.orAtText }}
      <a
        c11n-link
        variant="primary-reinforced"
        [attr.href]="content.selectSharesPage.mixedMethodsModal.phoneNumberLink"
        >{{ content.selectSharesPage.mixedMethodsModal.phoneNumberText }}</a
      >
      {{ content.selectSharesPage.mixedMethodsModal.body2 }}
    </p>
  </div>
</c11n-modal-dialog>
