<ng-container
  *ngIf="{
    orderContent: (orderContent$ | async),
    order: (order$ | async),
    orderSummary: (orderSummary$ | async),
  } as view"
>
  <tcx-order-summary
    *ngIf="view.order"
    [sections]="view.orderSummary"
    [footnotes]="[view.orderContent]"
    (summarySectionRowElementClick)="handleClickEvent($event)"
  ></tcx-order-summary>
</ng-container>
<twe-cost-basis-unavailable-modal></twe-cost-basis-unavailable-modal>
<twe-selected-lots-modal #selectedLotsModal></twe-selected-lots-modal>
<twe-account-details-modal></twe-account-details-modal>
