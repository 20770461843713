import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import { selectAction } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { FocusUtil } from '@etfs-equities/utils';
import { select, Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { take } from 'rxjs';

@Component({
  selector: 'twe-order-types-modal',
  templateUrl: './order-types-modal.component.html',
  styleUrls: ['./order-types-modal.component.scss'],
})
export class OrderTypesModalComponent {
  //  Decorators...

  @ViewChild('modal')
  modal: ModalDialogComponent;

  //  Public variables...

  content: JsonContent = content;

  activeTab = '';

  constructor(public readonly windowService: WindowService, private readonly store: Store) {}

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  open(event?: Event) {
    this.store
      .pipe(select(selectAction), take(1))
      .subscribe((action) => (this.activeTab = !!action ? action.toLowerCase() : ''));
    FocusUtil.setTooltipFocusElement(event);
    this.modal.openModalDialog(event);
  }

  onClose() {
    FocusUtil.setFocus();
    this.modal.closeDialogModal();
  }

  updateTab(event) {
    const activeItem = event === 'order-types-modal-buy' ? 'buy' : 'sell';
    this.setActiveTab(activeItem);
  }
}
