@import '@vg-constellation/angular-18/styles/breakpoints';
@import '@vg-constellation/angular-18/styles/typography';
@import '@vg-constellation/angular-18/styles/variables';

.cancel-order-detail {
  margin-bottom: $c11n-size-space-3x;

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  // Special case where designers require the box shadow and outline to be removed at the mobile breakpoint
  .twe-card {
    @include media-breakpoint-down(sm) {
      box-shadow: none;
      border: none;
    }
  }

  &__row {
    padding-top: $c11n-size-space-1point5x;
  }

  &__header {
    @include c11n-font-rules('bold', 'md');
    margin-bottom: $c11n-size-space-1point5x;
  }

  &__label {
    @include c11n-font-rules('regular', 'sm');
    color: $c11n-color-ink-600;
    padding-left: 0;
  }

  &__data {
    @include c11n-font-rules('bold', 'sm');
    margin-bottom: 0;
  }

  &__disclaimer {
    @include c11n-font-rules('regular', 'sm');
    color: $c11n-color-ink-600;
    padding-top: $c11n-size-space-3x;
  }

  &--border-top {
    border-top: solid 3px $black;
  }

  &--border-bottom {
    border-bottom: solid 1px $c11n-color-ink-300;
  }

  &--title-case {
    text-transform: capitalize;
  }
}
