import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingContext } from '@app/core/interceptors/loading-interceptor';
import { SymbolRetrievalType, TweLookupTypes } from '@app/etfs-equities/enums';
import { Quote } from '@app/etfs-equities/models';
import { SymbolRetrievalError } from '@app/etfs-equities/models/symbol-retrieval-error.model';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketDataService {
  //  Public variables...

  isLoading = false;

  isRefreshing = false;

  lastSymbol: string;

  loadSymbolFailed = false;

  symbolNotFound = false;

  panelIsOpen = false;

  allowRetry = false;

  //  Public observables/subjects...

  symbolRetrievalError$ = new Subject<SymbolRetrievalError>();

  emitLoadQuoteSuccess$ = new Subject<void>();

  constructor(private readonly http: HttpClient, private readonly envService: EnvironmentService) {}

  fetchQuote(symbol: string, type?: TweLookupTypes, showLoading = true): Observable<Quote> {
    this.loadSymbolFailed = false;
    this.allowRetry = false;
    const query = type ? `?type=${type}` : '';

    return this.http
      .get<Quote>(this.envService.getApiUrlBaseOnRoute() + `api/quotes/realtime/${symbol.toUpperCase()}${query}`, {
        withCredentials: true,
        context: new HttpContext().set(LoadingContext, { showLoading }),
      })
      .pipe(
        tap((quote) => {
          if (quote.isFund) {
            this.symbolRetrievalError$.next({
              type: SymbolRetrievalType.MUTUAL_FUND,
              symbol: quote.tickerSymbol,
            });
          }
        })
      );
  }

  loadQuote(securityIdentifier: string, type?: TweLookupTypes, symbol?: string): Observable<Quote> {
    this.lastSymbol = type === TweLookupTypes.CUSIP ? symbol : securityIdentifier;
    this.isLoading = true;

    return this.fetchQuote(securityIdentifier, type).pipe(tap(() => (this.isLoading = false)));
  }

  refreshQuote(cusip?: string): Observable<Quote> {
    let type: TweLookupTypes | undefined;

    if (!this.lastSymbol && !cusip) {
      return EMPTY;
    }

    if (cusip) type = TweLookupTypes.CUSIP;

    this.isRefreshing = true;

    return this.fetchQuote(cusip || this.lastSymbol, type, false).pipe(tap(() => (this.isRefreshing = false)));
  }

  clearLastSymbolSearched() {
    this.lastSymbol = null;
  }

  clearSymbolNotFoundError() {
    this.symbolNotFound = false;
  }

  setIsLoading(loadingValue: boolean) {
    this.isLoading = loadingValue;
  }

  updateFlags() {
    this.isRefreshing = false;
    this.isLoading = false;
    this.loadSymbolFailed = true;
    this.allowRetry = true;
  }
}
