<ng-container
  *ngIf="{
 accountIsManaged: accountIsManaged$ | async,
} as view"
>
  <ng-container *ngIf="view.accountIsManaged">
    <twe-shingle>
      {{ content.managedAccountsShingle.bodyPart1 }}
      <a c11n-link variant="secondary-reinforced" href="{{ content.contactUsModal.phoneNumberGeneralLink }}">
        {{ content.contactUsModal.phoneNumberGeneral }}
      </a>
      {{ content.managedAccountsShingle.bodyPart2 }}
    </twe-shingle>
  </ng-container>
</ng-container>
