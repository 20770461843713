<div aria-live="off" class="refresh-button-wrapper" [class.d-flex]="!isWrap">
  <ng-content *ngIf="!isLoading && (!hasFailed || alwaysShowContent)"></ng-content>

  <div>
    <button
      c11n-link
      type="button"
      *ngIf="!isLoading && !isComplete"
      iconName="refresh"
      fontWeight="bold"
      iconPosition="trailing"
      (click)="emitRefresh()"
      [@fadeIn]
    >
      {{ title }}
    </button>

    <div class="twe-flex-center-items" *ngIf="isLoading">
      {{ content.loading.updating }}
      <c11n-spinner size="small" [spinnerStatus]="content.loading.updating"></c11n-spinner>
    </div>

    <c11n-icon *ngIf="isComplete" [name]="'success'" [size]="'small'" class="success-icon-container"></c11n-icon>
  </div>
</div>
