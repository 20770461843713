import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { MutualFundErrorModalComponent, OrderInProgressModalComponent } from '@app/etfs-equities/components';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { Account, JsonContent, OpenOrdersResponse } from '@app/etfs-equities/models';
import { AccountService, WindowService } from '@app/etfs-equities/services';
import {
  createRefreshAccountsAction,
  selectAccountIsTradeable,
  selectBondHoldings,
  selectCDHoldings,
  selectEtfHoldings,
  selectMutualFundHoldings,
  selectOptionHoldings,
  selectSelectedAccount,
  selectStockHoldings,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-18';
import { combineLatest, filter, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'twe-modern-holdings-page',
  templateUrl: './modern-holdings-page.component.html',
  styleUrl: './modern-holdings-page.component.scss',
})
export class ModernHoldingsPageComponent implements OnInit, OnDestroy {
  // Decorators...
  @ViewChild('mutualFundModal')
  mutualFundModal: MutualFundErrorModalComponent;

  @ViewChild('orderInProgressModal')
  orderInProgressModal: OrderInProgressModalComponent;

  // Public variables...
  content: JsonContent = content;
  hasAccountRetrievalError = false;
  isFilterAvailable = true;
  tablesVisibility: Account.HoldingsFilterState = {
    all: true,
    stocks: false,
    etfs: false,
    options: false,
    mutualFunds: false,
    cds: false,
    bonds: false,
  };
  isBeacon: boolean;
  symbolForMutualFundModal = '';

  // Public observables...
  selectedAccount$: Observable<Account.Account>;
  stocks$: Observable<Account.MappedHolding[]>;
  etfs$: Observable<Account.MappedHolding[]>;
  options$: Observable<Account.MappedHolding[]>;
  mutualFunds$: Observable<Account.MappedHolding[]>;
  CDs$: Observable<Account.MappedHolding[]>;
  bonds$: Observable<Account.MappedHolding[]>;
  accountIsTradeable$: Observable<boolean>;
  openOrders$: Observable<OpenOrdersResponse>;

  // Private observables...
  private unsubscribe$: Subject<void> = new Subject();
  protected readonly timestampSizeEnum = TimestampSize;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly accountService: AccountService,
    private readonly windowService: WindowService
  ) {
    this.isBeacon = this.windowService.getIsBeacon();
  }

  get isRefreshingAccounts() {
    return this.accountService.isRefreshingAccounts;
  }

  get hasCriticalHoldingError() {
    return this.accountService.hasCriticalHoldingError;
  }

  ngOnInit(): void {
    this.selectedAccount$ = this.store.select(selectSelectedAccount);
    this.stocks$ = this.store.select(selectStockHoldings);
    this.etfs$ = this.store.select(selectEtfHoldings);
    this.options$ = this.store.select(selectOptionHoldings);
    this.mutualFunds$ = this.store.select(selectMutualFundHoldings);
    this.CDs$ = this.store.select(selectCDHoldings);
    this.bonds$ = this.store.select(selectBondHoldings);
    this.accountIsTradeable$ = this.store.select(selectAccountIsTradeable);

    this.watchForFilterState();
    this.getOpenOrders();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setHasAccountRetrievalError(hasError: boolean): void {
    this.hasAccountRetrievalError = hasError;
  }

  backToTrading(): void {
    this.windowService.router.navigate([CONSTANTS.TRADE_PATH]);
  }

  refreshHoldings() {
    this.store.dispatch(createRefreshAccountsAction());
  }

  updateFilterState(filterState: Account.HoldingsFilterState): void {
    this.tablesVisibility = filterState;
  }

  openMutualFundModal(holding: Account.MappedHolding): void {
    this.symbolForMutualFundModal = holding.symbol;
    this.mutualFundModal.setQueryParams(holding.symbol);
    this.mutualFundModal.modal.openModalDialog();
  }

  openOrderInProgressModal(queryParams: Params): void {
    this.orderInProgressModal.setQueryParams(queryParams);
    this.orderInProgressModal.modal.openModalDialog();
  }

  private watchForFilterState(): void {
    combineLatest([
      this.selectedAccount$,
      this.stocks$,
      this.etfs$,
      this.options$,
      this.mutualFunds$,
      this.CDs$,
      this.bonds$,
    ])
      .pipe(
        tap(([_account, stocks, etfs, options, mutualFunds, cds, bounds]) => {
          this.isFilterAvailable =
            [stocks, etfs, options, mutualFunds, cds, bounds].reduce(
              (sum, holdingsGroup) => sum + (holdingsGroup?.length ? 1 : 0),
              0
            ) > 1;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private getOpenOrders(): void {
    this.selectedAccount$
      .pipe(
        filter((account) => !!account),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((account) => {
        this.openOrders$ = this.accountService.fetchOpenOrdersForAccount(account);
      });
  }
}
