<c11n-modal-dialog
  #modal
  [headingText]="content.howToReadAQuoteModal.title"
  maxWidth="900"
  modalId="how-to-read-a-quote"
>
  <div c11n-modal-dialog-body>
    <c11n-tabs activeItem="basic-quote-tab" [hasAnimation]="true">
      <c11n-tab-panel id="basic-quote-tab" [labelText]="content.howToReadAQuoteModal.tabs.basicQuote">
        <div class="row">
          <div class="col-md-5 text-center">
            <img
              class="read-quote__img"
              src="{{ windowService.assetsPath }}assets/images/how-to-read-a-quote/example-quote.png"
              alt="Example Quote"
              loading="lazy"
            />
          </div>

          <ng-container *ngTemplateOutlet="quoteContent; context: { $implicit: false }"></ng-container>
        </div>
      </c11n-tab-panel>
      <c11n-tab-panel id="detailed-quote-tab" [labelText]="content.howToReadAQuoteModal.tabs.detailedQuote">
        <div class="row">
          <div class="col-md-5 text-center">
            <img
              class="read-quote__img"
              src="{{ windowService.assetsPath }}assets/images/how-to-read-a-quote/example-quote-detailed.png"
              alt="Example Detailed Quote"
              loading="lazy"
            />
          </div>

          <ng-container *ngTemplateOutlet="quoteContent; context: { $implicit: true }"></ng-container>
        </div>
      </c11n-tab-panel>
    </c11n-tabs>
  </div>
</c11n-modal-dialog>

<ng-template #quoteContent let-isDetailedQuote>
  <div class="col-md-7">
    <div tabindex="0" class="read-quote">
      <div *ngFor="let section of content.howToReadAQuoteModal.body">
        <div
          *ngIf="
            (isDetailedQuote && section.group.indexOf('D') != -1) ||
            (!isDetailedQuote && section.group.indexOf('B') != -1)
          "
        >
          <h4 class="read-quote__sectionName">{{ section.sectionName }}</h4>

          <p *ngIf="section.briefDescription" class="read-quote__briefDesctiption">{{ section.briefDescription }}</p>

          <p
            *ngIf="section.longDescription"
            class="read-quote__longDescription"
            [innerHtml]="section.longDescription"
          ></p>

          <div class="col-12 mt-3 mb-3">
            <hr noshade class="read-quote__hr" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
