import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import content from '@content/content.json';
import { AdobeAnalyticsService } from '@core/services';
import { CONSTANTS } from '@etfs-equities/constants';
import { JsonContent } from '@etfs-equities/models';
import { CostBasisService, OrderService, TradeTicketService } from '@etfs-equities/services';
import {
  createClearLoadAvailableMethodsErrorAction,
  createLoadAvailableMethodsAction,
  selectCostBasisTradeMethodsError,
  selectIsScreenMediumOrLess,
  TayneState,
} from '@etfs-equities/store';
import { selectIsIncapacitated } from '@etfs-equities/store/selectors/client-data/client-data.selectors';
import { select, Store } from '@ngrx/store';
import { ButtonComponent } from '@vg-constellation/angular-18/button';
import { filter, Observable, take } from 'rxjs';

@Component({
  selector: 'twe-trade-buttons',
  templateUrl: './trade-buttons.component.html',
  styleUrls: ['./trade-buttons.component.scss'],
})
export class TradeButtonsComponent implements OnInit {
  //  Decorators...
  @Input() isChangeOrder = false;
  @Input() isExtendedTrading = false;

  @Output()
  clear: EventEmitter<void> = new EventEmitter();

  @Output()
  preview: EventEmitter<void> = new EventEmitter();

  @ViewChild('previewButton', { static: false })
  previewButton: ButtonComponent;

  //  Public observables/subjects...
  isIncapacitated$: Observable<boolean>;
  isScreenMediumOrLess$: Observable<boolean>;

  //  Public variables...
  content: JsonContent = content;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly router: Router,
    public orderService: OrderService,
    public costBasisService: CostBasisService,
    public tradeTicketService: TradeTicketService,
    private readonly adobeService: AdobeAnalyticsService
  ) {}

  ngOnInit() {
    this.isScreenMediumOrLess$ = this.store.select(selectIsScreenMediumOrLess);
    this.isIncapacitated$ = this.store.pipe(select(selectIsIncapacitated));
  }

  handlePreviewBtnClick(): void {
    const costBasisMethod = this.tradeTicketService.getCostBasisMethod();
    const isCostBasisVisible = this.tradeTicketService.costBasisIsVisible;

    this.adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Preview Order', CONSTANTS.ADOBE_PROCESS_TYPE);

    if (isCostBasisVisible) {
      this.adobeService.sendAdobeLaunchProcess(`equityETFTicket-capture: ${costBasisMethod}`);

      if (this.isChangeOrder && !costBasisMethod) {
        this.openCostBasisSelectionModalOnSuccess();
        return;
      }
    }

    this.preview.next();
  }
  handleActionBtnClick() {
    if (this.isChangeOrder) {
      this.router.navigate([CONSTANTS.OPEN_ORDERS_PATH]);
      return;
    }
    this.clear.next();
  }

  /**
   * Opens the cost basis selection modal on cost basis service's success response.
   * Proceeds to preview otherwise.
   */
  private openCostBasisSelectionModalOnSuccess() {
    // Clear focus from the preview button to reset its color
    this.previewButton?.elRef?.nativeElement?.blur();

    this.store.dispatch(createClearLoadAvailableMethodsErrorAction(null));
    this.store.dispatch(createLoadAvailableMethodsAction({ openErrorModal: false }));

    this.store
      .select(selectCostBasisTradeMethodsError)
      .pipe(
        filter((errors) => !!errors),
        take(1)
      )
      .subscribe(() => this.preview.next());
  }
}
