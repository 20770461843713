<ng-container
  *ngIf="{
    isIncapacitated: (isIncapacitated$ | async),
  } as view"
  [formGroup]="parentGroup"
>
  <div [hidden]="!keywordSearchService.isSearchingByKeyword">
    <twe-input-label
      for="keywordSearchComponent"
      [labelText]="content.labels.searchKeyword"
      [tooltipHeader]="content.guidelet.symbolKeywordLookup.header"
    >
      <p [innerHtml]="content.guidelet.symbolKeywordLookup.content"></p>
      <button
        c11n-link
        colorMode="on-dark"
        type="button"
        (click)="
          adobeService.sendAdobeTrackingOnClick(content.guidelet.symbolKeywordLookup.link, 'link', 'SymbolToolTip');
          symbolSearchModal.open($event)
        "
      >
        {{ content.guidelet.symbolKeywordLookup.link }}
      </button>
    </twe-input-label>

    <product-search
      #productSearchComponent
      id="keywordSearchComponent"
      data-testid="keywordSearchComponent-test-id"
      (searchResults)="handleProductResultSelection($event)"
      [productSearchConfigProvider]="productSearchConfigService"
    ></product-search>
  </div>

  <div id="keyword-typeahead-status-message" role="status" class="visually-hidden">
    <ng-container *ngIf="keywordSearchService.isSearchingByKeyword">
      <ng-container *ngIf="keywordSearchService.results?.length > 0 && keywordSearchService.results?.length <= 10">
        {{ keywordSearchService.results?.length }} {{ content.keywordSearch.resultsFoundFor }}
        {{ tradeTicketService.getKeyword() }},
        {{ content.keywordSearch.useArrowKeys }}
      </ng-container>

      <ng-container *ngIf="keywordSearchService.results?.length > 10">
        {{ content.keywordSearch.resultsFoundForExtended }} {{ tradeTicketService.getKeyword() }},
        {{ content.keywordSearch.useArrowKeys }}
      </ng-container>

      <ng-container *ngIf="keywordSearchService.isLoading">{{ content.keywordSearch.loading }}</ng-container>
    </ng-container>
  </div>
  <div id="keywordSearchServiceNoResultsFound" role="alert">
    <div *ngIf="keywordSearchService.noResultsFound" class="c11n-text-sm mt-1">
      {{ content.labels.noResultFound }}
    </div>
  </div>
  <div id="keywordSearchServiceHasError" role="alert">
    <div *ngIf="keywordSearchService.hasError" class="twe-c11n-color-ink-400 c11n-text-sm">
      {{ content.keywordSearch.searchFailed }}
    </div>
  </div>
  <div buttons class="c11n-text-sm search-toggle-link">
    <button
      c11n-link
      fontWeight="normal"
      class="d-inline-block"
      type="button"
      data-testid="search-by-keyword-toggle-button"
      [attr.disabled]="view.isIncapacitated || null"
      (click)="keywordSearchService.toggleKeywordSearch(); focusSymbolKeywordInput()"
    >
      {{
        keywordSearchService.isSearchingByKeyword ? content.labels.toggleLinkTextSymbol : content.labels.searchKeyword
      }}
    </button>
  </div>
</ng-container>

<twe-symbol-modal #symbolSearchModal></twe-symbol-modal>
