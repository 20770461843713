import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FundsAvailable } from '@app/etfs-equities/models';
import {
  createFundsAvailableIsLoadingAction,
  createFundsAvailableLoadingFailedAction,
  TayneState,
} from '@app/etfs-equities/store';
import { Store } from '@ngrx/store';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FundsAvailableService {
  // Public observables/subjects...
  isLoading$: Observable<boolean>;
  loadingFailed$: Observable<boolean>;

  constructor(
    private readonly http: HttpClient,
    private readonly cookieService: CookieService,
    private readonly envService: EnvironmentService,
    private readonly store: Store<TayneState>
  ) {}

  fetchFundsAvailable(accountId: string | number): Observable<FundsAvailable> {
    this.store.dispatch(createFundsAvailableIsLoadingAction(true));

    return this.http
      .get<FundsAvailable>(this.envService.getApiUrlBaseOnRoute() + `api/funds-available?accountId=${accountId}`, {
        headers: new HttpHeaders({
          'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
        }),
        withCredentials: true,
      })
      .pipe(
        tap(() => {
          this.store.dispatch(createFundsAvailableLoadingFailedAction(false));
          this.store.dispatch(createFundsAvailableIsLoadingAction(false));
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(createFundsAvailableIsLoadingAction(false));
          this.store.dispatch(createFundsAvailableLoadingFailedAction(true));
          return throwError(() => error);
        })
      );
  }
}
