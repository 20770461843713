import { Injectable, OnDestroy } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { UILogError } from '@app/core/models/ui-log/ui-log.model';
import { SEGMENT_LEGEND } from '@app/etfs-equities/constants/segment-legend';
import { OrderEnums, SecurityAccountTypes } from '@app/etfs-equities/enums';
import { DecimalsValidatorTypes } from '@app/etfs-equities/enums/decimals-validator-types.enum';
import {
  ControlPlaneEvent,
  ControlPlaneEventType,
  Order,
  SetValueOptions,
  TradeTicketForm,
  TradeTicketFormFields,
  TradeTicketFormGroupType,
} from '@app/etfs-equities/models';
import { createResetTradeTicketAction, createUpdateTradeTicketAction } from '@app/etfs-equities/store';
import { CostBasisUtil, FormUtil, OrderUtil } from '@app/etfs-equities/utils';
import {
  accountValidator,
  decimalsValidator,
  EstimatedSharesValidator,
  HoldLimitSharesValidator,
  numericValidator,
  requiredWhenIn,
} from '@app/etfs-equities/validators';
import { ExtendedHoursValidators } from '@app/etfs-equities/validators/extended-hours/extended-hours.validators';
import { HoldLimitDollarsValidator } from '@app/etfs-equities/validators/hold-limit-dollars/hold-limit-dollars.validator';
import { subDollarDecimalsValidator } from '@app/etfs-equities/validators/sub-dollar-decimals/sub-dollar-decimals.validator';
import { Durations } from '@etfs-equities/enums/order.enums';
import { SelectedLot } from '@etfs-equities/models/cost-basis.model';
import { Store } from '@ngrx/store';
import { RehydratableFormGroup, SingleErrorFormControl } from '@vanguard/trade-standard-forms-lib-ng-18';
import { CostBasisMethod, LotTableRowControls } from '@vanguard/trade-ui-components-lib-ng-18';
import lodash from 'lodash';
import { merge, Observable, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TradeTicketService implements OnDestroy {
  // constants
  private static readonly limitOrderTypes: OrderEnums.Types[] = [OrderEnums.Types.LIMIT, OrderEnums.Types.STOP_LIMIT];

  private static readonly stopOrderTypes: OrderEnums.Types[] = [OrderEnums.Types.STOP, OrderEnums.Types.STOP_LIMIT];

  //  Public observables/subjects...

  tradeCannotBeCompleted$ = new Subject<UILogError>();

  costBasisVisibilityChanged$: Subject<boolean> = new Subject();

  selectedKeywordSearchResult$: Subject<void> = new Subject();

  ticketFormReset$: Subject<string | void> = new Subject();

  accountSelectorControlPlane$: Subject<ControlPlaneEvent> = new Subject();

  extendedTradingAsyncValidator: AsyncValidatorFn;

  //  Public variables...

  // The main trade form group...

  tradeTicket: FormGroup<TradeTicketFormGroupType>;

  /*
  |=====================================================================
  | Lifecycle Methods
  |=====================================================================
  |
  | Creating the form group in a stateful service injected at the root
  | means we have access to the form everywhere we might need it.
  | I think this is good and bad. Maybe there is a way to lock
  | down access to this form group but keep the benefits of
  | shared state? Or is there a different approach?
  |
  | Currently this is just a single form group. We can break this up
  | into logical sections or multiple forms if that makes sense.
  |
  */

  //  Private observables/subjects...

  private readonly unsubscribe$ = new Subject<void>();

  //  Private variables...

  private _costBasisIsVisible = false;

  constructor(
    private readonly estimatedSharesValidator: EstimatedSharesValidator,
    private readonly holdLimitSharesValidator: HoldLimitSharesValidator,
    private readonly holdLimitDollarsValidator: HoldLimitDollarsValidator,
    private readonly extendedHoursValidators: ExtendedHoursValidators,
    private readonly store: Store
  ) {
    this.tradeTicket = new FormGroup<TradeTicketFormGroupType>(
      {
        allOrNone: new FormControl(),
        accountId: new SingleErrorFormControl<string>('', {
          nonNullable: true,
          validators: accountValidator,
        }),
        brokerageAccountNumber: new FormControl(null, [Validators.required]),
        action: new FormControl(null, [Validators.required]),
        duration: new FormControl(null, [Validators.required]),
        hasQuote: new FormControl(false, [Validators.requiredTrue]),
        keyword: new FormControl(),
        keywordCusip: new FormControl(null),
        amountType: new FormControl(OrderEnums.AmountTypes.SHARES),
        limitPrice: new FormControl(null, {
          updateOn: 'blur',
          validators: [
            Validators.min(0.0000001),
            Validators.max(999999999.99),
            numericValidator(),
            subDollarDecimalsValidator(),
          ],
        }),
        orderId: new FormControl(),
        orderType: new FormControl(null, [Validators.required]),
        amount: new FormControl(null, { updateOn: 'blur' }),
        sellAll: new FormControl(),
        stopPrice: new FormControl(null, {
          updateOn: 'blur',
          validators: [
            Validators.min(0.0000001),
            Validators.max(999999999.99),
            numericValidator(),
            subDollarDecimalsValidator(),
          ],
        }),
        symbol: new FormControl(null, [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern('[A-Za-z_^ .]*'),
        ]),
        costBasisMethod: new FormControl(),
        securityAccountType: new FormControl(),
        updateDefaultCostBasis: new FormControl(false),
        isCostBasisEligible: new FormControl(false),
      },
      {
        validators: [
          requiredWhenIn('limitPrice', 'orderType', [OrderEnums.Types.STOP_LIMIT, OrderEnums.Types.LIMIT]),
          requiredWhenIn('stopPrice', 'orderType', [OrderEnums.Types.STOP_LIMIT, OrderEnums.Types.STOP]),
        ],
      }
    );

    this.watchForTradeTicketValueChanges();

    this.watchForLimitPriceResetConditions();

    this.watchForStopPriceResetConditions();

    this.watchForDurationAutoselectConditions();

    this.watchForAllOrNoneResetAmountConditions();

    this.watchForAllOrNoneResetOrderTypeConditions();

    this.watchForActionChangeAndResetCostBasis();

    this.setSharesValidators();

    this.extendedTradingAsyncValidator = this.extendedHoursValidators.validate.bind(this.extendedHoursValidators);
  }

  // Getters/Setters...

  get accountSelectionRequired(): boolean {
    return (
      this.tradeTicket.controls.brokerageAccountNumber.value === null &&
      this.tradeTicket.controls.action.valid &&
      this.tradeTicket.controls.symbol.valid &&
      this.tradeTicket.controls.amount.valid
    );
  }

  get costBasisIsVisible() {
    return this._costBasisIsVisible;
  }

  set costBasisIsVisible(val: boolean) {
    if (val !== this._costBasisIsVisible) {
      /*
      Templates use the property costBasisIsVisible and cost basis auto launch popup
      uses observable version so added timeout to ensure the modal doesn't show
      before the template ui is updated.
      */
      setTimeout(() => this.costBasisVisibilityChanged$.next(val));
    }
    this._costBasisIsVisible = val;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /*
  |=====================================================================
  | Template Helpers
  |=====================================================================
  |
  | It feels weird having a template reach into a service for logic.
  | It feels even worse adding methods to a component that just defer
  | to the service, so I opted not to do that. I think this smells
  | of something, but I'm not sure what.
  |
  | Lets make these getters as they do not accept params and are
  | really intended for use in the view.
  |
  */

  orderTypeIsVisible() {
    return (
      this.tradeTicket.controls.brokerageAccountNumber.valid &&
      this.tradeTicket.controls.action.valid &&
      this.tradeTicket.controls.hasQuote.valid &&
      !(this.actionIsSellShortOrBuyToCover() && this.amountTypeIsDollars()) &&
      (this.tradeTicket.controls.amount.valid ||
        !!(this.tradeTicket.controls.amount.value && !this.tradeTicket.controls.amount.touched))
    );
  }

  limitPriceIsVisible() {
    return (
      this.orderTypeIsVisible() &&
      TradeTicketService.limitOrderTypes.includes(this.tradeTicket.controls.orderType.value)
    );
  }

  stopPriceIsVisible() {
    return (
      this.orderTypeIsVisible() && TradeTicketService.stopOrderTypes.includes(this.tradeTicket.controls.orderType.value)
    );
  }

  durationIsVisible() {
    return this.orderTypeIsVisible() && this.tradeTicket.controls.orderType.valid;
  }

  orderType() {
    return this.tradeTicket.controls.orderType.value;
  }

  orderTypeIsMarket() {
    return this.tradeTicket.controls.orderType.value === OrderEnums.Types.MARKET;
  }

  orderTypeIsLimitOrStop() {
    const orderType = this.tradeTicket.controls.orderType.value;
    return orderType === OrderEnums.Types.LIMIT || orderType === OrderEnums.Types.STOP;
  }

  orderTypeIsStopLimit() {
    return this.tradeTicket.controls.orderType.value === OrderEnums.Types.STOP_LIMIT;
  }

  orderDurationIsEvening() {
    return this.tradeTicket.controls.duration.value === OrderEnums.Durations.EVENING;
  }

  allOrNoneIsVisible() {
    return this.limitPriceIsVisible() && +this.tradeTicket.controls.amount.value >= 2000;
  }

  orderActionIsSell() {
    return this.tradeTicket.controls.action.value === OrderEnums.TransactionTypes.SELL;
  }

  transactionTypeIsSellShort() {
    return this.tradeTicket.controls.action.value === OrderEnums.TransactionTypes.SELL_SHORT;
  }

  isSellAllChecked() {
    return this.tradeTicket.controls.sellAll.value;
  }

  isSellAllLessThanOne() {
    return (
      this.isSellAllChecked() &&
      +this.tradeTicket.controls.amount.value > 0 &&
      +this.tradeTicket.controls.amount.value < 1
    );
  }

  specIdIsSelected() {
    return this.tradeTicket.controls.costBasisMethod.value === CostBasisMethod.SPEC_ID;
  }

  amountTypeIsDollars() {
    return this.tradeTicket.controls.amountType.value === OrderEnums.AmountTypes.DOLLARS;
  }

  amountTypeIsShares() {
    return this.tradeTicket.controls.amountType.value === OrderEnums.AmountTypes.SHARES;
  }

  actionIsSellShortOrBuyToCover() {
    return [OrderEnums.TransactionTypes.SELL_SHORT, OrderEnums.TransactionTypes.BUY_TO_COVER].includes(
      this.tradeTicket.controls.action.value
    );
  }

  actionIsBuyOrSellShort() {
    return [OrderEnums.TransactionTypes.BUY, OrderEnums.TransactionTypes.SELL_SHORT].includes(
      this.tradeTicket.controls.action.value
    );
  }

  /*
  |=====================================================================
  | Validation Helpers
  |=====================================================================
  */

  // This is just for convenience to keep template markup more terse.
  controlHasError(controlName: string, ruleName?: string) {
    return FormUtil.controlHasError(this.tradeTicket.get(controlName), ruleName);
  }

  limitPriceHasError() {
    const limitPriceControl = this.tradeTicket.controls.limitPrice;
    return (
      FormUtil.controlIsTouchedAndDirty(limitPriceControl) &&
      (limitPriceControl.invalid || this.limitPriceHasRequiredWhenInError())
    );
  }

  limitPriceHasRequiredWhenInError() {
    return !!this.tradeTicket.errors && !!this.tradeTicket.errors.limitPriceRequiredWhenIn;
  }

  stopPriceHasError() {
    const stopPriceControl = this.tradeTicket.controls.stopPrice;
    return (
      FormUtil.controlIsTouchedAndDirty(stopPriceControl) &&
      (stopPriceControl.invalid || this.stopPriceHasRequiredWhenInError())
    );
  }

  stopPriceHasRequiredWhenInError() {
    return !!this.tradeTicket.errors && !!this.tradeTicket.errors.stopPriceRequiredWhenIn;
  }

  costBasisMethodHasError() {
    const costBasisControl = this.tradeTicket.controls.costBasisMethod;
    return (
      FormUtil.controlIsTouchedAndDirty(costBasisControl) &&
      FormUtil.groupHasError(this.tradeTicket, 'costBasisMethodRequired')
    );
  }

  setSharesValidators() {
    this.tradeTicket.controls.amount.clearValidators();
    this.tradeTicket.controls.amount.clearAsyncValidators();
    this.tradeTicket.controls.amount.setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(99999999),
      Validators.pattern('[0-9]*'),
      decimalsValidator({ expectedNumberOfDecimals: 0 }),
    ]);
    this.tradeTicket.controls.amount.setAsyncValidators(
      this.holdLimitSharesValidator.validate.bind(this.holdLimitSharesValidator)
    );
    this.tradeTicket.controls.amount.updateValueAndValidity({ emitEvent: false });
  }

  setDollarsValidators() {
    this.tradeTicket.controls.amount.clearValidators();
    this.tradeTicket.controls.amount.clearAsyncValidators();
    this.tradeTicket.controls.amount.setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(5000000),
      Validators.pattern('[0-9]*\\.?[0-9]*'),
      decimalsValidator({ expectedNumberOfDecimals: 2, matchType: DecimalsValidatorTypes.MAX }),
    ]);
    this.tradeTicket.controls.amount.setAsyncValidators([
      this.estimatedSharesValidator.validate.bind(this.estimatedSharesValidator),
      this.holdLimitDollarsValidator.validate.bind(this.holdLimitDollarsValidator),
    ]);
    this.tradeTicket.controls.amount.updateValueAndValidity({ emitEvent: false });
  }

  setSellAllValidators() {
    this.tradeTicket.controls.amount.clearValidators();
    this.tradeTicket.controls.amount.setValidators([
      Validators.required,
      Validators.min(0.0001),
      Validators.pattern('[0-9]*\\.?[0-9]*'),
    ]);
    this.tradeTicket.controls.amount.updateValueAndValidity({ emitEvent: false });
  }

  setSecurityAccountTypeValidators() {
    this.tradeTicket.controls.securityAccountType.setValidators([
      Validators.required,
      Validators.pattern('CASH|MARGIN|SHORT'),
    ]);
    this.tradeTicket.controls.securityAccountType.updateValueAndValidity({ emitEvent: false });
  }

  setExtendedHoursValidators() {
    this.tradeTicket.controls.symbol.setAsyncValidators(this.extendedTradingAsyncValidator);
    this.tradeTicket.controls.symbol.updateValueAndValidity();
  }

  clearSecurityAccountTypeValidators() {
    this.tradeTicket.controls.securityAccountType.removeValidators(Validators.required);
    this.tradeTicket.controls.securityAccountType.updateValueAndValidity({ emitEvent: false });
  }

  clearExtendedHoursValidators() {
    this.tradeTicket.controls.symbol.removeAsyncValidators(this.extendedTradingAsyncValidator);
    this.tradeTicket.controls.symbol.updateValueAndValidity();
  }

  /*
  |=====================================================================
  | General Helpers
  |=====================================================================
  */

  validateForm() {
    this.tradeTicket.markAllAsTouched();
    this.triggerMfeAccountSelectorValidation();

    Object.keys(this.tradeTicket.controls).forEach((controlName) => {
      this.tradeTicket.get(controlName).markAsDirty();
    });
  }

  validateAmountControl() {
    if (this.tradeTicket.controls.amount.value) {
      this.tradeTicket.controls.amount.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  isNullSpecIdForNotMarketOrder() {
    return (
      !this.orderTypeIsMarket() &&
      !this.actionIsBuyOrSellShort() &&
      this.tradeTicket.controls.isCostBasisEligible.value &&
      this.getCostBasisMethod() === null
    );
  }

  resetForm() {
    const persistentControlNames: (keyof TradeTicketForm)[] = [
      'accountId',
      'brokerageAccountNumber',
      'amountType',
      'isCostBasisEligible',
    ];
    this.resetEverythingBut(persistentControlNames);
    // re-initialize amount type
    this.setAmountType(OrderEnums.AmountTypes.SHARES);
    this.ticketFormReset$.next(SEGMENT_LEGEND.AMOUNT_TYPE);
  }

  clearForm() {
    this.store.dispatch(createResetTradeTicketAction());
  }

  /**
   * @remarks
   * Resets amountType to the provided type
   * @param type - 'DOLLARS|SHARES'. Default value SHARES will be used if not provided
   */
  resetAmountType(type = OrderEnums.AmountTypes.SHARES, emitEvent = true) {
    this.tradeTicket.controls.amountType.reset(type, { emitEvent, onlySelf: !emitEvent });
    this.ticketFormReset$.next(SEGMENT_LEGEND.AMOUNT_TYPE);
  }

  resetSymbol() {
    this.tradeTicket.controls.symbol.reset();
    this.tradeTicket.controls.symbol.markAsUntouched();
    this.tradeTicket.controls.symbol.markAsPristine();
  }

  /**
   * @remarks
   * This method is for resetting everything but the specified arguments passed in
   * @param controls - An array that takes the TradeTicketForm keys as string values
   */
  resetEverythingBut(controls: (keyof TradeTicketForm)[]) {
    Object.keys(this.tradeTicket.controls).forEach((controlName: keyof TradeTicketForm) => {
      if (controls.indexOf(controlName) === -1) {
        this.tradeTicket.get(controlName).reset();
      }
    });
    // reset segmented controls
    this.ticketFormReset$.next();
  }

  /**
   * @remarks
   * This method is for resetting only the specified arguments passed in
   * @param controls - An array that takes the TradeTicketForm keys as string values
   */
  resetOnlyTheFollowingControls(controls: (keyof TradeTicketForm)[]) {
    Object.keys(this.tradeTicket.controls).forEach((controlName: keyof TradeTicketForm) => {
      if (controls.indexOf(controlName) !== -1) {
        this.tradeTicket.get(controlName).reset();
      }
    });
    // reset segmented controls
    this.ticketFormReset$.next();
  }

  tradeFormIsValid() {
    const formControlsAreValid = Object.keys(this.tradeTicket.controls)
      .map((controlName) => FormUtil.controlHasError(this.tradeTicket.get(controlName)))
      .every((status) => !status);

    const formValidatorsAreValid =
      !this.limitPriceHasError() && !this.stopPriceHasError() && !this.costBasisMethodHasError();

    return formControlsAreValid && formValidatorsAreValid;
  }

  /*
  |=====================================================================
  | Business Logic
  |=====================================================================
  */

  private setDurationAutoValues(action, orderType) {
    if (action === OrderEnums.TransactionTypes.SELL_SHORT || orderType === OrderEnums.Types.MARKET) {
      this.tradeTicket.controls.duration.setValue(OrderEnums.Durations.DAY);
      this.tradeTicket.controls.duration.markAsTouched();
      this.tradeTicket.controls.duration.markAsDirty();
    } else if (this.orderDurationIsEvening()) {
      this.tradeTicket.controls.duration.markAsTouched();
      this.tradeTicket.controls.duration.markAsDirty();
    } else {
      this.tradeTicket.controls.duration.reset();
      this.tradeTicket.controls.duration.markAsUntouched();
      this.tradeTicket.controls.duration.markAsPristine();
      this.ticketFormReset$.next(SEGMENT_LEGEND.DURATION);
    }
  }

  /*
  |=====================================================================
  | Public Form Control Value Setters
  |=====================================================================
  |
  | Trying to prevent using setValue outside of this service so that it
  | can maintain its own state. These setters are explicit, but might
  | not be useful. I'd almost like to have the tradeForm be private,
  | but I'm not sure that'd be possible the way I'm using it.
  |
  */

  quoteLoaded() {
    this.tradeTicket.controls.hasQuote.setValue(true);
    this.tradeTicket.controls.hasQuote.markAsTouched();
    this.tradeTicket.controls.hasQuote.markAsDirty();
  }

  quoteCleared() {
    this.tradeTicket.controls.hasQuote.setValue(false);
    this.tradeTicket.controls.hasQuote.markAsPristine();
  }

  handleSelectedKeyword() {
    this.tradeTicket.controls.keyword.setValue(null);
    this.selectedKeywordSearchResult$.next();
  }

  uncheckSellAll() {
    this.tradeTicket.controls.sellAll.setValue(false);
    this.setAmountType(OrderEnums.AmountTypes.SHARES);
  }

  replaceDotsAndUnderscores(symbol: string): string {
    if (symbol.includes('.') || symbol.includes('_')) {
      const freshAndCleanSymbol = symbol.replace(/[._]/g, ' ');
      return freshAndCleanSymbol.trim();
    }
    return symbol.trim();
  }

  setSymbol(symbol: string, noPropagation?: boolean) {
    const opts = noPropagation ? { emitEvent: false, onlySelf: true } : {};
    this.tradeTicket.controls.symbol.setValue(this.replaceDotsAndUnderscores(symbol), opts);
    this.tradeTicket.controls.symbol.markAsTouched(opts);
    this.tradeTicket.controls.symbol.markAsDirty(opts);
  }

  clearSymbolOnlySelf() {
    this.tradeTicket.controls.symbol.reset(null, { emitEvent: false, onlySelf: true });
  }

  getSymbol() {
    return this.tradeTicket.controls.symbol.value;
  }

  setKeywordCusip(cusip: string): void {
    this.tradeTicket.controls.keywordCusip.setValue(cusip);
  }

  getKeywordCusip(): string | null {
    return this.tradeTicket.controls.keywordCusip.value;
  }

  setAmountType(amountType: OrderEnums.AmountTypes, emitEvent = true) {
    this.tradeTicket.controls.amountType.setValue(amountType, { emitEvent, onlySelf: !emitEvent });
  }

  setBrokerageAccountNumber(brokerageAccountNumber: string) {
    this.tradeTicket.controls.brokerageAccountNumber.setValue(String(brokerageAccountNumber));
  }

  setOrderType(orderType: OrderEnums.Types) {
    this.tradeTicket.controls.orderType.setValue(orderType);
  }

  setOrderAction(orderAction: OrderEnums.TransactionTypes) {
    this.tradeTicket.controls.action.setValue(orderAction);
  }

  setAmount(amount: number | string | null, emitEvent = true) {
    // c11n input fails when we set number value
    const newAmountValue = amount ? String(amount) : null;

    this.tradeTicket.controls.amount.setValue(newAmountValue, { emitEvent, onlySelf: !emitEvent });
  }

  setKeyword(value: string | null) {
    this.tradeTicket.controls.keyword.setValue(value);
  }

  setIsCostBasisEligible(isCostBasisEligible: boolean) {
    this.tradeTicket.controls.isCostBasisEligible.setValue(isCostBasisEligible);
  }

  setUpdateDefaultCostBasis(value: boolean, emitEvent = true) {
    this.tradeTicket.controls.updateDefaultCostBasis.setValue(value, { emitEvent, onlySelf: !emitEvent });
  }

  setSecurityAccountType(type: SecurityAccountTypes, options?: SetValueOptions) {
    this.tradeTicket.controls.securityAccountType.setValue(type, options);

    if (!type) {
      this.tradeTicket.controls.securityAccountType.markAsPristine();
      this.tradeTicket.controls.securityAccountType.markAsUntouched();
    }
  }

  getSecurityAccountType() {
    return this.tradeTicket.controls.securityAccountType.value;
  }

  getOrderAction(): OrderEnums.TransactionTypes | null {
    return this.tradeTicket.controls.action.value;
  }

  getCostBasisMethod(): CostBasisMethod {
    return this.tradeTicket.controls.costBasisMethod.value;
  }

  getKeyword(): string | null {
    return this.tradeTicket.controls.keyword.value;
  }

  setCostBasisMethod(method: CostBasisMethod | null, noPropagation?: boolean) {
    const opts = noPropagation ? { emitEvent: false, onlySelf: true } : {};
    this.tradeTicket.controls.costBasisMethod.setValue(method, opts);
  }

  updateChangeOrderTicketForm(order: Order.Order): void {
    const changeOrderForm = OrderUtil.makeChangeOrderForm(order);
    this.tradeTicket.patchValue({ ...changeOrderForm }, { emitEvent: false, onlySelf: true });
  }

  isOrderEqualTradeTicketForm(order: Order.Order): boolean {
    const initOrder = OrderUtil.makeChangeOrderForm(order);
    const actualOrder = this.tradeTicket.getRawValue();

    if (initOrder && typeof initOrder === 'object') {
      const keys = Object.keys(initOrder);

      return (
        keys.length ===
        keys.filter((key) => {
          switch (key) {
            case 'allOrNone':
              // for comparison purposes, null and false are equivalent
              return !!initOrder[key] === !!actualOrder[key];
            case 'duration':
              // for comparison purposes, 60-day GTC is not equal to itself -- this allows a user to extend the duration
              // of an order by re-submitting the order without making any changes to the form
              if (actualOrder[key] === Durations.GTC) {
                return false;
              }
              return actualOrder[key] === initOrder[key];
            case 'securityAccountType':
              // for comparison purposes, null and 'CASH' are equivalent
              return (
                String(!initOrder[key] ? SecurityAccountTypes.CASH : initOrder[key]) ===
                String(!actualOrder[key] ? SecurityAccountTypes.CASH : actualOrder[key])
              );
            default:
              return String(initOrder[key]) === String(actualOrder[key]);
          }
        }).length
      );
    }

    return false;
  }

  isOrderEqualTradeTicketFormCostBasis = (order: Order.Order): boolean =>
    OrderUtil.makeChangeOrderForm(order).costBasisMethod === this.tradeTicket.getRawValue().costBasisMethod;

  isOrderEqualSelectSharesForm(
    order: Order.Order,
    costBasisLots: SelectedLot[],
    selectedLots: RehydratableFormGroup<LotTableRowControls>[]
  ): boolean {
    if (!!order && !!costBasisLots && !!selectedLots) {
      const lots = CostBasisUtil.makeLotFormDataFromOrder(order, costBasisLots);
      const form = CostBasisUtil.makeLotsFormLotsGroups(selectedLots);

      return lodash.isEqual(lots, form);
    }
    return false;
  }

  triggerMfeAccountSelectorValidation(): void {
    this.tradeTicket.controls.accountId.markAsTouched();
    this.tradeTicket.controls.accountId.updateValueAndValidity({ emitEvent: false });
    this.accountSelectorControlPlane$.next({ eventType: ControlPlaneEventType.FORCE_CHANGE_DETECTION });
  }

  resetAmount(emitEvent = true) {
    this.setAmount(null, emitEvent);
    this.tradeTicket.controls.amount.markAsPristine();
    this.tradeTicket.controls.amount.markAsUntouched();
    this.resetAmountType(OrderEnums.AmountTypes.SHARES, false);
    this.setSharesValidators();
  }

  resetOrderFieldsOnTabSwitch() {
    const resetControls: (keyof TradeTicketForm)[] = ['duration', 'orderType', 'limitPrice', 'stopPrice', 'amount'];
    this.resetOnlyTheFollowingControls(resetControls);
  }

  isOrderInProgress() {
    return (
      this.tradeTicket.controls.action.dirty ||
      this.tradeTicket.controls.amount.dirty ||
      this.tradeTicket.controls.symbol.dirty
    );
  }

  private watchForLimitPriceResetConditions() {
    this.tradeTicket.controls.orderType.valueChanges
      .pipe(
        filter(() => !this.limitPriceIsVisible()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.tradeTicket.controls.limitPrice.reset());
  }

  private watchForStopPriceResetConditions() {
    this.tradeTicket.controls.orderType.valueChanges
      .pipe(
        filter(() => !this.stopPriceIsVisible()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.tradeTicket.controls.stopPrice.reset());
  }

  private watchForDurationAutoselectConditions() {
    merge(this.tradeTicket.controls.action.valueChanges, this.tradeTicket.controls.orderType.valueChanges)
      .pipe(
        map(() => [this.tradeTicket.controls.action.value, this.tradeTicket.controls.orderType.value]),
        tap(([action, orderType]) => this.setDurationAutoValues(action, orderType)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForAllOrNoneResetAmountConditions() {
    this.tradeTicket.controls.amount.valueChanges
      .pipe(
        filter((shares) => !!+shares),
        tap(() => this.tradeTicket.controls.allOrNone.setValue(null)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForAllOrNoneResetOrderTypeConditions() {
    this.tradeTicket.controls.orderType.valueChanges
      .pipe(
        filter((orderType) => !TradeTicketService.limitOrderTypes.includes(orderType)),
        tap(() => this.tradeTicket.controls.allOrNone.setValue(null)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForActionChangeAndResetCostBasis() {
    this.tradeTicket.controls.action.valueChanges
      .pipe(
        tap(() => {
          if (this.actionIsBuyOrSellShort()) {
            this.tradeTicket.controls.costBasisMethod.reset();
            this.tradeTicket.controls.updateDefaultCostBasis.reset();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private updateBrokerageAccountNumberStatus(): void {
    if (this.accountSelectionRequired) {
      this.tradeTicket.controls.brokerageAccountNumber.markAsTouched();
      this.tradeTicket.controls.brokerageAccountNumber.markAsDirty();
      this.triggerMfeAccountSelectorValidation();
    }
  }

  /**
   * Private method to update the cost basis control component visibility.
   *
   * @param {TradeTicketForm} controls - The trade ticket form controls
   * @returns {Observable<any>}
   */
  private updateCostBasisVisibility(controls: TradeTicketForm): Observable<any> {
    return of(controls).pipe(
      filter(({ isCostBasisEligible, orderType }) => {
        const amountControl = this.tradeTicket.get('amount');
        amountControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });

        this.costBasisIsVisible = false;

        return isCostBasisEligible && !!orderType && !this.controlHasError('amount') && !this.actionIsBuyOrSellShort();
      }),
      // Get the limit price, stop price and duration controls
      map(() => ['limitPrice', 'stopPrice', 'duration'].map((control) => this.tradeTicket.get(control))),
      // Check for limit price, stop price and duration control validity
      filter(([limitPrice, stopPrice, duration]) => {
        if (
          (this.limitPriceIsVisible() && (this.limitPriceHasRequiredWhenInError() || limitPrice.invalid)) ||
          (this.stopPriceIsVisible() && (this.stopPriceHasRequiredWhenInError() || stopPrice.invalid))
        ) {
          // Set the cost basis visibility flag to false
          // if the limit price or stop price is invalid
          this.costBasisIsVisible = false;
          return false;
        }

        return this.durationIsVisible() && duration.valid;
      }),
      // Set the cost basis visibility flag to true if all conditions are met
      tap(() => (this.costBasisIsVisible = true))
    );
  }

  private watchForTradeTicketValueChanges() {
    this.tradeTicket.valueChanges
      .pipe(
        tap(() => this.updateBrokerageAccountNumberStatus()),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap((tradeTicket: TradeTicketFormFields) => this.store.dispatch(createUpdateTradeTicketAction(tradeTicket))),
        // we need to delay for updateCostBasisVisibility call to ensure the form is updated before run the validity for amount field
        delay(0),
        switchMap((controls: TradeTicketForm) => this.updateCostBasisVisibility(controls)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
