<ng-container
  *ngIf="{
    changeOrderEnabled: (changeOrderEnabled$ | async),
    showButtons: (showButtons$ | async)
  } as view"
>
  <div
    class="open-order-item__actions c11n-text-md"
    [ngClass]="{
      'd-none': !view.showButtons,
      'min-width-230': isEditCostBasisButtonVisible
    }"
  >
    <ng-container *ngIf="view.showButtons">
      <button
        c11n-link
        *ngIf="isChangeOrderButtonVisible && securityIsEquity"
        iconName="edit"
        variant="secondary-independent"
        data-testid="changeOrderButton"
        [ngClass]="{ 'mb-0': isChangeOrderButtonVisible && !isCancelButtonVisible }"
        (click)="handleActionBtnClick('change')"
        attr.aria-label="{{
          content.openOrders.changeOrderFrom +
            ' ' +
            orderInstructionCodeLabel[order.orderInstructionCode] +
            ' ' +
            numberOfSharesOrDollars +
            content.openOrders.sharesOf +
            order.ticker +
            ', ' +
            order.securityDescription +
            ', ' +
            content.openOrders.orderDate +
            ' ' +
            (order.enteredTimeStamp | date : 'MM/dd/yyyy')
        }}"
      >
        {{ content.labels.changeOrder }}
      </button>
      <button
        c11n-link
        class="twe-text-bold"
        iconName="delete"
        variant="secondary-independent"
        *ngIf="isCancelButtonVisible && securityIsEquity"
        (click)="handleActionBtnClick('cancel')"
        attr.aria-label="{{
          content.openOrders.cancelOrderTo +
            ' ' +
            orderInstructionCodeLabel[order.orderInstructionCode] +
            ' ' +
            numberOfSharesOrDollars +
            (order.amountIndicator === amountIndicators.SHARES
              ? content.openOrders.sharesOf
              : content.openOrders.dollarsOf) +
            order.ticker +
            ', ' +
            order.securityDescription +
            ', ' +
            content.openOrders.orderDate +
            ' ' +
            (order.enteredTimeStamp | date : 'MM/dd/yyyy')
        }}"
      >
        {{ content.labels.cancelOrder }}
      </button>

      <button
        c11n-link
        iconName="edit"
        variant="secondary-independent"
        *ngIf="isEditCostBasisButtonVisible"
        (click)="handleActionBtnClick('editCostBasisMethod')"
        attr.aria-label="{{
          content.openOrders.editCostBasisMethodOrderTo +
            ' ' +
            orderInstructionCodeLabel[order.orderInstructionCode] +
            ' ' +
            numberOfSharesOrDollars +
            (order.amountIndicator === amountIndicators.SHARES
              ? content.openOrders.sharesOf
              : content.openOrders.dollarsOf) +
            order.ticker +
            ', ' +
            order.securityDescription +
            ', ' +
            content.openOrders.orderDate +
            ' ' +
            (order.enteredTimeStamp | date : 'MM/dd/yyyy')
        }}"
      >
        {{ content.editCostBasis.title }}
      </button>
    </ng-container>

    <span *ngIf="!securityIsEquity && (isCancelButtonVisible || isChangeOrderButtonVisible)" class="c11n-text-md ml-3">
      {{ content.openOrders.nonEquityText1 }} {{ nonEquitySecurityTypeText }}
      {{ content.openOrders.nonEquityText2 }}
      <a *ngIf="!isBeacon" c11n-link variant="primary-reinforced" [href]="orderStatusLink">
        {{ content.openOrders.orderStatusLinkText }}
      </a>
      <ng-container *ngIf="isBeacon">
        {{ content.openOrders.orderStatusLinkText }}
      </ng-container>
    </span>
  </div>
</ng-container>
