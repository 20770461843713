import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import * as Directives from '@app/etfs-equities/directives';
import * as Pipes from '@app/etfs-equities/pipes';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormStateService, SessionFormStateService } from '@vanguard/trade-standard-forms-lib-ng-18';
import {
  AnnouncementBannerComponent,
  ChipWithIconModule,
  CostBasisModule,
  CustomIconModule,
  FocusService,
  LotSelectionModule,
  OrderConfirmationHeaderModule,
  OrderSummaryModule,
  ProductDetailsTableModule,
  ProductListTableModule,
  ProspectusLinkModule,
  SelectedLotsModule,
  TcxChipModule,
  TimestampModule,
} from '@vanguard/trade-ui-components-lib-ng-18';
import { AccordionModule } from '@vg-constellation/angular-18/accordion';
import { BannerModule } from '@vg-constellation/angular-18/banner';
import { ButtonModule } from '@vg-constellation/angular-18/button';
import { CheckboxModule } from '@vg-constellation/angular-18/checkbox';
import { ChipModule } from '@vg-constellation/angular-18/chip';
import { DetailsModule } from '@vg-constellation/angular-18/details';
import { HintErrorModule } from '@vg-constellation/angular-18/hint-error';
import { IconComponent, IconModule } from '@vg-constellation/angular-18/icon';
import { InputModule } from '@vg-constellation/angular-18/input';
import { LabelModule } from '@vg-constellation/angular-18/label';
import { LinkModule } from '@vg-constellation/angular-18/link';
import { ModalDialogModule } from '@vg-constellation/angular-18/modal-dialog';
import { NavigationModule } from '@vg-constellation/angular-18/navigation';
import { RadioModule } from '@vg-constellation/angular-18/radio';
import { SearchModule } from '@vg-constellation/angular-18/search';
import { SegmentedControlModule } from '@vg-constellation/angular-18/segmented-control';
import { SelectModule } from '@vg-constellation/angular-18/select';
import { SpinnerComponent } from '@vg-constellation/angular-18/spinner';
import { TabsModule } from '@vg-constellation/angular-18/tabs';
import { TooltipModule } from '@vg-constellation/angular-18/tooltip';
import { IMaskModule } from 'angular-imask';
import { TdsExpansionPanelModule } from 'vg-trade-design-system';

import * as Components from './components';
import { EtfsEquitiesRoutingModule } from './etfs-equities-routing.module';
import * as Pages from './pages';
import { ServicesModule } from './services/services.module';
import * as Effects from './store/effects';
import * as Reducers from './store/reducers';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    Components.ContactUsModalComponent,
    Components.DollarsToSharesCalculatorComponent,
    Components.HeaderComponent,
    Components.HowToReadAQuoteModalComponent,
    Components.KeywordSearchControlComponent,
    Components.OpenOrdersComponent,
    Components.OrderDetailsComponent,
    Components.QuoteComponent,
    Components.SelectedAccountComponent,
    Components.RefreshButtonComponent,
    Components.TradeHelpModalComponent,
    Components.RuleContentComponent,
    Components.CancelOrderDetailComponent,
    Components.ChangeOrderDetailComponent,
    Components.CriticalErrorComponent,
    Components.TriggeredRulesModalComponent,
    Components.OrderTypesModalComponent,
    Components.ExitDialogComponent,
    Components.AccountsControlComponent,
    Components.AccountsErrorComponent,
    Components.AlertInfoComponent,
    Components.ServiceUnavailableMsgComponent,
    Components.SymbolSearchControlComponent,
    Components.CostBasisControlComponent,
    Components.CostBasisLotErrorModalComponent,
    Components.SecurityAccountTypeControlComponent,
    Components.SymbolModalComponent,
    Components.AllOrNoneControlComponent,
    Components.VolatilityBannerComponent,
    Components.TipsForTradingVolatilityModalComponent,
    Components.TransactionTypeControlComponent,
    Components.ExecutedPriceComponent,
    Components.SharesHeldComponent,
    Components.DurationControlComponent,
    Components.IpoModalComponent,
    Components.DurationModalComponent,
    Components.MutualFundErrorModalComponent,
    Components.FractionalSharesInfoModalComponent,
    Components.OrderTypeControlComponent,
    Components.SharesControlComponent,
    Components.PriceControlComponent,
    Components.InputLabelComponent,
    Components.TooltipComponent,
    Components.GenericTooltipModalComponent,
    Components.AmountTypeControlComponent,
    Components.ExtendedTradingDislocureComponent,
    Components.ExtendedHoursDisclosureModalComponent,
    Components.QuoteDisclaimerModalComponent,
    Components.DisclosureModalComponent,
    Components.NavigationTabsComponent,
    Components.QuoteDisclaimerModalComponent,
    Components.DisclosureModalComponent,
    Components.NavigationTabsComponent,
    Components.TimeoutErrorModalComponent,
    Components.ExitTradeModalComponent,
    Components.SingleStockPriceBandsModalComponent,
    Components.DollarBasedInvestingLearnMoreModalComponent,
    Components.CancelWarningModalComponent,
    Components.CancelErrorModalComponent,
    Components.CannotModifyModalComponent,
    Components.SpecIdInfoModalComponent,
    Components.MixedMethodWarningModalComponent,
    Components.GenericErrorModalComponent,
    Components.CostBasisModalComponent,
    Components.TradeSymbolKeywordComponent,
    Components.OrderTypeLimitOnlyComponent,
    Components.ExtendedHoursExpiredModalComponent,
    Components.ExtendedHoursBannerComponent,
    Components.OrderSummaryComponent,
    Components.MarginRequirementsVgETFsBannerComponent,
    Components.OrderValidationBannerComponent,
    Components.CardCapComponent,
    Components.OpenOrderItemActionsComponent,
    Components.TradeButtonsComponent,
    Components.EcbOrderSummaryComponent,
    Components.OrderConfirmationHeaderComponent,
    Components.HoldingsTableComponent,
    Components.CannotTradeShingleComponent,
    Components.ManagedAccountShingleComponent,
    Components.ShingleComponent,
    Components.HoldingsFilterComponent,
    Components.OrderInProgressModalComponent,
    Pages.CancelPageComponent,
    Pages.CancelSuccessPageComponent,
    Pages.ConfirmPageComponent,
    Pages.CostBasisUnavailableModalComponent,
    Pages.HoldingsPageComponent,
    Pages.NoChangesErrorModalComponent,
    Pages.OpenOrdersPageComponent,
    Pages.PreviewPageComponent,
    Pages.SelectSharesPageComponent,
    Pages.TradePageComponent,
    Pages.EditCostBasisComponent,
    Pages.ExtendedTradingComponent,
    Pages.ModernHoldingsPageComponent,
    Pipes.CostBasisExpandPipe,
    Pipes.CostBasisLongNamePipe,
    Pipes.DatePipe,
    Pipes.LongNameSplit,
    Pipes.OpenOrderDurationPipe,
    Pipes.QuoteTickerLongNamePipe,
    Pipes.SubDollarCurrency,
    Pipes.SanitizeHtmlPipe,
    Directives.SubmitButtonOnceDirective,
    Directives.SegmentControlResetDirective,
  ],
  exports: [
    Components.TradeHelpModalComponent,
    Components.ContactUsModalComponent,
    Components.HeaderComponent,
    Components.ExitDialogComponent,
    Components.AlertInfoComponent,
    Components.DisclosureModalComponent,
    Components.QuoteDisclaimerModalComponent,
    Components.NavigationTabsComponent,
    Components.ServiceUnavailableMsgComponent,
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      Effects.AccountEffects,
      Effects.CostBasisEffects,
      Effects.FundsAvailableEffects,
      Effects.OrderEffects,
      Effects.QuoteEffects,
      Effects.OrderCancelEffects,
      Effects.EnvironmentEffects,
      Effects.MarketDataEffects,
      Effects.KeywordSearchEffects,
      Effects.ExtendedHoursEffects,
      Effects.TradeTicketEffects,
    ]),
    EtfsEquitiesRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature('tayne', {
      account: Reducers.accountReducer,
      costBasis: Reducers.costBasisReducer,
      fundsAvailable: Reducers.fundsAvailableReducer,
      order: Reducers.orderReducer,
      quote: Reducers.quoteReducer,
      tradeTicket: Reducers.tradeTicketReducer,
      history: Reducers.historyReducer,
      clientData: Reducers.clientDataReducer,
      orderCancel: Reducers.orderCancelReducer,
      changeOrder: Reducers.changeOrderReducer,
      environment: Reducers.environmentReducer,
      screenSize: Reducers.screenSizeReducer,
      extendedHours: Reducers.extendedHoursReducer,
      activeNavTab: Reducers.activeNavTabReducer,
    }),
    TdsExpansionPanelModule,
    Components.OpenOrderItemCellComponent,
    ChipModule,
    ModalDialogModule,
    BannerModule,
    LinkModule,
    IconModule,
    IconComponent,
    HintErrorModule,
    SearchModule,
    InputModule,
    TooltipModule,
    LabelModule,
    SegmentedControlModule,
    IMaskModule,
    AnnouncementBannerComponent,
    NavigationModule,
    ServicesModule,
    SelectModule,
    TabsModule,
    DetailsModule,
    SpinnerComponent,
    AccordionModule,
    ButtonModule,
    RadioModule,
    CheckboxModule,
    CostBasisModule,
    TimestampModule,
    ProspectusLinkModule,
    CustomIconModule,
    OrderSummaryModule,
    ProductDetailsTableModule,
    ChipWithIconModule,
    OrderConfirmationHeaderModule,
    LotSelectionModule,
    SelectedLotsModule,
    ProductListTableModule,
    Components.OpenOrderItemCellComponent,
    Components.SelectedLotsModalComponent,
    TcxChipModule,
    Components.AccountDetailsModalComponent,
  ],
  providers: [
    DecimalPipe,
    Pipes.SubDollarCurrency,
    Pipes.CostBasisExpandPipe,
    Pipes.QuoteTickerLongNamePipe,
    Pipes.AccountNamePipe,
    {
      provide: FormStateService,
      useClass: SessionFormStateService,
    },
    TitleCasePipe,
    FocusService,
  ],
})
export class EtfsEquitiesModule {}
