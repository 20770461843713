import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
@Component({
  selector: 'twe-disclosure-modal',
  templateUrl: './disclosure-modal.component.html',
  styleUrls: ['./disclosure-modal.component.scss'],
})
export class DisclosureModalComponent {
  // Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;
  // Public variables...
  content: JsonContent = content;
}
