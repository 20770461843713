import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OrderEnums } from '@app/etfs-equities/enums';
import { JsonContent } from '@app/etfs-equities/models';
import { SubDollarCurrency } from '@app/etfs-equities/pipes';
import { TradeTicketService } from '@app/etfs-equities/services';
import {
  selectAmount,
  selectEstimatedShares,
  selectPrincipal,
  selectQuoteIsVgETF,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import {
  ProductDetailsTable,
  ProductDetailsTableCellContentType,
  ProductDetailsTableRowType,
} from '@vanguard/trade-ui-components-lib-ng-18';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OrderSummaryComponent implements OnInit {
  //  Public variables...
  content: JsonContent = content;
  amountTypes = OrderEnums.AmountTypes;

  // Observable variables...
  principal$: Observable<number>;
  estimatedShares$: Observable<number>;
  amount$: Observable<number>;
  quoteIsVgEtf$: Observable<boolean>;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly decimalPipe: DecimalPipe,
    private readonly subDollarCurrency: SubDollarCurrency,
    private readonly tradeTicketService: TradeTicketService
  ) {}

  ngOnInit(): void {
    this.principal$ = this.store.select(selectPrincipal);
    this.estimatedShares$ = this.store.select(selectEstimatedShares);
    this.amount$ = this.store.select(selectAmount);
    this.quoteIsVgEtf$ = this.store.select(selectQuoteIsVgETF);
  }

  getTableData(view): ProductDetailsTable {
    const isDollarAmountAndVgEtf = view.quoteIsVgEtf && this.tradeTicketService.amountTypeIsDollars();

    return {
      rows: [
        {
          cell: {
            label: content.labels.commission,
            content: content.labels.free,
            contentType: ProductDetailsTableCellContentType.CHIP,
          },
          rowType: ProductDetailsTableRowType.SIDE_BY_SIDE_CELL_ROW,
        },
        {
          cell: {
            label: isDollarAmountAndVgEtf ? content.labels.estimatedShares : content.labels.shares,
            content:
              this.negativeDash(view.amount) +
              (this.decimalPipe.transform(isDollarAmountAndVgEtf ? view.estimatedShares : view.amount, '1.4-4') || '—'),
            contentType: ProductDetailsTableCellContentType.TEXT,
          },
          rowType: ProductDetailsTableRowType.SIDE_BY_SIDE_CELL_ROW,
        },
        {
          cell: {
            label: isDollarAmountAndVgEtf ? content.labels.amount : content.labels.estimatedAmount,
            content: this.subDollarCurrency.transform(isDollarAmountAndVgEtf ? view.amount : view.principal) || '$—',
            contentType: ProductDetailsTableCellContentType.TEXT,
          },
          rowType: ProductDetailsTableRowType.SIDE_BY_SIDE_CELL_ROW,
        },
      ],
    };
  }

  negativeDash(amount: number): string {
    return this.tradeTicketService.transactionTypeIsSellShort() && amount > 0 ? '-' : '';
  }
}
