<c11n-modal-dialog
  #ipoModal
  [headingText]="content.ipoModal.title"
  [primaryButtonLabel]="content.labels.ok"
  (primaryClick)="ipoModal.closeDialogModal()"
>
  <div c11n-modal-dialog-body>
    <p [innerHTML]="content.ipoModal.body"></p>
    <ul>
      <li class="c11n-space-stack-0_5x" [innerHtml]="content.ipoModal.ipoFirstListItem"></li>
      <li class="c11n-space-stack-0_5x" [innerHtml]="content.ipoModal.ipoSecondListItem"></li>
      <li class="c11n-space-stack-0_5x" [innerHtml]="content.ipoModal.ipoThirdListItem"></li>
      <li class="c11n-space-stack-0_5x" [innerHtml]="content.ipoModal.ipoFourthListItem"></li>
    </ul>

    <div *ngIf="!isBeacon" class="c11n-space-stack-2x c11n-space--force">
      <a c11n-link variant="primary-independent" [attr.href]="ipoLink" target="_blank">{{
        content.ipoModal.linkText
      }}</a>
    </div>
  </div>
</c11n-modal-dialog>
