import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { JsonContent, Order } from '@app/etfs-equities/models';
import { SummaryCostBasisLot } from '@app/etfs-equities/models/cost-basis.model';
import { TradeTicketService } from '@app/etfs-equities/services';
import { selectOrder } from '@app/etfs-equities/store';
import { CostBasisUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { SelectedLotsModule } from '@vanguard/trade-ui-components-lib-ng-18';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { IMaskModule } from 'angular-imask';
import { AnyMaskedOptions } from 'imask';
import { combineLatest, map, Observable, Subject } from 'rxjs';

@Component({
  selector: 'twe-selected-lots-modal',
  standalone: true,
  imports: [ModalDialogComponent, AsyncPipe, NgIf, IMaskModule, SelectedLotsModule],
  templateUrl: './selected-lots-modal.component.html',
  styleUrl: './selected-lots-modal.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedLotsModalComponent implements OnInit {
  // Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;

  // Public variables...
  content: JsonContent = content;
  imaskConfig: AnyMaskedOptions = {
    mask: Number,
    signed: true,
    scale: 4,
    padFractionalZeros: true,
    radix: '.',
    thousandsSeparator: ',',
  };

  // Observables/Subjects...
  order$: Observable<Order.Order>;
  unsubscribe$ = new Subject<void>();
  summaryLots$: Observable<SummaryCostBasisLot[]>;

  constructor(private readonly store: Store, private readonly tradeTicketService: TradeTicketService) {}

  ngOnInit(): void {
    this.order$ = this.store.select(selectOrder);
    this.initializeLots();
  }

  private initializeLots(): void {
    this.summaryLots$ = combineLatest([this.order$]).pipe(
      map(([order]) => {
        if (!order) {
          return [];
        }

        let summaryCostBasisLots: SummaryCostBasisLot[] = [];
        order.costBasisLots?.forEach((lot) => {
          summaryCostBasisLots.push({
            acquiredDate: lot.aquiredDate,
            estimatedProceeds: CostBasisUtil.calculateEstimatedProceeds(
              this.tradeTicketService.tradeTicket.getRawValue(),
              lot
            ),
            numberOfSharesSelected: lot.selectedLotQuantity,
          });
        });

        return summaryCostBasisLots;
      })
    );
  }
}
