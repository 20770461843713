import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdobeAnalyticsService } from '@app/core/services';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import { createLoadEcnHours, createSetExitBtnStatusAction, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';

@Component({
  selector: 'twe-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // Decorators...

  @Input()
  exitButtonHref = environment.secureSiteUrls.dashboard;

  @Input()
  tweNavIsVisible = true;

  @Input()
  pageTitle = '';

  @Input()
  showContactUsTradeHelp = true;

  @Input()
  showExitOnly = false;

  @Input()
  activeNavTab = '';

  // Public variables...

  content: JsonContent = content;
  isBeacon = false;

  constructor(
    public router: Router,
    private readonly store: Store<TayneState>,
    public readonly windowService: WindowService,
    public readonly adobeService: AdobeAnalyticsService
  ) {}

  ngOnInit(): void {
    this.loadEcnHours();
    this.isBeacon = this.windowService.getIsBeacon();
  }

  handleExitBtnClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.adobeService.sendProcessAbandoned({ processAbandonmentType: 'exit' });
    this.store.dispatch(createSetExitBtnStatusAction(true));
  }

  loadEcnHours() {
    this.store.dispatch(createLoadEcnHours());
  }
}
