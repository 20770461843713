import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ChannelTypeEnum } from '@app/core/enums/otel-lib-enums';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { environment } from '@env/environment';
import { Window } from '@etfs-equities/models';
import { ChannelType } from '@vanguard/invest-otel-lib/common';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  public readonly $window: Window;

  prevUrl: string;
  assetsPath = '';

  constructor(@Inject(DOCUMENT) public readonly $document: Document, public readonly router: Router) {
    this.$window = this.$document.defaultView;

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.prevUrl = this.router.url;
    });
  }

  /**
   *
   * @param link Pass string value
   * @param target Pass in target property values from the anchor tag
   */
  navigateToExternalLink(link: string, target: '_self' | '_blank' = '_self'): void {
    this.$window.open(link, target);
  }

  /**
   * @returns the URL that the user navigated from
   */
  userNavigationUrl(): string {
    return this.prevUrl?.includes(CONSTANTS.OPEN_ORDERS_PATH) ? this.prevUrl : environment.secureSiteUrls.orderStatus;
  }

  /**
   * @returns the hostname
   */
  getHostname(): string {
    return this.$window.location.hostname;
  }

  /**
   * @returns the search
   */
  getSearch(): URLSearchParams {
    return new URLSearchParams(this.$window.location.search);
  }

  getIsBeacon(): boolean {
    const beaconTag: Element = this.$document.querySelector('meta[name="isBeacon"]');
    return !!(beaconTag?.getAttribute('content') === 'true');
  }

  getIsAndroid(): boolean {
    const userAgent = navigator.userAgent?.toLowerCase() || '';
    return userAgent.indexOf('android') > -1;
  }

  getChannelType(): ChannelType {
    return this.getIsBeacon() ? ChannelTypeEnum.MOBILE : ChannelTypeEnum.WEB;
  }

  getServerlessPath(): string {
    const origin = this.$window.location.origin;
    const folder = this.getServerlessFolder();
    return `${origin}/${folder}/`;
  }

  getServerlessFolder(): string {
    return this.$window.location.pathname.split('/')[1];
  }

  getLocation(): Location {
    return this.$window.location;
  }

  print(): void {
    this.$window.print();
  }
}
