<ng-container
  *ngIf="{
    isScreenXSmall: (isScreenXSmall$ | async)
  } as view"
>
  <div class="shingle-container">
    <div class="c11n-space-stack-8x">
      <c11n-icon
        *ngIf="iconType === 'error'"
        class="error-shingle-icon-container"
        name="error-fill"
        size="medium"
        scale="larger"
      ></c11n-icon>
    </div>
    <div class="shingle-text twe-c11n-color-ink-800 col-lg-6 col-md-12 c11n-text-lg c11n-space-stack-8x"
      >{{ message }}
      <ng-content></ng-content>
    </div>
    <div class="shingle-buttons">
      <c11n-button
        *ngIf="primaryButtonLabel"
        class="c11n-space-inline-right-1_5x"
        buttonType="button"
        size="medium"
        variant="primary"
        data-testid="btn-shingle-primary"
        [fullWidth]="view.isScreenXSmall"
        [labelText]="primaryButtonLabel"
        [iconName]="primaryButtonIconName"
        iconPosition="trailing"
        (clickEvent)="primaryButtonClicked.emit()"
      ></c11n-button>
      <c11n-button
        *ngIf="secondaryButtonLabel"
        class="shingle-secondary-button"
        buttonType="button"
        size="medium"
        variant="secondary"
        data-testid="btn-shingle-secondary"
        [fullWidth]="view.isScreenXSmall"
        [labelText]="secondaryButtonLabel"
        [iconName]="secondaryButtonIconName"
        iconPosition="trailing"
        (clickEvent)="secondaryButtonClicked.emit()"
      ></c11n-button>
    </div>
  </div>
</ng-container>
