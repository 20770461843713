import { Component, OnInit, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { selectIsFactset } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-quote-disclaimer-modal',
  templateUrl: './quote-disclaimer-modal.component.html',
  styleUrls: ['./quote-disclaimer-modal.component.scss'],
})
export class QuoteDisclaimerModalComponent implements OnInit {
  // Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;

  // Public observable/subjects...
  isFactset$: Observable<boolean>;

  // Public variables...
  content: JsonContent = content;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.isFactset$ = this.store.pipe(select(selectIsFactset));
  }
}
