import { AbstractControl } from '@angular/forms';
import content from '@content/content.json';
import { SingleErrorValidationError, SingleErrorValidatorFn } from '@vanguard/trade-standard-forms-lib-ng-18';

export const accountValidator: SingleErrorValidatorFn<AbstractControl> = (control: AbstractControl) => {
  let error: SingleErrorValidationError | null = null;
  if (control.touched && !control.value) {
    error = {
      single: {
        error: 'required',
        errorMessage: content.validation.accountSelectionRequired,
      },
    };
  }

  return error;
};
