<div class="d-flex align-items-baseline">
  <c11n-checkbox
    class="all-or-none-checkbox"
    labelText="{{ content.labels.allOrNone }} ({{ content.labels.optional }})"
  >
    <input
      c11nCheckboxInput
      id="allOrNoneCheckbox"
      type="checkbox"
      name="allOrNoneCheckbox"
      [formControl]="tradeTicketService?.tradeTicket?.controls.allOrNone"
    />
  </c11n-checkbox>

  <twe-tooltip [tooltipHeader]="content?.labels.allOrNone" [autoOrientation]="false" direction="above">
    <p>
      {{ content.guidelet.allOrNone.guideletContent }}
    </p>
  </twe-tooltip>
</div>
