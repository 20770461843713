import { CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CashMarginIndicatorTypes, OrderEnums } from '@app/etfs-equities/enums';
import { Account, CostBasis, FundsAvailable, JsonContent, Order, Quote } from '@app/etfs-equities/models';
import { AccountNamePipe, QuoteTickerLongNamePipe, SubDollarCurrency } from '@app/etfs-equities/pipes';
import { ProspectusService, TradeTicketService, WindowService } from '@app/etfs-equities/services';
import {
  selectEstimatedShares,
  selectFundsAvailable,
  selectOrder,
  selectOrderContent,
  selectOrderFormatSharesDisplay,
  selectPrincipal,
  selectQuote,
  selectSelectedAccount,
  selectShouldDisplayProspectusLink,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { CostBasisUnavailableModalComponent } from '@etfs-equities/pages';
import { CostBasisService } from '@etfs-equities/services/cost-basis/cost-basis.service';
import { select, Store } from '@ngrx/store';
import {
  CostBasisMethod,
  OrderSummarySection,
  OrderSummarySectionRow,
  OrderSummarySectionRowSubContentType,
} from '@vanguard/trade-ui-components-lib-ng-18';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { AccountDetailsModalComponent } from '../account-details-modal/account-details-modal.component';
import { SelectedLotsModalComponent } from '../selected-lots-modal/selected-lots-modal.component';
@Component({
  selector: 'twe-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  // Decorators...
  @ViewChild(CostBasisUnavailableModalComponent)
  costBasisMethodUnavailableModal: CostBasisUnavailableModalComponent;

  @ViewChild('selectedLotsModal')
  selectedLotsModal: SelectedLotsModalComponent;

  @ViewChild(AccountDetailsModalComponent)
  accountDetailsModal: AccountDetailsModalComponent;

  @Input()
  enableProspectusLink = true;

  // Enums for form control values...
  amountTypes = OrderEnums.AmountTypes;

  // Store state...
  selectedAccount$: Observable<Account.Account>;
  order$: Observable<Order.Order>;
  principal$: Observable<number>;
  quote$: Observable<Quote>;
  orderContent$: Observable<string>;
  estimatedShares$: Observable<number>;
  formatSharesDisplay$: Observable<string>;
  fundsAvailable$: Observable<FundsAvailable>;
  shouldDisplayProspectusLink$: Observable<boolean>;
  orderSummary$: Observable<OrderSummarySection[]>;

  // Local state...
  content: JsonContent = content;
  specIdWasSelected: boolean;
  totalShares: number;
  totalSharesCost: number;
  isStopLimit = false;
  isStopOrLimit = false;
  isMarket = false;
  linkTarget: string;

  _titleFontWeight = 400;

  currencyPipe = inject(CurrencyPipe);
  decimalPipe = inject(DecimalPipe);
  subDollarCurrencyPipe = inject(SubDollarCurrency);
  quoteTickerLongNamePipe = inject(QuoteTickerLongNamePipe);
  titleCasePipe = inject(TitleCasePipe);

  unsubscribe$ = new Subject<void>();

  constructor(
    private readonly store: Store<TayneState>,
    private readonly costBasisService: CostBasisService,
    private readonly tradeTicketService: TradeTicketService,
    private readonly prospectusService: ProspectusService,
    private readonly windowService: WindowService,
    private readonly accountNamePipe: AccountNamePipe
  ) {}

  get showCostBasis() {
    return this.tradeTicketService.costBasisIsVisible;
  }

  ngOnInit() {
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.principal$ = this.store.pipe(select(selectPrincipal));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.order$ = this.store.pipe(select(selectOrder));
    this.orderContent$ = this.store.pipe(select(selectOrderContent));
    this.estimatedShares$ = this.store.pipe(select(selectEstimatedShares));
    this.formatSharesDisplay$ = this.store.pipe(select(selectOrderFormatSharesDisplay));
    this.fundsAvailable$ = this.store.pipe(select(selectFundsAvailable));
    this.shouldDisplayProspectusLink$ = this.store.pipe(select(selectShouldDisplayProspectusLink));

    this.order$
      .pipe(
        filter((order) => !!order),
        tap((order) => {
          this.specIdWasSelected = order.costBasisMethod === CostBasisMethod.SPEC_ID;

          if (order.costBasisLots) {
            this.calculateTotalSharesSold(order.costBasisLots);
            this.calculateTotalSharesCost(order.costBasisLots);
          }
          this.isMarket = order.orderType === OrderEnums.Types.MARKET;
          this.isStopLimit = order.orderType === OrderEnums.Types.STOP_LIMIT;
          this.isStopOrLimit = order.orderType === OrderEnums.Types.STOP || order.orderType === OrderEnums.Types.LIMIT;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.linkTarget = this.windowService.getIsBeacon() ? '_self' : '_blank';

    this.getOrderSummary();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openCostBasisUnavailableModal() {
    this.costBasisService.openUnavailableModal$.next();
  }

  handleClickEvent(event) {
    switch (event) {
      case 'view-details':
        this.accountDetailsModal.modal.openModalDialog();
        break;
      case 'view-specId':
        this.selectedLotsModal.modal.openModalDialog();
        break;
      case 'view-cost-basis-unavailable-modal':
        this.openCostBasisUnavailableModal();
        break;
      case 'view-prospectus':
        this.order$
          .pipe(
            take(1),
            tap((order) => {
              this.prospectusService.logProspectusData(order);
            })
          )
          .subscribe();
        break;
    }
  }

  private calculateTotalSharesSold(selectedLots: CostBasis.SelectedLot[]) {
    this.totalShares = 0;

    selectedLots.forEach((lot) => (this.totalShares += +lot.selectedLotQuantity));
  }

  private calculateTotalSharesCost(selectedLots: CostBasis.SelectedLot[]) {
    this.totalSharesCost = 0;

    selectedLots.forEach((lot) => (this.totalSharesCost += +(lot.selectedLotQuantity * lot.costperShare).toFixed(2)));
  }

  private isAmountTypeDollarsOrder(order: Order.Order): boolean {
    return order.amountType === this.amountTypes.DOLLARS;
  }

  private displayProspectusLink(shouldDisplayProspectusLink: boolean): boolean {
    return shouldDisplayProspectusLink && this.enableProspectusLink;
  }

  private allOrNoneRow(amountRow, allOrNoneValue: string): OrderSummarySectionRow {
    if (!allOrNoneValue) {
      return amountRow;
    }

    return {
      ...amountRow,
      subContentRight: [
        {
          text: allOrNoneValue,
          type: OrderSummarySectionRowSubContentType.TEXT,
          dataCy: 'all-or-none',
        },
      ],
    };
  }

  private getCostBasisSummaryRow(order: Order.Order) {
    if (
      order?.transactionType !== OrderEnums.TransactionTypes.SELL &&
      order?.transactionType !== OrderEnums.TransactionTypes.BUY_TO_COVER
    ) {
      return [];
    }
    const baseObject = {
      titleLeft: { text: this.content.labels.costBasis, titleFontWeight: this._titleFontWeight },
      isUnderlined: true,
      dataCy: 'sub-title-left',
    };

    const costBasisMethod = order.costBasisMethod ?? undefined;

    switch (costBasisMethod) {
      case undefined:
        return [
          {
            ...baseObject,
            titleRight: this.content.labels.unavailable,
            subContentRight: [
              {
                text: this.content.labels.viewDetails,
                type: OrderSummarySectionRowSubContentType.LINK_BUTTON,
                clickID: 'view-cost-basis-unavailable-modal',
              },
            ],
          },
        ];
      case CostBasisMethod.SPEC_ID:
        return [
          {
            ...baseObject,
            titleRight: this.content.labels.specIdExpanded,
            flexibleRightColumn: true,
            subContentRight: [
              {
                text: this.content.orderDetail.specID.viewSelectedShares,
                type: OrderSummarySectionRowSubContentType.LINK_BUTTON,
                clickID: 'view-specId',
                dataCy: 'view-specId',
              },
            ],
          },
        ];
      default:
        return [
          {
            ...baseObject,
            titleRight: costBasisMethod,
          },
        ];
    }
  }

  private getLimitPriceRow(order: Order.Order) {
    return order?.orderType === OrderEnums.Types.LIMIT || order?.orderType === OrderEnums.Types.STOP_LIMIT
      ? [
          {
            titleLeft: { text: this.content.labels.limitPrice, titleFontWeight: this._titleFontWeight },
            titleRight: this.subDollarCurrencyPipe.transform(order.limitPrice) || '$—',
            isUnderlined: true,
            dataCy: 'sub-title-left',
          },
        ]
      : [];
  }

  private getStopPriceRow(order: Order.Order) {
    return order?.orderType === OrderEnums.Types.STOP || order?.orderType === OrderEnums.Types.STOP_LIMIT
      ? [
          {
            titleLeft: { text: this.content.labels.stopPrice, titleFontWeight: this._titleFontWeight },
            titleRight: this.subDollarCurrencyPipe.transform(order.stopPrice) || '$—',
            isUnderlined: true,
            dataCy: 'sub-title-left',
          },
        ]
      : [];
  }

  private getMarginSubtypeRow(selectedAccount: Account.Account, order: Order.Order) {
    return selectedAccount?.marginCode === CashMarginIndicatorTypes.MARGIN
      ? [
          {
            titleLeft: { text: this.content.labels.marginTradeType, titleFontWeight: this._titleFontWeight },
            titleRight: this.titleCasePipe.transform(order.securityAccountType),
            isUnderlined: true,
            flexibleRightColumn: true,
            dataCy: 'sub-title-left',
          },
        ]
      : [];
  }

  private callProspectusService(shouldDisplayProspectusLink: boolean, order: Order.Order) {
    return shouldDisplayProspectusLink
      ? [
          {
            type: OrderSummarySectionRowSubContentType.PROSPECTUS_LINK,
            prospectusData: this.prospectusService.getProspectusData(order),
            clickID: 'view-prospectus',
            text: this.content.labels.viewProspectus,
            linkTarget: this.linkTarget,
          },
        ]
      : [];
  }

  private getOrderSummary(): void {
    this.orderSummary$ = combineLatest([
      this.selectedAccount$,
      this.fundsAvailable$,
      this.quote$,
      this.estimatedShares$,
      this.order$,
      this.principal$,
      this.formatSharesDisplay$,
      this.shouldDisplayProspectusLink$,
    ]).pipe(
      take(1),
      map(
        ([
          selectedAccount,
          fundsAvailable,
          quote,
          estimatedShares,
          order,
          principal,
          formatSharesDisplay,
          shouldDisplayProspectusLink,
        ]) => {
          if (!order) {
            return [];
          }

          const sellingOrBuyingTitle = order.transactionType.includes('Buy')
            ? this.content.confirmationSummary.whatYoureBuying
            : this.content.confirmationSummary.whatYoureSelling;

          const displayProspectusLink = this.displayProspectusLink(shouldDisplayProspectusLink);

          const settlementBalance = this.currencyPipe.transform(fundsAvailable?.fundsAvailableToTrade) ?? '$-';

          const sharesLabel = this.isAmountTypeDollarsOrder(order)
            ? this.content.labels.estimatedShares
            : this.content.labels.shares;

          const sharesValue = this.isAmountTypeDollarsOrder(order)
            ? this.decimalPipe.transform(estimatedShares, '1.4-4')
            : formatSharesDisplay;

          const amountLabel = this.isAmountTypeDollarsOrder(order)
            ? content.labels.amount
            : content.labels.estimatedAmount;

          const amountValue = this.isAmountTypeDollarsOrder(order)
            ? this.subDollarCurrencyPipe.transform(order?.shares) || '$—'
            : this.subDollarCurrencyPipe.transform(principal) || '$—';

          const allOrNoneValue = order?.allOrNone ? this.content.labels.allOrNone : '';

          const allOrNoneRowItem = this.allOrNoneRow(
            {
              titleLeft: { text: amountLabel, titleFontWeight: this._titleFontWeight },
              titleRight: amountValue,
              isUnderlined: true,
              flexibleRightColumn: true,
              dataCy: 'sub-title-left',
            },
            allOrNoneValue
          );
          return [
            {
              titleLeft: this.content.labels.account,
              subTitleLeft: this.accountNamePipe.transform(selectedAccount),
            },
            {
              titleLeft: this.content.labels.balance,
              isTitleUnderlined: true,
              rows: [
                {
                  titleLeft: {
                    text: this.content.confirmationSummary.fundsAvailableToTrade,
                    tooltipContent: this.content.confirmationSummary.orderDetailsBalancesFundsAvailable,
                    tooltipColorMode: 'on-light',
                  },
                  titleRight: settlementBalance,
                  isUnderlined: true,
                  flexibleRightColumn: true,
                  subContentRight: [
                    {
                      text: this.content.labels.viewDetails,
                      type: OrderSummarySectionRowSubContentType.LINK_BUTTON,
                      clickID: 'view-details',
                    },
                  ],
                },
              ],
            },
            {
              titleLeft: sellingOrBuyingTitle,
              isTitleUnderlined: true,
              rows: [
                {
                  titleLeft: { text: quote?.tickerSymbol },
                  contentLeft: [{ text: this.quoteTickerLongNamePipe.transform(quote, false) }],
                  isUnderlined: true,
                  // the truthy value is an empty space because subContentRight will not display if it is an empty string
                  titleRight: displayProspectusLink ? ' ' : '',
                  flexibleRightColumn: true,
                  subContentRight: this.callProspectusService(displayProspectusLink, order),
                  dataCy: 'sub-title-left',
                },
                ...this.getMarginSubtypeRow(selectedAccount, order),
                {
                  titleLeft: { text: sharesLabel, titleFontWeight: this._titleFontWeight },
                  titleRight: sharesValue,
                  isUnderlined: true,
                  flexibleRightColumn: true,
                  dataCy: 'sub-title-left',
                },
                {
                  titleLeft: { text: this.content.labels.orderType, titleFontWeight: this._titleFontWeight },
                  titleRight: order.orderType,
                  isUnderlined: true,
                  dataCy: 'sub-title-left',
                },
                ...this.getLimitPriceRow(order),
                ...this.getStopPriceRow(order),
                {
                  titleLeft: { text: this.content.labels.duration, titleFontWeight: this._titleFontWeight },
                  titleRight: order?.orderDuration || '&mdash;',
                  isUnderlined: true,
                  dataCy: 'sub-title-left',
                },
                {
                  titleLeft: { text: this.content.labels.commission, titleFontWeight: this._titleFontWeight },
                  titleRight: this.content.labels.free,
                  isUnderlined: true,
                  dataCy: 'sub-title-left',
                },
                {
                  ...allOrNoneRowItem,
                },
                ...this.getCostBasisSummaryRow(order),
              ],
            },
          ];
        }
      )
    );
  }
}
