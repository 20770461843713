import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';

import { AdobeAnalyticsService } from '../../../core/services';

@Component({
  selector: 'twe-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent {
  //  Decorators...

  @ViewChild('modal')
  modal: ModalDialogComponent;

  //  Public variables...

  content: JsonContent = content;

  constructor(public adobeService: AdobeAnalyticsService) {}
}
