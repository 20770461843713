import { Pipe, PipeTransform } from '@angular/core';
import { Quote } from '@app/etfs-equities/models';

@Pipe({
  name: 'tweQuoteTickerLongName',
})
export class QuoteTickerLongNamePipe implements PipeTransform {
  transform(quote: Quote, hasSymbol = true): string | null {
    if (quote === null) {
      return null;
    }
    const includeSymbol = hasSymbol ? ` (${quote.tickerSymbol})` : '';

    if (quote.tickerLongName) {
      return `${quote.tickerLongName}${includeSymbol}`;
    }

    if (quote.fullSecurityName) {
      return `${quote.fullSecurityName}${includeSymbol}`;
    }
  }
}
