import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UILogCode } from '@app/core/enums/ui-log-code-enum';
import { LegacyCookie, LegacyCookieName } from '@app/core/models/legacy-cookie.model';
import { LoggerService } from '@app/core/services';
import { ROUTES } from '@app/etfs-equities/constants';
import { Order } from '@app/etfs-equities/models';
import { OrderService, WindowService } from '@app/etfs-equities/services';
import { EnvironmentService } from '@app/shared/services/environment/environment.service';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';

const removeRouteParamFromUrl = (location: Location) => {
  let url = new URL(location.toString());

  // Clear route parameter
  url.searchParams.delete('route');

  // Replace the current history entry
  history.replaceState({}, '', url.toString());
};

export const serverlessRouteGuard: CanActivateFn = async () => {
  const environmentService = inject(EnvironmentService);
  const router = inject(Router);
  const windowService = inject(WindowService);
  const params = windowService.getSearch();
  const paramsObj = Object.fromEntries(params);

  let allowAccess = false;

  if (environmentService.isLocalEnvironment()) {
    // bypassing this guard for local environment
    return true;
  }

  if (environmentService.isServerlessEnvironment()) {
    if (paramsObj.route) {
      // remove route parameter from URL do not provide infinity redirects
      removeRouteParamFromUrl(windowService.$window.location);

      return router.createUrlTree([`${paramsObj.route}`], {
        queryParams: {
          ...paramsObj,
          route: undefined,
        },
      });
    }
    allowAccess = true;
  } else {
    const loggerService = inject(LoggerService);
    const orderService = inject(OrderService);
    const cookieService = inject(CookieService);
    const location = windowService.getLocation();

    const canGetOrderDetails = paramsObj.orderId && paramsObj.accountId;
    let legacyQueryParams = '';
    let legacyCookie: LegacyCookie = {
      name: LegacyCookieName.CHANGE_ORDER_OR_COST_BASIS,
      value: '',
    };
    let urlToRedirect = environment.buySellRouterUrl;

    const isExtendedTrading = location.pathname.includes(ROUTES.EXTENDED_TRADING_PATH);
    const isCancel = location.pathname.includes(ROUTES.CANCEL_PATH);
    const isChangeOrEditCostBasis =
      location.pathname.includes(ROUTES.TRADE_PATH) || location.pathname.includes(ROUTES.EDIT_COST_BASIS_PATH);

    if (isCancel) {
      legacyCookie.name = LegacyCookieName.CANCEL_ORDER;
      legacyCookie.value = `accountID~${paramsObj.accountId}|orderKey~${paramsObj.orderId}`;
      legacyQueryParams = `&accountID=${paramsObj.accountId}`;
      urlToRedirect = environment.buySellRouterCancelUrl;
    } else if (isExtendedTrading) {
      legacyQueryParams = '&extendedHours=true';
    } else if (isChangeOrEditCostBasis) {
      legacyCookie.value = `accountID~${paramsObj.accountId}|orderKey~${paramsObj.orderId}|changeOrder~true`;
      legacyQueryParams = `&orderStatusNGA=true&accountID=${paramsObj.accountId}&changeOrder=true`;
    }

    if (canGetOrderDetails) {
      try {
        const response: Order.Order = await firstValueFrom(
          orderService.getOrderDetails(paramsObj.accountId, paramsObj.orderId)
        );

        if (response?.brokerageAccountNumber) {
          if (isCancel) {
            legacyCookie.value += `|brokerageAccountNumber~${response.brokerageAccountNumber}`;
          } else if (isChangeOrEditCostBasis) {
            legacyCookie.value += `|brokerageAccountNumber~${response.brokerageAccountNumber}|symbol~${response.ticker}`;
            legacyQueryParams += `&ticker=${response.ticker}`;
          }
        }
      } catch (error) {
        loggerService.error(UILogCode.ORDER_ERROR, `Order details error in serverless guard: ${error}`);
      }
    }

    if (legacyCookie.value) {
      cookieService.set(legacyCookie.name, legacyCookie.value, {
        path: '/',
        domain: '.vanguard.com',
        secure: true,
      });
    }

    windowService.navigateToExternalLink(`${urlToRedirect}${legacyQueryParams}`);
  }
  return allowAccess;
};
