<ng-container
  *ngIf="{
    costBasisMethodCodes: (costBasisMethodCodes$ | async),
    quote: (quote$ | async)
  } as view"
>
  <c11n-modal-dialog
    #modal
    modalId="costBasisModal"
    class="cost-basis-selection"
    [headingText]="content.costBasisSelection.costBasisSelectionContent.header"
    [primaryButtonLabel]="content.costBasisSelection.costBasisSelectionModal.buttonContinue"
    [secondaryButtonLabel]="content.costBasisSelection.costBasisSelectionModal.buttonCancel"
    (primaryClick)="continue()"
    (secondaryClick)="modal.closeDialogModal()"
  >
    <div c11n-modal-dialog-body>
      <tcx-cost-basis
        [costBasisForm]="costBasisFormGroup"
        [availableCostBasisMethods]="getCostBasisCodes(view.costBasisMethodCodes)"
        [ticker]="view.quote?.tickerSymbol"
        [isLotsAvailable]="allowSpecID"
        [hideSetPreferred]="true"
        [costBasisDialogContent]="getModalContent(content.costBasisSelection.costBasisSelectionModal)"
        [costBasisContent]="content.costBasisSelection.costBasisSelectionContent"
        (onMinTaxLearnMoreLinkClick)="openLinkInNewTab(content.costBasisSelection.linkMinTaxUrl)"
        (onAvailableCostBasisMethodsLinkClick)="openLinkInNewTab(content.costBasisSelection.link)"
      ></tcx-cost-basis>
      <c11n-checkbox
        *ngIf="!specIdIsSelected"
        class="text-start"
        [labelText]="content.costBasisSelection.costBasisSelectionModal.checkboxPreferredCostBasis"
        (stateChange)="defaultCostBasisCheckboxValue = $event.isChecked"
      >
        <input
          c11nCheckboxInput
          id="defaultCostBasisCheckbox"
          type="checkbox"
          name="defaultCostBasisCheckbox"
          [checked]="defaultCostBasisCheckboxValue"
        />
      </c11n-checkbox>
      <div *ngIf="specIdIsSelected" class="spacer"></div>
    </div>
  </c11n-modal-dialog>
</ng-container>
