import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UILogCode } from '@app/core/enums/ui-log-code-enum';
import { UILogError } from '@app/core/models/ui-log/ui-log.model';
import { LoggerService } from '@app/core/services';
import { SymbolRetrievalType } from '@app/etfs-equities/enums';
import { JsonContent } from '@app/etfs-equities/models';
import { SymbolRetrievalError } from '@app/etfs-equities/models/symbol-retrieval-error.model';
import {
  AccountService,
  CostBasisService,
  MarketDataService,
  OrderService,
  TradeTicketService,
} from '@app/etfs-equities/services';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { merge, skip, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'twe-generic-error-modal',
  templateUrl: './generic-error-modal.component.html',
  styleUrls: ['./generic-error-modal.component.scss'],
})
export class GenericErrorModalComponent implements OnInit, OnDestroy {
  @ViewChild('genericErrorModal')
  modal: ModalDialogComponent;

  @Input()
  isChangeOrder = false;

  @Output()
  hasAccountRetrievalErrorEmitter: EventEmitter<boolean> = new EventEmitter();

  //  Public variables...

  content: JsonContent = content;
  pageName = '';

  //  Public observables/subjects...

  unsubscribe$ = new Subject<void>();

  constructor(
    private readonly orderService: OrderService,
    private readonly accountService: AccountService,
    private readonly marketDataService: MarketDataService,
    private readonly tradeTicketService: TradeTicketService,
    private readonly costBasisService: CostBasisService,
    private logService: LoggerService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.watchForGenericErrors();
    this.watchForPageTitle();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setMarketDataIsLoading(value: boolean) {
    this.marketDataService.setIsLoading(value);
  }

  private watchForGenericErrors() {
    this.orderService.httpError$
      .pipe(
        tap((error) => {
          if (!this.orderService.hasSubmitTimeoutError) {
            this.modal.openModalDialog();
            this.logService.error(UILogCode.ORDER_SERVICE_ERROR, this.getLogMessage(error));
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.accountService.accountRetrievalError$
      .pipe(
        skip(1),
        tap((error) => {
          this.hasAccountRetrievalErrorEmitter.emit(true);
          if (!this.isChangeOrder) {
            this.modal.openModalDialog();
            this.logService.error(UILogCode.ACCOUNT_ERROR, this.getLogMessage(error));
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.marketDataService.symbolRetrievalError$
      .pipe(
        filter(
          ({ type }: SymbolRetrievalError) =>
            !this.isChangeOrder &&
            type !== SymbolRetrievalType.MUTUAL_FUND &&
            type !== SymbolRetrievalType.NON_VG_MUTUAL_FUND
        ),
        tap((error) => {
          this.modal.openModalDialog();
          this.logService.error(UILogCode.REAL_TIME_MARKET_DATA_ERROR, this.getLogMessage(error.logEntry));
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    merge(this.tradeTicketService.tradeCannotBeCompleted$, this.costBasisService.httpError$)
      .pipe(
        tap((error) => {
          this.modal.openModalDialog();
          this.logService.error(UILogCode.COST_BASIS_ERROR, this.getLogMessage(error));
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForPageTitle() {
    if (this.router && this.router.data) {
      this.router.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
        if (data.pageTitle) {
          this.pageName = data.pageTitle;
        }
      });
    }
  }

  private getLogMessage(errorLog: UILogError) {
    return `${this.pageName} page error from ${errorLog?.serviceName} service with error message: ${errorLog?.error.message}`;
  }
}
