import { Component, OnInit } from '@angular/core';
import content from '@content/content.json';
import { JsonContent } from '@etfs-equities/models';
import { selectAccountIsManaged, TayneState } from '@etfs-equities/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-managed-account-shingle',
  templateUrl: './managed-account-shingle.component.html',
  styleUrls: ['./managed-account-shingle.component.scss'],
})
export class ManagedAccountShingleComponent implements OnInit {
  //  Public variables...
  content: JsonContent = content;

  // Public observables/subjects...
  accountIsManaged$: Observable<boolean>;

  constructor(private readonly store: Store<TayneState>) {}

  ngOnInit() {
    this.accountIsManaged$ = this.store.select(selectAccountIsManaged);
  }
}
