<c11n-modal-dialog
  #modal
  modalId="timeout-error-modal"
  [headingText]="content.submitTimeoutModal.title"
  [primaryButtonLabel]="content.labels.ok"
  (primaryClick)="modal.closeDialogModal()"
  variant="warning"
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md">
      {{ content.submitTimeoutModal.body1 }}
      <a c11n-link [routerLink]="OPEN_ORDERS_PATH" variant="primary-reinforced" (click)="modal.closeDialogModal()">{{
        content.submitTimeoutModal.orderStatusText
      }}</a>
      {{ content.submitTimeoutModal.body2 }}
      {{ content.submitTimeoutModal.body3 }}
    </p>
  </div>
</c11n-modal-dialog>
