import { Component, Input, ViewChild } from '@angular/core';
import { TradeHelpIDs } from '@app/etfs-equities/enums';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { TabsComponent } from '@vg-constellation/angular-18/tabs';

@Component({
  selector: 'twe-trade-help-modal',
  templateUrl: './trade-help-modal.component.html',
  styleUrls: ['./trade-help-modal.component.scss'],
})
export class TradeHelpModalComponent {
  //  Decorators...
  @Input() hasAnimation = true;

  @ViewChild('modal')
  modal: ModalDialogComponent;

  @ViewChild('modalTabs')
  modalTabs: TabsComponent;

  //  Public variables...
  activeItem = TradeHelpIDs.TRADING_POLICIES;
  content: JsonContent = content;
  isBeacon: boolean;

  constructor(private readonly windowService: WindowService) {
    this.isBeacon = this.windowService.getIsBeacon();
  }

  open(activeItem?: TradeHelpIDs): void {
    if (activeItem) {
      this.modalTabs.changeActiveItem(activeItem);
    }

    this.modal.openModalDialog();
  }
}
