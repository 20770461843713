import { ActiveNavTabState, initialActiveNavTabState } from '@etfs-equities/store';
import {
  ActiveNavTabActionsUnion,
  ActiveNavTabActionTypes,
} from '@etfs-equities/store/actions/active-nav-tab/active-nav-tab.actions';

export function activeNavTabReducer(
  state: ActiveNavTabState = initialActiveNavTabState,
  action: ActiveNavTabActionsUnion
): ActiveNavTabState {
  if (action.type === ActiveNavTabActionTypes.SET_ACTIVE_NAV_TAB) {
    return { activeNavTab: action.payload };
  } else {
    return state;
  }
}
