import { HttpClient, HttpContext, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingContext } from '@app/core/interceptors/loading-interceptor';
import { UILogError } from '@app/core/models/ui-log/ui-log.model';
import { BusinessWorkStreamEnum } from '@app/etfs-equities/enums/order.enums';
import { Account, BrokerageNumberToAccountIdMap, OpenOrdersResponse } from '@app/etfs-equities/models';
import { GatekeeperFeatureIds } from '@core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@core/services';
import { selectBrokerageNumberToAccountIdMap, TayneState } from '@etfs-equities/store';
import { HeadersUtil } from '@etfs-equities/utils/header/header.util';
import { OpenOrderMappingUtil } from '@etfs-equities/utils/open-order-mapping/open-order-mapping.util';
import { Store } from '@ngrx/store';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import {
  AccountApi,
  AccountInvestmentPlans,
  OrdersApi,
  VgaOrdersResponseV2,
} from '@vanguard/invest-api-client-typescript-axios';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { combineLatest, from, Observable, Subject, take, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { DateAndTimeUtil } from '../../utils/date-and-time/date-and-time.util';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  //  Public observables/subjects...

  private openOrdersResponse$: Observable<OpenOrdersResponse>;

  accountRetrievalError$ = new Subject<UILogError>();

  accountRetrievalRetry$ = new Subject<void>();

  transactableAccountsRetrievalError$ = new Subject<void>();

  //  Public variables...

  isLoadingAccounts = false;

  isLoadingOpenOrders = false;

  isRefreshingAccounts = false;

  orderRetrievalFailed = false;

  hasCriticalHoldingError = false;

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly gatekeeperService: GatekeeperService,
    private readonly ordersApi: OrdersApi,
    private readonly accountApi: AccountApi,
    private readonly store: Store<TayneState>
  ) {}

  fetchAccounts(): Observable<Account.MidTierResponse> {
    this.isLoadingAccounts = true;

    return this.getAccountsRequest().pipe(
      tap(({ accounts }) => {
        if (!accounts.length) {
          this.transactableAccountsRetrievalError$.next();
          this.accountRetrievalError$.next({
            error: new HttpErrorResponse({
              error: new Error('Account retrieval failed.'),
              status: HttpStatusCode.NoContent,
              statusText: 'Not Found',
            }),
            serviceName: 'AccountService.fetchAccounts',
          });
        }
        this.isLoadingAccounts = false;
      })
    );
  }

  refreshAccounts(): Observable<Account.MidTierResponse> {
    this.isRefreshingAccounts = true;

    return this.getAccountsRequest(true).pipe(tap(() => (this.isRefreshingAccounts = false)));
  }

  // fetch open orders via TWE mid-tier
  fetchOpenOrdersV1(): Observable<OpenOrdersResponse> {
    const options = { withCredentials: true, context: new HttpContext().set(LoadingContext, { showLoading: true }) };
    return this.http
      .get<OpenOrdersResponse>(this.envService.getApiUrlBaseOnRoute() + 'api/open-orders', options)
      .pipe(shareReplay(1));
  }

  // fetch open orders via VGA/JT9
  fetchOpenOrdersV2(brokerageAccountNumber: string, costBasisEligible = false): Observable<OpenOrdersResponse> {
    // JT9 returns brokerage account number in lieu of account ID in the order response. We need to map brokerage account numbers to account
    // IDs for backwards compatibility with the rest of the application.
    const acctIdMap$: Observable<BrokerageNumberToAccountIdMap> = this.store.select(
      selectBrokerageNumberToAccountIdMap
    );
    return combineLatest([
      acctIdMap$,
      from(
        this.ordersApi.getOrdersV2([brokerageAccountNumber], false, costBasisEligible, HeadersUtil.showLoadingHeaders())
      ),
    ]).pipe(
      map(([acctIdMap, response]: [BrokerageNumberToAccountIdMap, AxiosResponse<VgaOrdersResponseV2[]>]) => {
        return {
          asOfDate: DateAndTimeUtil.asOfDate(response.headers.date),
          orders: OpenOrderMappingUtil.mapToTweOpenOrder(response.data, acctIdMap),
        };
      })
    );
  }

  fetchOpenOrdersForAccount(account: Account.Account, costBasisEligible = false): Observable<OpenOrdersResponse> {
    const isOpenOrdersV2Enabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_INTEGRATION_VGA_OPENORDERS_V2
    );

    this.isLoadingOpenOrders = true;
    return isOpenOrdersV2Enabled$.pipe(
      take(1),
      switchMap((isOpenOrdersV2Enabled) => {
        if (!this.openOrdersResponse$ || isOpenOrdersV2Enabled) {
          this.openOrdersResponse$ = isOpenOrdersV2Enabled
            ? this.fetchOpenOrdersV2(account.brokerageAccountNumber, costBasisEligible)
            : this.fetchOpenOrdersV1();
        }

        return this.openOrdersResponse$.pipe(
          map((response) => {
            this.isLoadingOpenOrders = false;
            this.orderRetrievalFailed = false;
            return {
              asOfDate: response.asOfDate,
              orders: response.orders.filter(
                (openOrder) =>
                  openOrder.accountId === +account.accountId &&
                  openOrder.businessOrigin?.businessWorkStream !== BusinessWorkStreamEnum.AUTO_INVEST_INTO_ETFS
              ),
            };
          }),
          catchError((error) => {
            this.orderRetrievalFailed = true;
            this.isLoadingOpenOrders = false;
            return throwError(() => error);
          })
        );
      })
    );
  }

  refreshOpenOrdersForAccount(account: Account.Account, costBasisEligible: boolean): Observable<OpenOrdersResponse> {
    this.resetOpenOrdersResponse();
    return this.fetchOpenOrdersForAccount(account, costBasisEligible);
  }

  // Break memoization upon client request.
  resetOpenOrdersResponse() {
    this.openOrdersResponse$ = null;
  }

  // Fetch automatic investment plans
  fetchAutomaticInvestmentPlans(accountIds: string, options?: AxiosRequestConfig): Observable<AccountInvestmentPlans> {
    return from(this.accountApi.getAutomaticInvestmentPlans(accountIds, options)).pipe(
      map((response: AxiosResponse<AccountInvestmentPlans>) => response.data),
      catchError((error) => throwError(() => error))
    );
  }

  private getAccountsRequest(showLoading: boolean = false) {
    const options = {
      withCredentials: true,
      context: new HttpContext().set(LoadingContext, { showLoading }),
    };
    return this.http
      .get<Account.MidTierResponse>(this.envService.getApiUrlBaseOnRoute() + 'api/accounts', options)
      .pipe(
        tap(
          (response: Account.MidTierResponse) =>
            (this.hasCriticalHoldingError = this.responseHasCriticalHoldingsError(response))
        ),
        catchError((error) => {
          this.accountRetrievalError$.next({ error, serviceName: 'AccountService.getAccountsRequest' });
          this.hasCriticalHoldingError = true;
          this.isLoadingAccounts = false;
          this.isRefreshingAccounts = false;
          return throwError(() => error);
        })
      );
  }

  private responseHasCriticalHoldingsError(response: Account.MidTierResponse) {
    return response.errors?.find((e) => e.code === 'ALS-10015') ? true : false;
  }
}
