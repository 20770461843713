import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MASKS } from '@app/etfs-equities/constants/masks';
import content from '@content/content.json';
import { JsonContent } from '@etfs-equities/models';
import { TradeTicketService } from '@etfs-equities/services';
import { InputMask } from '@vg-constellation/angular-18/input';

@Component({
  selector: 'twe-price-control',
  templateUrl: './price-control.component.html',
  styleUrls: ['./price-control.component.scss'],
})
export class PriceControlComponent {
  @Input() controlName: string;
  @Input() controlLabel: string;
  @Input() hasError: boolean;
  @Input() requiredWhenInError: boolean;
  @Input() parentGroup?: FormGroup;

  //  Public variables...
  content: JsonContent | undefined = content;
  twoDecimalsCurrency = InputMask.CURRENCY;
  fourDecimalsCurrency = MASKS.FOUR_DECIMALS_CURRENCY;

  constructor(public tradeTicketService: TradeTicketService) {}

  getErrorText() {
    if (
      this.tradeTicketService?.controlHasError(this.controlName, 'min') ||
      this.tradeTicketService?.controlHasError(this.controlName, 'max')
    ) {
      return this.content?.validation.priceMinMax;
    }
    if (this.requiredWhenInError && this.hasError) {
      return this.content?.validation[this.controlName];
    }
    if (this.tradeTicketService?.controlHasError(this.controlName, 'decimals')) {
      return this.content?.validation.priceDecimals;
    }
    return '';
  }

  emitBlur(event) {
    if (event.target) {
      event.target.blur();
    }
  }

  get valueIsLessThanOne() {
    return this.parentGroup?.controls[this.controlName]?.value < 1;
  }
}
