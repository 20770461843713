<c11n-modal-dialog #modal [headingText]="content.orderTypeModal.orderTypes" [maxWidth]="1024" (closeEvent)="onClose()">
  <div c1nn-modal c11n-modal-dialog-body>
    <c11n-tabs [activeItem]="'order-types-modal-' + activeTab" (activeItemChange)="updateTab($event)">
      <c11n-tab-panel id="order-types-modal-buy" [labelText]="content.labels.buy">
        <div class="scrollable-tab">
          <ng-container *ngTemplateOutlet="orderTypeModalTemplateModal"></ng-container>
        </div>
      </c11n-tab-panel>
      <c11n-tab-panel id="order-types-modal-sell" [labelText]="content.labels.sell">
        <div class="scrollable-tab">
          <ng-container *ngTemplateOutlet="orderTypeModalTemplateModal"></ng-container>
        </div>
      </c11n-tab-panel>
    </c11n-tabs>

    <ng-template #orderTypeModalTemplateModal>
      <p
        class="c11n-text-md c11n-space-stack-3x c11n-space--force"
        [innerHtml]="content.orderTypeModal.orderTypesBodyTop"
      ></p>

      <div class="row">
        <div class="col-md-7 col-xs-12">
          <h2 class="c11n-text-lg-bold c11n-space-stack-2x">{{ content.orderTypeModal.marketOrder }}</h2>
          <p class="c11n-text-md-bold">{{ content.orderTypeModal.marketOrderDefinition }}</p>
          <p class="c11n-text-md">{{ content.orderTypeModal.marketOrderDescription }}</p>
        </div>

        <div class="col-md-5 col-xs-12">
          <img
            src="{{ windowService.assetsPath }}assets/svg/orderTypesModal/market.svg"
            class="img-fluid"
            alt="{{ content.orderTypeModal.marketOrderChart }}"
            loading="lazy"
          />
        </div>

        <hr noshade class="mt-4 c11n-space-stack-3x" />
      </div>

      <div class="row">
        <div class="col-md-7 col-xs-12">
          <h2 class="c11n-text-lg-bold c11n-space-stack-2x">{{ content.orderTypeModal.limitOrder }}</h2>
          <p class="c11n-text-md-bold">{{ content.orderTypeModal.limitOrderDefinition }}</p>
          <p class="c11n-text-md c11n-space-stack-0_5x">
            <strong
              >{{ activeTab !== 'sell' ? content.orderTypeModal.buyExample : content.orderTypeModal.sellExample }}:
            </strong>
            {{
              activeTab !== 'sell'
                ? content.orderTypeModal.limitOrderBuyDescription
                : content.orderTypeModal.limitOrderSellDescription
            }}
          </p>
        </div>

        <div class="col-md-5 col-xs-12 align-self-center">
          <img
            src="{{ windowService.assetsPath }}assets/svg/orderTypesModal/{{
              activeTab !== 'sell' ? 'buy-limit' : 'sell-limit'
            }}.svg"
            class="img-fluid"
            alt="{{ content.orderTypeModal.limitOrderChart }}"
            loading="lazy"
          />
        </div>

        <hr noshade class="mt-4 c11n-space-stack-3x" />
      </div>

      <div class="container-fluid">
        <c11n-accordion [labelText]="content.orderTypeModal.advancedOrderTypesLink">
          <div accordion-body>
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex">
                  <img
                    src="{{ windowService.assetsPath }}assets/svg/info-filled.svg"
                    class="img-fluid c11n-space-inline-right-2x align-self-start mt-1"
                    alt="information"
                    loading="lazy"
                  />
                  <p
                    class="c11n-text-md c11n-space-stack-4x c11n-space--force"
                    [innerHtml]="content.orderTypeModal.expandedDescription"
                  ></p>
                </div>
              </div>
            </div>
            <div class="row c11n-space-stack-3x">
              <div class="col-md-7 col-xs-12">
                <h2 class="c11n-text-lg-bold c11n-space-stack-2x">{{ content.orderTypeModal.stopOrder }}</h2>
                <p class="c11n-text-md-bold">{{ content.orderTypeModal.stopOrderDefinition }}</p>

                <p class="c11n-text-md">
                  <strong>
                    {{ activeTab !== 'sell' ? content.orderTypeModal.buyExample : content.orderTypeModal.sellExample }}:
                  </strong>

                  {{
                    activeTab !== 'sell'
                      ? content.orderTypeModal.stopOrderBuyExample
                      : content.orderTypeModal.stopOrderSellExample
                  }}
                </p>
              </div>

              <div class="col-md-5 col-xs-12">
                <img
                  src="{{ windowService.assetsPath }}assets/svg/orderTypesModal/{{
                    activeTab !== 'sell' ? 'buy-stop' : 'sell-stop'
                  }}.svg"
                  class="img-fluid"
                  alt="{{ content.orderTypeModal.stopOrderChart }}"
                  loading="lazy"
                />
              </div>

              <div class="col-md-12">
                <hr noshade />
              </div>
            </div>

            <div class="row">
              <div class="col-md-7 col-xs-12">
                <h2 class="c11n-text-lg-bold c11n-space-stack-2x">{{ content.orderTypeModal.stopLimitOrder }}</h2>
                <p class="c11n-text-md-bold">{{ content.orderTypeModal.stopLimitOrderDefinition }}</p>
                <p>{{ content.orderTypeModal.stopLimitOrderDescription }}</p>

                <p class="c11n-text-md">
                  <strong>
                    {{
                      activeTab !== 'sell' ? content.orderTypeModal.buyExample : content.orderTypeModal.sellExample
                    }}:</strong
                  >

                  {{
                    activeTab !== 'sell'
                      ? content.orderTypeModal.stopLimitOrderBuyExample
                      : content.orderTypeModal.stopLimitOrderSellExample
                  }}
                </p>
              </div>

              <div class="col-md-5 col-xs-12">
                <img
                  src="{{ windowService.assetsPath }}assets/svg/orderTypesModal/{{
                    activeTab !== 'sell' ? 'buy-stop-limit' : 'sell-stop-limit'
                  }}.svg"
                  class="img-fluid"
                  alt="{{ content.orderTypeModal.stopLimitOrderChart }}"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </c11n-accordion>
      </div>
    </ng-template>
  </div>
</c11n-modal-dialog>
