import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, HostListener, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { APP_PREFIX } from '@env/environment.constants';
import { Account, JsonContent, Order, RouteData } from '@etfs-equities/models';
import { selectActiveNavTab } from '@etfs-equities/store/selectors/active-nav-tab/active-nav-tab.selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

import { GatekeeperFeatureIds } from './core/enums/gatekeeper-features.enum';
import { GatekeeperService, MetaDataService, supportedFeatureIds } from './core/services';
import { TradeHelpModalComponent } from './etfs-equities/components';
import { VGNNavVariants } from './etfs-equities/enums/vgn-nav-variants.enum';
import { UserService, WindowService } from './etfs-equities/services';
import { AnalyticsService } from './etfs-equities/services/analytics/analytics.service';
import { SubmitService } from './etfs-equities/services/submit/submit.service';
import {
  createLoadEnvironmentAction,
  createSetIncapacitatedAction,
  createUpdateTradeTicketTabLinkAction,
  ScreenSize,
  selectAccountIsMargin,
  selectAccountIsTradeable,
  selectIsChangeOrderState,
  selectOrder,
  selectSelectedAccount,
  TayneState,
} from './etfs-equities/store';
import * as ScreenSizeActions from './etfs-equities/store';
import { selectEnvironment } from './etfs-equities/store/selectors/environment/environment.selectors';
import { LoadingService } from './shared/services/loading/loading.service';

@Component({
  selector: 'twe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  //  Decorators...

  @ViewChild('tradeHelpModal')
  tradeHelpModal: TradeHelpModalComponent;

  //  Public variables...

  content: JsonContent = content;

  currentRoute = '/';

  isBeacon = false;

  isIncapacitated = false;

  showVgnHeaderAndFooter = false;

  showNav: boolean;

  showContactUsTradeHelp: boolean;

  showExitOnly: boolean;

  isCriticalError: boolean;

  pageTitle = '';

  medalliaUrl: string;

  secureSiteUrls = environment.secureSiteUrls;

  prospectusAuditUrl: string;

  appPrefix = APP_PREFIX;

  vgnNavVariants = VGNNavVariants;

  // Public observable/subjects...

  env$: Observable<any>;

  order$: Observable<Order.Order>;

  isChangeOrder$: Observable<boolean>;

  accountIsMargin$: Observable<boolean>;

  isOneTrustEnabled$: Observable<boolean>;

  selectedAccount$: Observable<Account.Account>;

  accountIsTradeable$: Observable<boolean>;

  activeNavTab$: Observable<string>;

  //  Private variables...

  private readonly gatekeeperFeatureStatusInterval$ = interval(CONSTANTS.GATEKEEPER_REFRESH_FEATURE_STATUS_INTERVAL);
  private readonly unsubscribe$ = new Subject<void>();
  private readonly metaDataService = inject(MetaDataService); // do not remove this

  // eslint-disable-next-line max-params
  constructor(
    private readonly userService: UserService,
    public readonly windowService: WindowService,
    private readonly store: Store<TayneState>,
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly cdr: ChangeDetectorRef,
    public readonly analyticsService: AnalyticsService,
    private readonly breakpointObserver: BreakpointObserver,
    public readonly loadingService: LoadingService,
    private readonly submitService: SubmitService,
    private readonly gatekeeperService: GatekeeperService
  ) {
    this.medalliaUrl = analyticsService.medalliaService.medalliaUrl;
    this.prospectusAuditUrl = environment.prospectus.audit;
    this.isBeacon = windowService.getIsBeacon();
  }

  // Getters/Setters...

  get tweNavIsVisible() {
    return !this.showVgnHeaderAndFooter && this.showNav;
  }

  get isCrewDisclaimerVisible() {
    return this.userService.crewDisclaimerService.showCrewDisclaimer;
  }

  get isConfirmationPage() {
    return this.currentRoute === CONSTANTS.CONFIRMATION_PATH;
  }

  @HostListener('document:visibilitychange', ['$event.target']) handleVisibilityChange(document) {
    if (document.hidden) {
      return;
    }
    this.userService.sessionCheckService.checkSession().pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  ngOnInit() {
    this.store.dispatch(createLoadEnvironmentAction());
    this.env$ = this.store.pipe(select(selectEnvironment));
    this.isIncapacitated = this.getIsIncapacitated();
    this.store.dispatch(createSetIncapacitatedAction(this.isIncapacitated));
    this.order$ = this.store.pipe(select(selectOrder));
    this.isChangeOrder$ = this.store.pipe(select(selectIsChangeOrderState));
    this.accountIsMargin$ = this.store.pipe(select(selectAccountIsMargin));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.accountIsTradeable$ = this.store.pipe(select(selectAccountIsTradeable));
    this.activeNavTab$ = this.store.pipe(select(selectActiveNavTab));

    this.isOneTrustEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_ONETRUST);

    this.fetchSpoid();
    this.watchForMedalliaRouteUpdate();
    this.watchForScreenSizeChanges();
    this.handleLongerThanExpectedLoadingTime();
    this.watchForGatekeeperMultiFeatureStatus();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((e: NavigationEnd) => {
          this.currentRoute = e.urlAfterRedirects;
          this.cdr.detectChanges();
          this.updateTabUrls();
        }),
        map(() => this.router.routerState.snapshot.root),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((route) => {
        const routeData = route.data as RouteData;
        this.toggleNav(routeData);
        this.titleService.setTitle(routeData.pageTitle);
        this.pageTitle = routeData.pageHeading || routeData.pageTitle;
      });

    combineLatest([this.selectedAccount$, this.accountIsTradeable$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([account, isTradeable]) => {
        this.showExitOnly = !!account && !isTradeable;
      });
  }

  handleLongerThanExpectedLoadingTime() {
    this.submitService.submitOrder$
      .pipe(debounceTime(CONSTANTS.ADDITIONAL_LOADING_MESSAGE_DELAY), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.loadingService.isSubmittingOrder()) {
          this.loadingService.setSpinnerStatus('longerLoadingTime');
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Toggle between the VGN header/footer full version or no nav based on the current route.
  // The VGN no nav variant 1 is visible on all routes except for routes with
  // showVgnHeaderAndFooter set to true, where we switch over full nav.
  toggleNav(routeData: RouteData) {
    this.showVgnHeaderAndFooter = routeData.showVgnHeaderAndFooter;
    this.showNav = !routeData.hideNav;
    this.showContactUsTradeHelp = !routeData.hideContactUsTradeHelp;
  }

  // Save the most recent "Trade ETFs or Stocks" or "Extended Trading" tab link to the store
  // so that we know where to send the client when they click that link.
  updateTabUrls() {
    if ([CONSTANTS.TRADE_PATH, CONSTANTS.EDIT_COST_BASIS_PATH].some((path) => this.currentRoute.includes(path))) {
      const urlTree = this.router.parseUrl(this.currentRoute);
      this.store.dispatch(createUpdateTradeTicketTabLinkAction(this.currentRoute, urlTree.queryParams));
    } else if (this.currentRoute.includes(CONSTANTS.EXTENDED_TRADING_PATH)) {
      const urlTree = this.router.parseUrl(this.currentRoute);
      this.store.dispatch(
        ScreenSizeActions.createUpdateExtendedTradingTabLinkAction(this.currentRoute, urlTree.queryParams)
      );
    }
  }

  getIsIncapacitated() {
    let isIncapacitated = false;
    const incapacitatedTag: Element = document.querySelector('meta[name="isIncapacitated"]');
    if (incapacitatedTag) {
      isIncapacitated = incapacitatedTag.getAttribute('content') === 'true';
    }
    return isIncapacitated;
  }

  /**
   * Triggers OneTrust to open modal
   */
  triggerCookieSetting(): void {
    if (this.windowService.$window.OneTrust) {
      this.windowService.$window.OneTrust.ToggleInfoDisplay();
    }
  }

  /**
   * Fetches the Spoid on load of the authenticated app to be passed along to Medallia.
   */
  private fetchSpoid(): void {
    this.windowService.$window.spoid = this.userService.getSpoid();
  }

  /**
   * Watch for medallia router updates.
   *
   * @remarks
   * This method is calling onInit to subscribe on Router events and check
   * if page was updated with single page. If page was loaded with single
   * page then we need to call updatePageView for Medallia survey.
   *
   */
  private watchForMedalliaRouteUpdate(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd && event.id > 1) {
        this.analyticsService.medalliaService.updateRoute();
      }
    });
  }

  private watchForScreenSizeChanges() {
    const bodyStyle = window.getComputedStyle(document.body);

    // use the custom breakpoint properties in styles.css to determine the bootstrap breakpoints
    const breakpoints: { size: ScreenSize; style: string; width: string }[] = [
      { size: ScreenSize.XS, style: '--breakpoint-xs', width: null },
      { size: ScreenSize.SM, style: '--breakpoint-sm', width: null },
      { size: ScreenSize.MD, style: '--breakpoint-md', width: null },
      { size: ScreenSize.LG, style: '--breakpoint-lg', width: null },
      { size: ScreenSize.XL, style: '--breakpoint-xl', width: null },
      { size: ScreenSize.XXL, style: '--breakpoint-xxl', width: null },
    ];
    // fill in the min-widths for each breakpoint
    breakpoints.forEach((bp) => {
      bp.width = `(min-width: ${bodyStyle.getPropertyValue(bp.style)})`;
    });

    // Extract the breakpoint widths into an array that we can pass to the breakpointObserver. The breakpointObserver will
    // emit each time the screen size changes. When it does, we iterate through the breakpoints and determine which one
    // is active, then dispatch an action to the store with the new ScreenSize.
    const breakpointWidths = breakpoints.map((bp) => bp.width);
    let size: ScreenSize;
    this.breakpointObserver.observe(breakpointWidths).subscribe((breakPointState) => {
      breakpoints.forEach((bp) => {
        if (breakPointState.breakpoints[bp.width]) {
          size = bp.size;
        }
      });
      this.store.dispatch(ScreenSizeActions.createSetScreenSizeAction(size));
    });
  }

  private watchForGatekeeperMultiFeatureStatus() {
    this.gatekeeperFeatureStatusInterval$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.gatekeeperService.checkMultiFeatureStatus(supportedFeatureIds, true).subscribe());
  }
}
