import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { TweLookupTypes } from '@app/etfs-equities/enums';
import { Quote, TradeTicketForm } from '@app/etfs-equities/models';
import { AccountService, MarketDataService, OrderService } from '@app/etfs-equities/services';
import { OrderUtil } from '@app/etfs-equities/utils';
import { AcceptedRulesNextStep } from '@etfs-equities/enums/triggered-rule.enums';
import { SubmitService } from '@etfs-equities/services/submit/submit.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-18';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import {
  AcceptTriggeredRulesAction,
  createClearLoadLotsErrorAction,
  createLoadChangeOrderErrorAction,
  createLoadChangeOrderSuccessAction,
  createLoadLotsErrorAction,
  createLoadOrderStatusErrorAction,
  createLoadOrderStatusSuccessAction,
  createSetQuoteAction,
  createSubmitOrderErrorAction,
  createSubmitOrderSuccessAction,
  createValidateOrderErrorAction,
  createValidateOrderSuccessAction,
  LoadChangeOrderAction,
  LoadOrderStatusAction,
  OrderActionTypes,
  SubmitOrderSuccessAction,
  ValidateOrderAction,
  ValidateOrderSuccessAction,
} from '../../actions';
import {
  selectAccountIsMargin,
  selectCashAccountHoldingsForCurrentQuote,
  selectCostBasisEligible,
  selectOrder,
  selectOrderEveningDuration,
  selectPositionForCurrentQuoteBySecurityType,
  selectPrincipal,
  selectQuote,
  selectQuoteIsEtf,
  selectSelectedAccount,
  selectSetCostBasisMethodForFutureTrades,
  selectTradeTicket,
  selectTradeTicketTabLink,
} from '../../selectors';
import { selectIsChangeOrderState } from '../../selectors/change-order/change-order.selectors';
import { TayneState } from '../../states';

@Injectable()
export class OrderEffects {
  validateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActionTypes.VALIDATE_ORDER),

      // Fetch the quote and trade ticket from the store.
      withLatestFrom(
        this.store.pipe(select(selectQuote)),
        this.store.pipe(select(selectQuoteIsEtf)),
        this.store.pipe(select(selectTradeTicket)),
        this.store.pipe(select(selectAccountIsMargin)),
        this.store.pipe(select(selectCostBasisEligible))
      ),

      // Create an order object from the quote and trade ticket.
      map(
        ([action, quote, isEtf, tradeTicket, isMarginAccount, isCostBasisEligible]: [
          ValidateOrderAction,
          Quote,
          boolean,
          TradeTicketForm,
          boolean,
          boolean
        ]) => {
          return {
            order: OrderUtil.makeFromTradeTicket(
              {
                ...tradeTicket,
                cusip: quote.romCusip,
                validation: true,
                validated: false,
                submitted: false,
                isMarginAccount,
                isCostBasisEligible,
              },
              action.selectedLots,
              isEtf
            ),
            isChangeOrder: action.isChangeOrder,
          };
        }
      ),

      withLatestFrom(this.store.pipe(select(selectCashAccountHoldingsForCurrentQuote))),

      exhaustMap(([orderParams, holdings]) => {
        // We need holdings data in order to load lots for Spec ID sells. If holdings
        // aren't available due to a critical API error and this is a Spec ID sell,
        // do not attempt validation. Instead, open the lot error modal.

        if (
          orderParams.order.costBasisMethod === CostBasisMethod.SPEC_ID &&
          this.accountService.hasCriticalHoldingError &&
          !holdings.length
        ) {
          return of(createLoadLotsErrorAction(new HttpErrorResponse({})));
        }

        // Send the validation request to the midtier.
        return this.orderService.validate(orderParams.order, orderParams.isChangeOrder).pipe(
          tap((order) => {
            // Silently accept any review release rules that maybe have been triggered.
            const triggeredRules = OrderUtil.keyTriggeredRulesByType(order);

            if (triggeredRules.reviewReleases.length) {
              order.acceptedWarningRules = triggeredRules.reviewReleases.map((tr) => ({ ruleId: tr.ruleId }));
            }
          }),
          map((order) => createValidateOrderSuccessAction(order)),
          catchError((error: HttpErrorResponse) => of(createValidateOrderErrorAction(error)))
        );
      })
    )
  );

  validateOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderActionTypes.VALIDATE_ORDER_SUCCESS),
        withLatestFrom(this.store.select(selectQuote)),
        tap(([action, quote]: [ValidateOrderSuccessAction, Quote]) => {
          // Grab any unaccepted triggered rules.
          const unacceptedTriggeredRules = OrderUtil.getUnacceptedTriggeredRules(action.payload);

          // If there are triggered rules, inform subscribers and return early.
          if (unacceptedTriggeredRules.length > 0) {
            this.orderService.triggeredRules$.next(action.payload);
            return;
          }
          this.refreshQuoteAndContinueToPreview(quote.cusip);
          this.store.dispatch(createClearLoadLotsErrorAction(null));
        })
      ),
    { dispatch: false }
  );

  submitOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActionTypes.SUBMIT_ORDER),

      // Fetch the order from the store.
      withLatestFrom(
        this.store.pipe(select(selectOrder)),
        this.store.pipe(select(selectPrincipal)),
        this.store.pipe(select(selectIsChangeOrderState)),
        this.store.pipe(select(selectAccountIsMargin)),
        this.store.pipe(select(selectSelectedAccount)),
        this.store.pipe(select(selectPositionForCurrentQuoteBySecurityType)),
        this.store.pipe(select(selectSetCostBasisMethodForFutureTrades)),
        this.store.pipe(select(selectCostBasisEligible))
      ),

      // Prep the order for submission to the midtier.
      map(
        ([
          _action,
          order,
          estimatedAmount,
          isChangeOrder,
          isMarginAccount,
          selectedAccount,
          holding,
          updateDefaultCostBasis,
          isCostBasisEligible,
        ]) => ({
          order: {
            ...order,
            accountId: String(selectedAccount.accountId),
            validated: false,
            validation: false,
            submitted: false,
            estimatedAmount,
            isMarginAccount,
            isCostBasisEligible,
            costBasisDetailsToChangeCbaMethod: updateDefaultCostBasis
              ? {
                  accountId: selectedAccount.accountId,
                  holdingId: holding.positionId,
                  cbaMethodCode: order.costBasisMethod,
                }
              : null,
          },
          isChangeOrder,
        })
      ),

      // Submit the order to the midtier.
      exhaustMap(({ order, isChangeOrder }) =>
        this.orderService.submit(order, isChangeOrder).pipe(
          map((orderItem) => createSubmitOrderSuccessAction(orderItem)),
          catchError((error: HttpErrorResponse) => of(createSubmitOrderErrorAction(error)))
        )
      )
    )
  );

  submitOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderActionTypes.SUBMIT_ORDER_SUCCESS),
        tap((action: SubmitOrderSuccessAction) => {
          const order = action.payload;

          // always redirect to confirmation page if the order was submitted; otherwise inform listeners
          // if any unaccepted rules were returned
          if (order.submitted) {
            this.router.navigateByUrl('trade/ticket/confirmation');
          } else {
            const unacceptedRules = OrderUtil.getUnacceptedTriggeredRules(order);
            if (unacceptedRules.length > 0) {
              this.orderService.triggeredRules$.next(order);
            }
          }
        })
      ),
    { dispatch: false }
  );

  acceptTriggeredRules$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderActionTypes.ACCEPT_TRIGGERED_RULES),
        withLatestFrom(this.store.select(selectQuote)),
        tap(([action, quote]: [AcceptTriggeredRulesAction, Quote]) => {
          switch (action.acceptedRulesNextStep) {
            case AcceptedRulesNextStep.PREVIEW:
              this.refreshQuoteAndContinueToPreview(quote.cusip);
              this.store.dispatch(createClearLoadLotsErrorAction(null));
              break;
            case AcceptedRulesNextStep.SUBMIT:
              this.submitService.submitOrder$.next();
              break;
          }
        })
      ),
    { dispatch: false }
  );

  loadChangeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActionTypes.LOAD_CHANGE_ORDER),
      switchMap((action: LoadChangeOrderAction) => {
        return this.orderService.loadOrderDetails(action.accountId, action.orderId).pipe(
          map((order) => createLoadChangeOrderSuccessAction(order)),
          catchError((error: HttpErrorResponse) => of(createLoadChangeOrderErrorAction(error)))
        );
      })
    )
  );

  loadOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActionTypes.LOAD_ORDER_STATUS),
      switchMap((action: LoadOrderStatusAction) => {
        return this.orderService.loadOrderDetails(action.accountId, action.orderId, false).pipe(
          map((order) => createLoadOrderStatusSuccessAction(order)),
          catchError((error: HttpErrorResponse) => of(createLoadOrderStatusErrorAction(error)))
        );
      })
    )
  );

  constructor(
    private readonly marketDataService: MarketDataService,
    private readonly accountService: AccountService,
    private readonly orderService: OrderService,
    private readonly submitService: SubmitService,
    private readonly store: Store<TayneState>,
    private readonly actions$: Actions,
    private readonly router: Router
  ) {}

  refreshQuoteAndContinueToPreview(cusip: string) {
    this.marketDataService
      .fetchQuote(cusip, TweLookupTypes.CUSIP)
      .pipe(
        take(1),
        withLatestFrom(this.store.pipe(select(selectTradeTicketTabLink))),
        withLatestFrom(this.store.pipe(select(selectOrderEveningDuration)))
      )
      .subscribe({
        next: ([[quote, tabLink], isEveningOrder]) => {
          this.store.dispatch(createSetQuoteAction(quote));
          this.router.navigate(
            [isEveningOrder ? CONSTANTS.EXTENDED_TRADING_PREVIEW_PAGE_PATH : CONSTANTS.PREVIEW_PAGE_PATH],
            {
              queryParams: tabLink.queryParams,
            }
          );
        },
        error: () => {
          this.marketDataService.symbolRetrievalError$.next(null);
        },
      });
  }
}
