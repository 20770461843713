import { OrderUtil } from '@app/etfs-equities/utils';

import { OrderActionTypes, OrderActionTypesUnion } from '../../actions';
import { initialOrderState, OrderState } from '../../states';

export const orderReducer = (state: OrderState = initialOrderState, action: OrderActionTypesUnion): OrderState => {
  const orderLoadError = false;
  switch (action.type) {
    case OrderActionTypes.VALIDATE_ORDER_SUCCESS:
    case OrderActionTypes.SUBMIT_ORDER_SUCCESS:
      return { ...state, order: action.payload, orderLoadError };
    case OrderActionTypes.CLEAR_ORDER:
      return { ...state, order: null, origOrder: null, orderLoadError };
    case OrderActionTypes.LOAD_CHANGE_ORDER_SUCCESS:
      return { ...state, order: action.payload, origOrder: action.payload, orderLoadError };
    case OrderActionTypes.LOAD_CHANGE_ORDER_ERROR:
      return { ...state, order: null, origOrder: null, orderLoadError: true };
    case OrderActionTypes.LOAD_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          parentOrderStatus: action.payload.parentOrderStatus,
          vgaOrderStatus: action.payload.vgaOrderStatus,
          orderStatusTweText: OrderUtil.getOrderStatusDisplayText(action.payload.vgaOrderStatus),
          asOfDate: action.payload.asOfDate,
        },
        origOrder: action.payload,
        orderLoadError,
      };
    case OrderActionTypes.LOAD_ORDER_STATUS_ERROR:
      return { ...state, order: null, orderLoadError: true };

    case OrderActionTypes.ACCEPT_TRIGGERED_RULES:
      return {
        ...state,
        order: {
          ...state.order,
          acceptedWarningRules: action.payload
            .map((tr) => ({ ruleId: tr.ruleId }))
            .concat(state.order.acceptedWarningRules || []),
        },
        orderLoadError,
      };

    case OrderActionTypes.UPDATE_SELECTED_LOTS:
      return {
        ...state,
        order: {
          ...state.order,
          costBasisLots: action.payload,
        },
        orderLoadError,
      };

    default:
      return state;
  }
};
