import 'moment-timezone';

import { EcnHours } from '@etfs-equities/models';
import moment from 'moment';

export class DateAndTimeUtil {
  // returns true if "now" is within the provided EcnHours interval.
  static isWithinSpecifiedHours = (ecnHours: EcnHours) => {
    return DateAndTimeUtil.timeRemaining(ecnHours) >= 0;
  };

  // returns the number of milliseconds between "now" and the end of the ecnHours interval if "now" falls within the
  // interval, and returns -1 otherwise.
  static timeRemaining = (ecnHours: EcnHours) => {
    let msecs = -1;
    if (ecnHours?.openTime && ecnHours.closeTime) {
      const start = moment(ecnHours.openTime).valueOf();
      const end = moment(ecnHours.closeTime).valueOf();
      const now = moment().valueOf() + ecnHours.clockOffset;
      if (now >= start && now <= end) {
        msecs = end - now;
      }
    }
    return msecs;
  };

  // Create an ISO date string in the Eastern time zone given a timestamp representing the number of milliseconds since Epoch.
  // If no number is provided, use the current date/time.
  static asOfDate = (timestamp?: number) => {
    const now = timestamp ? moment(timestamp) : moment();
    return now.tz('America/New_York').toISOString(true);
  };
}
