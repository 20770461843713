export enum AccountType {
  CASH = 'CASH',
  MARGIN = 'MARGIN',
  SHORT = 'SHORT',
}

export enum AccountTypeCode {
  CASH = '1',
  MARGIN = '2',
  SHORT = '3',
}
