import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CostBasisControlComponent } from '@app/etfs-equities/components';
import { OrderEnums } from '@app/etfs-equities/enums';
import { AcceptedRulesNextStep } from '@app/etfs-equities/enums/triggered-rule.enums';
import { CostBasisService, TradeTicketService } from '@app/etfs-equities/services';
import {
  createLoadExtendedTradeAgreementAction,
  createLoadLotsAction,
  createValidateOrderAction,
  selectAccountIsMargin,
  selectAccountIsTradeable,
  selectAction,
  selectCostBasisTradeMethodsError,
  selectExtendedTradeAgreement,
  selectPositionForCurrentQuoteBySecurityType,
  selectQuote,
  selectSelectedAccount,
  selectShowNoBuyOrdersBannerForLowTierOtc,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { ExtendedHoursExpiredModalComponent } from '@etfs-equities/components/extended-hours-expired-modal/extended-hours-expired-modal.component';
import { JsonContent, Quote } from '@etfs-equities/models';
import { ExtendedHoursService } from '@etfs-equities/services/extended-hours/extended-hours.service';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'twe-extended-trading',
  templateUrl: './extended-trading.component.html',
  styleUrls: ['./extended-trading.component.scss'],
})
export class ExtendedTradingComponent implements OnInit, AfterViewInit, OnDestroy {
  // Decorators...
  @ViewChild(ExtendedHoursExpiredModalComponent)
  extendedHoursExpiredModal: ExtendedHoursExpiredModalComponent | undefined;

  @ViewChild('costBasisControl')
  costBasisControl: CostBasisControlComponent;

  //  Public variables...
  content: JsonContent = content;
  acceptedRulesNextStep = AcceptedRulesNextStep;
  minutesRemaining: string = null;
  eveningDurationControlFlow = false;

  //  Public observables/subjects...
  extendedHoursAgreement$: Observable<boolean>;
  selectShowNoBuyOrdersBannerForLowTierOtc$: Observable<boolean>;
  accountIsMargin$: Observable<boolean>;
  quote$: Observable<Quote>;
  selectedAction$: Observable<OrderEnums.TransactionTypes>;
  showWarningSubscription$: Subscription;
  showExpirationSubscription$: Subscription;
  accountIsTradeable$: Observable<boolean>;

  //  Private variables...
  private unsubscribe$ = new Subject<void>();

  constructor(
    public tradeTicketService: TradeTicketService,
    public extendedHoursService: ExtendedHoursService,
    public costBasisService: CostBasisService,
    private readonly store: Store
  ) {}

  get formGroupName() {
    return this.tradeTicketService.tradeTicket;
  }

  ngOnInit(): void {
    this.loadExtendedHoursAgreement();

    this.extendedHoursAgreement$ = this.store.select(selectExtendedTradeAgreement);
    this.selectShowNoBuyOrdersBannerForLowTierOtc$ = this.store.select(selectShowNoBuyOrdersBannerForLowTierOtc);
    this.accountIsMargin$ = this.store.select(selectAccountIsMargin);
    this.accountIsTradeable$ = this.store.select(selectAccountIsTradeable);
    this.quote$ = this.store.select(selectQuote);
    this.selectedAction$ = this.store.select(selectAction);

    this.tradeTicketService.setExtendedHoursValidators();
    this.updateSymbolValidityOnQuoteChange();
    this.watchForActionChanges();
  }

  ngAfterViewInit(): void {
    this.initializeEveningTrade();
  }

  ngOnDestroy(): void {
    this.tradeTicketService.clearExtendedHoursValidators();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.extendedHoursService.cancelExtendedHoursTimers();
  }

  initializeEveningTrade(): void {
    if (this.tradeTicketService.tradeTicket.controls.duration.value !== OrderEnums.Durations.EVENING) {
      this.tradeTicketService.resetAmount();
      this.tradeTicketService.tradeTicket.controls.limitPrice.reset();
    }
    this.eveningDurationControlFlow = true;

    this.watchForWarningTimer();
    this.watchForExpirationTimer();
    this.extendedHoursService.setupExtendedHoursTimers();
  }

  loadExtendedHoursAgreement(): void {
    this.store.dispatch(createLoadExtendedTradeAgreementAction());
  }

  handlePreviewBtnClick(): void {
    if (this.tradeTicketService.tradeTicket.invalid) {
      this.tradeTicketService.validateForm();
      return;
    }

    this.store
      .select(selectCostBasisTradeMethodsError)
      .pipe(take(1))
      .subscribe((costBasisMethodError) => {
        if (this.tradeTicketService.specIdIsSelected()) {
          this.loadLotsAndContinueToSelection();
        } else if (
          this.tradeTicketService.isNullSpecIdForNotMarketOrder() &&
          !costBasisMethodError &&
          this.costBasisControl
        ) {
          this.costBasisControl.loadCostBasisMethods();
        } else {
          this.store.dispatch(createValidateOrderAction(false));
        }
      });
  }

  loadLotsAndContinueToSelection() {
    combineLatest([
      this.store.select(selectSelectedAccount),
      this.store.select(selectPositionForCurrentQuoteBySecurityType),
    ])
      .pipe(take(1))
      .subscribe(([account, holding]) => {
        this.store.dispatch(
          createLoadLotsAction(
            account.accountId,
            holding.positionId,
            this.tradeTicketService.tradeTicket.controls.orderId.value,
            this.tradeTicketService.tradeTicket.controls.action.value
          )
        );
      });
  }

  private updateSymbolValidityOnQuoteChange(): void {
    this.quote$
      .pipe(
        tap(() => {
          this.tradeTicketService.tradeTicket.controls.symbol.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private showExpirationWarning(minutes: number) {
    this.minutesRemaining = `${minutes}`;
  }

  private showExpiredModal() {
    this.extendedHoursExpiredModal.modal.openModalDialog();
  }

  private watchForWarningTimer() {
    this.showWarningSubscription$ = this.extendedHoursService.showWarning$
      .pipe(
        filter((minutes) => minutes >= 0),
        tap((minutes) => this.showExpirationWarning(minutes))
      )
      .subscribe();
  }

  private watchForExpirationTimer() {
    this.showExpirationSubscription$ = this.extendedHoursService.showExpired$
      .pipe(
        take(1),
        tap(() => {
          this.showExpiredModal();
        })
      )
      .subscribe();
  }

  resetTradeTicket() {
    this.tradeTicketService.clearForm();
  }

  private watchForActionChanges() {
    this.tradeTicketService.tradeTicket.controls.action.valueChanges
      .pipe(
        tap(() => this.tradeTicketService.resetAmount()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
