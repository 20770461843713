export enum ROUTES {
  TRADE_PATH = '/trade/ticket',
  CONFIRMATION_PATH = '/trade/ticket/confirmation',
  PREVIEW_PAGE_PATH = '/trade/ticket/preview',
  SELECT_SHARES_PATH = '/trade/ticket/select-shares',
  SELECT_SHARES_EDIT_COST_BASIS_PATH = '/trade/edit-cost-basis/select-shares',
  SELECT_SHARES_EDIT_EXTENDED_HOURS_PATH = '/trade/extended-trading/select-shares',
  OPEN_ORDERS_PATH = '/trade/orders',
  EDIT_COST_BASIS_PATH = '/trade/edit-cost-basis',
  CANCEL_PATH = '/trade/cancel-order',
  CANCEL_SUCCESS_PATH = '/trade/cancel-success',
  HOLDINGS_PATH = '/trade/holdings',
  EXTENDED_TRADING_PATH = '/trade/extended-trading',
  EXTENDED_TRADING_PREVIEW_PAGE_PATH = '/trade/extended-trading/preview',
}

export enum InvestorPaths {
  GAINS_LOSS = '/investor-resources-education/taxes/offset-gains-loss-harvesting',
  CAPITAL_GAINS = '/investor-resources-education/taxes/realized-capital-gains',
  IPO_EDUCATION = '/investor-resources-education/news/ipos-what-to-know',
  PAGE_NOT_FOUND_ROUTE = '/404_',
}

export const CONSTANTS = {
  ...ROUTES,
  ...InvestorPaths,
  ADOBE_PROCESS_TYPE: 'Brokerage Path',
  ADOBE_LINK_PROCESS_TYPE: 'Brokerage Path',
  APP_TITLE: 'Trade ETFs or Stocks | Vanguard',
  MAX_ESTIMATED_SHARES: 100000000,
  APP_PREFIX: 'TWE',
  CANNOT_CHANGE_ORDER_RULE_ID: '50301',
  XSRF_TOKEN: 'XSRF-TOKEN',
  TWE_XSRF_HEADER_NAME: 'TWE-XSRF-TOKEN',
  ADDITIONAL_LOADING_MESSAGE_DELAY: 10000,
  TIMEOUT_DEFAULT: 30000,
  RETRY_DEFAULT: 3,
  ALLOW_ANNOYANCES: true,
  C3_SITE: 'retailExternal',
  C11N_TOOLTIP_TRIGGER_CLASS: 'c11n-tooltip__trigger',
  LOADING_CONTEXT_HEADER_NAME: 'TWE-LOADING-CONTEXT',
  SERVERLESS_TEST_COOKIE: 'E2Y51JDVMBLBS5',
  EXTENDED_HOURS_WARNING_TIMEOUT: 5, // number of minutes before expiration warning is shown
  GATEKEEPER_REFRESH_FEATURE_STATUS_INTERVAL: 300000, // in milliseconds - 5 minutes
  DISABLE_CHANGE_EVENING_ORDER: true, // this const should be removed once we enable back change order for EVENING orders
};
