import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { TradeTicketService } from '@app/etfs-equities/services';
import { SegmentedControlComponent } from '@vg-constellation/angular-18/segmented-control';
import { Subject, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[appSegmentControlReset]',
})
export class SegmentControlResetDirective implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private segmentedControl: SegmentedControlComponent, private tradeTicketService: TradeTicketService) {}

  ngAfterViewInit(): void {
    if (this.segmentedControl) {
      this.tradeTicketService.ticketFormReset$
        .pipe(
          tap((controlToRefresh) => {
            // refresh only specific control
            if (controlToRefresh === this.segmentedControl.legendText) {
              this.segmentedControl.handleStateChange();
            } else if (!controlToRefresh) {
              // refresh all
              this.segmentedControl.handleStateChange();
            }
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
