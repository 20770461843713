<ng-container
  *ngIf="{
  summaryLots: (summaryLots$ | async),
} as view"
>
  <c11n-modal-dialog #modal modalId="selectedLotsModal" [headingText]="content.orderDetail.specID.selectedSharesToSell">
    <div c11n-modal-dialog-body>
      <tcx-selected-lots
        [selectedLotsContent]="{
          dateAcquired: content.orderDetail.specID.dateTitle,
          estimatedProceeds: content.orderDetail.specID.estimatedProceeds,
          sharesToSell: content.orderDetail.specID.sharesTitle,
          total: content.orderDetail.specID.total
        }"
        [lotsToSell]="view.summaryLots"
        [imaskConfig]="imaskConfig"
      ></tcx-selected-lots>
    </div>
  </c11n-modal-dialog>
</ng-container>
