import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { OrderEnums, SecurityClassTypes } from '@app/etfs-equities/enums';
import {
  TweOpenOrdersOrderCategoryTypesEnum,
  TweOpenOrdersOrderDurationTypesEnum,
  TweOpenOrdersOrderInstructionCodeEnum,
  TweOpenOrdersOrderStatusCodeTypesEnum,
  TweOpenOrdersOrderStatusTypesEnum,
} from '@app/etfs-equities/enums/open-orders/open-orders.enum';
import { JsonContent, OpenOrder } from '@app/etfs-equities/models';
import { TradeTicketService, WindowService } from '@app/etfs-equities/services';
import { selectCostBasisEligible, TayneState } from '@app/etfs-equities/store';
import { OrderUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-18';
import { combineLatest, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'twe-open-order-item-actions',
  templateUrl: './open-order-item-actions.component.html',
  styleUrl: './open-order-item-actions.component.scss',
})
export class OpenOrderItemActionsComponent implements OnInit {
  // Decorators...
  @Input({ required: true }) order: OpenOrder;
  @Input({ required: true }) numberOfSharesOrDollars: string;

  // Public variables...
  content: JsonContent = content;
  isCancelButtonVisible: boolean;
  isChangeOrderButtonVisible: boolean;
  isEditCostBasisButtonVisible: boolean;
  orderStatusLink = environment.secureSiteUrls.orderStatus;
  securityClasses = OrderUtil.securityIsEquity;
  orderInstructionCodeLabel = OrderUtil.orderInstructionCodeLabel;
  nonEquitySecurityTypeText: string;
  securityIsEquity: boolean;
  isBeacon: boolean;

  //  Public observables/subjects...
  changeOrderEnabled$: Observable<boolean>;
  orderCancelEnabled$: Observable<boolean>;
  isEditCostBasisToggleEnabled$: Observable<boolean>;
  isCostBasisEligible$: Observable<boolean>;
  extendedTradingEnabled$: Observable<boolean>;
  showButtons$: Observable<boolean>;

  // Public Enums...
  statusDetailEnum = TweOpenOrdersOrderStatusTypesEnum;
  instructionCodeEnum = TweOpenOrdersOrderInstructionCodeEnum;
  orderDurationEnum = TweOpenOrdersOrderDurationTypesEnum;
  orderCategoryEnum = TweOpenOrdersOrderCategoryTypesEnum;
  amountIndicators = OrderEnums.AmountIndicators;

  constructor(
    private readonly gatekeeperService: GatekeeperService,
    private readonly store: Store<TayneState>,
    private readonly router: Router,
    private readonly tradeTicketService: TradeTicketService,
    private readonly adobeService: AdobeAnalyticsService,
    private readonly windowService: WindowService
  ) {}

  ngOnInit(): void {
    this.isBeacon = this.windowService.getIsBeacon();
    this.nonEquitySecurityTypeText = this.getNonEquitySecurityTypeText();
    this.securityIsEquity = this.securityClasses.includes(this.order.securityType);
    this.isCostBasisEligible$ = this.store.select(selectCostBasisEligible);

    // gatekeeper features
    this.changeOrderEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CHANGE_ORDER);
    this.extendedTradingEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TOGGLE_EXTENDED_TRADING
    );
    this.isEditCostBasisToggleEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_EDIT_COST_BASIS
    );
    this.orderCancelEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CANCEL_ORDER);

    this.setButtonsVisibility();
  }

  handleActionBtnClick(action: 'change' | 'cancel' | 'editCostBasisMethod'): void {
    const { accountId, orderNumber: orderId } = this.order;
    let url: string;

    switch (action) {
      case 'change':
        this.adobeService.sendAdobeTrackingOnClick('Change order');
        url = CONSTANTS.TRADE_PATH;
        break;
      case 'cancel':
        this.adobeService.sendAdobeTrackingOnClick('Cancel order');
        url = CONSTANTS.CANCEL_PATH;
        break;
      case 'editCostBasisMethod':
        this.adobeService.sendAdobeTrackingOnClick('Edit cost basis method order');
        url = CONSTANTS.EDIT_COST_BASIS_PATH;
        break;
    }

    this.tradeTicketService.resetForm();

    this.router.navigate([url], {
      queryParams: {
        accountId,
        orderId,
      },
    });
  }

  private getNonEquitySecurityTypeText(): string {
    if (this.order.orderSecurityType.includes('MUTUAL_FUND')) {
      return content.openOrders.mutualFund;
    }

    switch (this.order.securityType) {
      case SecurityClassTypes.MUTUAL_FUND.toString():
        return content.openOrders.mutualFund;
      case SecurityClassTypes.CALL_OPTION.toString():
      case SecurityClassTypes.PUT_OPTION.toString():
        return content.labels.options;
      case SecurityClassTypes.CORPORATE_BOND.toString():
      case SecurityClassTypes.MUNICIPAL_BOND.toString():
      case SecurityClassTypes.US_TREASURY_BILL.toString():
      case SecurityClassTypes.US_TREASURY_NOTE.toString():
      case SecurityClassTypes.US_TREASURY_BOND.toString():
      case SecurityClassTypes.OTHER_GOVERNMENT.toString():
      case SecurityClassTypes.US_TREASURY_ZERO_COUPON.toString():
      case SecurityClassTypes.GOVERNMENT_MORTGAGE.toString():
      case SecurityClassTypes.CERTIFICATES_OF_DEPOSIT.toString():
      case SecurityClassTypes.MISCELLANEOUS_FIXED_INCOME.toString():
        return content.openOrders.fixedIncome;
      default:
        return '';
    }
  }

  private showChangeOrderButton(isChangeOrderToggleEnabled: boolean, isExtendedTradingEnabled: boolean): boolean {
    const { amountIndicator, orderDuration, statusDetail, orderStatusCode } = this.order;

    return (
      isChangeOrderToggleEnabled &&
      (orderDuration !== this.orderDurationEnum.EVENING ||
        (isExtendedTradingEnabled && !CONSTANTS.DISABLE_CHANGE_EVENING_ORDER)) &&
      OrderUtil.isStatusChangeOrCancelEligible(statusDetail) &&
      amountIndicator !== this.amountIndicators.DOLLARS &&
      !OrderUtil.isCompletedPartial(this.order) &&
      orderStatusCode !== TweOpenOrdersOrderStatusCodeTypesEnum.ENTERED &&
      orderStatusCode !== TweOpenOrdersOrderStatusCodeTypesEnum.PENDING &&
      !OrderUtil.isPaperTradeOrTTSOrder(this.order)
    );
  }

  private showCancelOrderButton(isCancelOrderToggleEnabled: boolean): boolean {
    const { statusDetail } = this.order;
    return (
      isCancelOrderToggleEnabled &&
      (OrderUtil.isStatusChangeOrCancelEligible(statusDetail) ||
        statusDetail === this.statusDetailEnum.PENDING_CHANGE) &&
      !OrderUtil.isCompletedPartial(this.order) &&
      !OrderUtil.isPaperTradeOrTTSOrder(this.order)
    );
  }

  private showEditCostBasisButton(isEditCostBasisToggleEnabled: boolean, isEditCostBasisEligible: boolean): boolean {
    const { orderInstructionCode, statusDetail } = this.order;
    return (
      isEditCostBasisEligible &&
      isEditCostBasisToggleEnabled &&
      this.securityIsEquity &&
      this.order.costBasisMethod !== CostBasisMethod.AVG_COST &&
      (orderInstructionCode === this.instructionCodeEnum.SELL ||
        orderInstructionCode === this.instructionCodeEnum.BUY_TO_COVER) &&
      (statusDetail === this.statusDetailEnum.EXECUTED ||
        statusDetail === this.statusDetailEnum.PARTIAL_CANCEL ||
        OrderUtil.isCompletedPartial(this.order)) &&
      !OrderUtil.isPaperTradeOrTTSOrder(this.order)
    );
  }

  private setButtonsVisibility(): void {
    this.showButtons$ = combineLatest([
      this.isCostBasisEligible$,
      this.orderCancelEnabled$,
      this.changeOrderEnabled$,
      this.extendedTradingEnabled$,
      this.isEditCostBasisToggleEnabled$,
    ]).pipe(
      tap(
        ([
          isCostBasisEligible,
          orderCancelEnabled,
          changeOrderEnabled,
          extendedTradingEnabled,
          isEditCostBasisToggleEnabled,
        ]) => {
          this.isCancelButtonVisible = this.showCancelOrderButton(orderCancelEnabled);
          this.isChangeOrderButtonVisible = this.showChangeOrderButton(changeOrderEnabled, extendedTradingEnabled);
          this.isEditCostBasisButtonVisible = this.showEditCostBasisButton(
            isEditCostBasisToggleEnabled,
            isCostBasisEligible
          );
        }
      ),
      map(() => this.isCancelButtonVisible || this.isChangeOrderButtonVisible || this.isEditCostBasisButtonVisible)
    );
  }
}
