<h1 class="visually-hidden" id="h1-sr-only" tabindex="0">{{ pageTitle }}</h1>
<div *ngIf="tweNavIsVisible">
  <div
    [ngClass]="{ 'nav-container': (showContactUsTradeHelp || showExitOnly) && !isBeacon }"
    class="container-lg container-xl container-xxl position-relative container-fluid"
  >
    <twe-navigation-tabs id="header-navigation-tabs" [activeItem]="activeNavTab"></twe-navigation-tabs>

    <div
      class="container-fluid d-flex justify-content-end twe-nav-end-links c11n-text-sm"
      *ngIf="showContactUsTradeHelp || showExitOnly"
    >
      <ng-container *ngIf="showContactUsTradeHelp && !showExitOnly">
        <div class="d-none d-lg-block">
          <button c11n-link (click)="tradeHelpModal.open()">{{ content.helpMeTrade }}</button>
        </div>
        <div class="d-none d-lg-block">
          <button c11n-link (click)="contactUsModal.modal.openModalDialog()">{{ content.contactUs }}</button>
        </div>
      </ng-container>
      <button c11n-link *ngIf="!isBeacon" (click)="handleExitBtnClick($event); exitTradeModal.modal.openModalDialog()"
        >{{ content.labels.exit }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="!tweNavIsVisible && (showContactUsTradeHelp || showExitOnly)">
  <div class="container-fluid d-flex justify-content-end right-header-links c11n-text-sm">
    <div *ngIf="showContactUsTradeHelp && !showExitOnly" class="d-none d-lg-block">
      <button c11n-link (click)="tradeHelpModal.open()">{{ content.helpMeTrade }}</button>
    </div>
    <div *ngIf="showContactUsTradeHelp && !showExitOnly" class="d-none d-lg-block">
      <button c11n-link (click)="contactUsModal.modal.openModalDialog()">{{ content.contactUs }}</button>
    </div>
    <div>
      <button c11n-link *ngIf="!isBeacon" (click)="handleExitBtnClick($event); exitTradeModal.modal.openModalDialog()"
        >{{ content.labels.exit }}
      </button>
    </div>
  </div>
</div>

<!-- Modals -->
<twe-exit-trade-modal #exitTradeModal [exitUrl]="exitButtonHref"></twe-exit-trade-modal>
<twe-contact-us-modal #contactUsModal></twe-contact-us-modal>
<twe-trade-help-modal #tradeHelpModal></twe-trade-help-modal>
