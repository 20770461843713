import { Pipe, PipeTransform } from '@angular/core';
import { CashMarginIndicatorTypes } from '@app/etfs-equities/enums';
import { Account } from '@app/etfs-equities/models';
import content from '@content/content.json';

@Pipe({
  name: 'accountName',
  standalone: true,
})
export class AccountNamePipe implements PipeTransform {
  transform(account: Account.Account): string {
    if (!account) {
      return '—';
    }

    let nickname = account.nickname ? `${account.nickname}` : '';
    const name = account.shortName ? account.shortName : '';
    const marginIndicator = account.marginCode === CashMarginIndicatorTypes.MARGIN ? ` (${content.labels.margin})` : '';

    if (nickname && name) {
      nickname += ' — ';
    }

    return name || nickname ? nickname + name + marginIndicator : '—';
  }
}
