<c11n-modal-dialog
  #cannotModifyModal
  modalId="cannot-modify-modal"
  [headingText]="content?.alertsModal.title"
  (closeEvent)="navigateToOrderStatus()"
  [hasForm]="true"
>
  <div c11n-modal-dialog-body>
    {{ content?.changeOrder.cannotModifyOrder }}
  </div>
  <div c11n-modal-dialog-custom-buttons>
    <c11n-button [labelText]="content?.labels.goToOrderStatus" (clickEvent)="navigateToOrderStatus()"></c11n-button>
  </div>
</c11n-modal-dialog>
