import { Component, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { JsonContent } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';

@Component({
  selector: 'twe-order-in-progress-modal',
  templateUrl: './order-in-progress-modal.component.html',
  styleUrls: ['./order-in-progress-modal.component.scss'],
})
export class OrderInProgressModalComponent {
  // Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;

  // Public properties...
  content: JsonContent = content;
  queryParams: Params = {};

  constructor(private readonly router: Router, private readonly tradeTicketService: TradeTicketService) {}

  // Public methods...
  startNewOrder(): void {
    this.tradeTicketService.clearForm();
    this.router.navigate([CONSTANTS.TRADE_PATH], { queryParams: this.queryParams });
  }

  setQueryParams(queryParams: Params) {
    this.queryParams = queryParams;
  }
}
