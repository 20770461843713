import { Account } from '@app/etfs-equities/models/account.model';

export interface AccountState {
  accounts: Account[];
  selectedAccount: Account | null;
  extendedTradeAgreement: boolean | null;
}

export const initialAccountState: AccountState = {
  accounts: [],
  selectedAccount: null,
  extendedTradeAgreement: null,
};
