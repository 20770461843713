<c11n-modal-dialog
  #modal
  [headingText]="content.orderInProgressModal.heading"
  [primaryButtonLabel]="content.orderInProgressModal.newOrderButton"
  [secondaryButtonLabel]="content.labels.close"
  (primaryClick)="modal.closeDialogModal(); startNewOrder()"
  (secondaryClick)="modal.closeDialogModal()"
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md c11n-space-stack-2x">{{ content.orderInProgressModal.body }} </p>
  </div>
</c11n-modal-dialog>
