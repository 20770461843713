<ng-container
  *ngIf="{
    etfs: (etfs$ | async),
    stocks: (stocks$ | async),
    options: (options$ | async),
    mutualFunds: (mutualFunds$ | async),
    CDs: (CDs$ | async),
    bonds: (bonds$ | async),
  } as view"
>
  <label c11n-label for="holdings-filter">{{ content.labels.filterByInvestmentType }}</label>

  <div class="d-flex flex-wrap gap-3">
    <tcx-chip
      [chipType]="chipType"
      [chipSize]="chipSize"
      [chipContent]="{ newHolding: ' ', closed: ' ', filterLabel: content.labels.all }"
      [isSelected]="filterState.all"
      (stateChange)="updateFilterState($event, filterStateEnum.ALL)"
    ></tcx-chip>
    <tcx-chip
      *ngIf="view.etfs?.length"
      [chipType]="chipType"
      [chipSize]="chipSize"
      [chipContent]="{ newHolding: ' ', closed: ' ', filterLabel: content.labels.etfs }"
      [isSelected]="filterState.etfs"
      (stateChange)="updateFilterState($event, filterStateEnum.ETFS)"
    ></tcx-chip>
    <tcx-chip
      *ngIf="view.stocks?.length"
      [chipType]="chipType"
      [chipSize]="chipSize"
      [chipContent]="{ newHolding: ' ', closed: ' ', filterLabel: content.labels.stocks }"
      [isSelected]="filterState.stocks"
      (stateChange)="updateFilterState($event, filterStateEnum.STOCKS)"
    ></tcx-chip>
    <tcx-chip
      *ngIf="view.mutualFunds?.length"
      [chipType]="chipType"
      [chipSize]="chipSize"
      [chipContent]="{ newHolding: ' ', closed: ' ', filterLabel: content.labels.mutualFunds }"
      [isSelected]="filterState.mutualFunds"
      (stateChange)="updateFilterState($event, filterStateEnum.MUTUAL_FUNDS)"
    ></tcx-chip>
    <tcx-chip
      *ngIf="view.bonds?.length"
      [chipType]="chipType"
      [chipSize]="chipSize"
      [chipContent]="{ newHolding: ' ', closed: ' ', filterLabel: content.labels.bonds }"
      [isSelected]="filterState.bonds"
      (stateChange)="updateFilterState($event, filterStateEnum.BONDS)"
    ></tcx-chip>
    <tcx-chip
      *ngIf="view.options?.length"
      [chipType]="chipType"
      [chipSize]="chipSize"
      [chipContent]="{ newHolding: ' ', closed: ' ', filterLabel: content.labels.options }"
      [isSelected]="filterState.options"
      (stateChange)="updateFilterState($event, filterStateEnum.OPTIONS)"
    ></tcx-chip>
    <tcx-chip
      *ngIf="view.CDs?.length"
      [chipType]="chipType"
      [chipSize]="chipSize"
      [chipContent]="{ newHolding: ' ', closed: ' ', filterLabel: content.labels.cds }"
      [isSelected]="filterState.cds"
      (stateChange)="updateFilterState($event, filterStateEnum.CDS)"
    ></tcx-chip>
  </div>
</ng-container>
