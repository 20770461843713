import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AdobeAnalyticsService } from '@app/core/services';
import { JsonContent } from '@app/etfs-equities/models';
import { TriggeredRule, TriggeredRulesDictionary } from '@app/etfs-equities/models/order.model';
import { OrderService, WindowService } from '@app/etfs-equities/services';
import { createAcceptTriggeredRulesAction, TayneState } from '@app/etfs-equities/store';
import { OrderUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { CONSTANTS } from '@etfs-equities/constants';
import { AcceptedRulesNextStep } from '@etfs-equities/enums/triggered-rule.enums';
import { EditService } from '@etfs-equities/services/edit/edit.service';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'twe-triggered-rules-modal',
  templateUrl: './triggered-rules-modal.component.html',
  styleUrls: ['./triggered-rules-modal.component.scss'],
})
export class TriggeredRulesModalComponent implements OnInit, OnDestroy {
  //  Decorators...

  @ViewChild('errorsModal')
  errorsModal: ModalDialogComponent;

  @ViewChild('alertsModal')
  alertsModal: ModalDialogComponent;

  @Input()
  acceptedRulesNextStep: AcceptedRulesNextStep;

  @Output()
  openOrderTypesModalEmitter = new EventEmitter();

  //  Public variables...

  content: JsonContent = content;
  triggeredRulesDictionary: TriggeredRulesDictionary;
  triggeredRules: TriggeredRule[] = [];
  shouldSendToOrderStatusPage = false;

  //  Public observables/subjects...

  unsubscribe$ = new Subject<void>();

  constructor(
    private readonly store: Store<TayneState>,
    public readonly orderService: OrderService,
    private readonly renderer2: Renderer2,
    private readonly windowService: WindowService,
    private readonly adobeService: AdobeAnalyticsService,
    private readonly editService: EditService
  ) {}

  ngOnInit() {
    this.watchForTriggeredRules();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  acceptTriggeredRules() {
    const implicitRules = this.triggeredRulesDictionary ? this.triggeredRulesDictionary.reviewReleases : [];
    const acceptedRules = this.triggeredRules.concat(implicitRules);
    this.store.dispatch(createAcceptTriggeredRulesAction(acceptedRules, this.acceptedRulesNextStep));
  }

  navigateToOrderStatus() {
    this.windowService.router.navigate([CONSTANTS.OPEN_ORDERS_PATH]);
  }

  edit() {
    this.editService.editOrder$.next();
  }

  private watchForTriggeredRules() {
    this.orderService.triggeredRules$
      .pipe(
        // Map triggered rules into a more consumable format.
        map((order) => OrderUtil.keyTriggeredRulesByType(order)),

        // Decide which triggered rules take precedence
        // and then launch the appropriate modal.
        tap((rules) => {
          this.triggeredRulesDictionary = rules;

          if (rules.errors.length > 0) {
            this.triggeredRules = rules.errors;
            this.errorsModal.openModalDialog();
            this.adobeService.sendAdobeLaunchProcessError(
              this.triggeredRules.map((rule) => rule.ruleId).join(','),
              `${CONSTANTS.ADOBE_PROCESS_TYPE} - Errors`
            );
          } else if (rules.alerts.length > 0) {
            this.triggeredRules = rules.alerts;
            this.alertsModal.openModalDialog();
            this.adobeService.sendAdobeLaunchProcessError(
              this.triggeredRules.map((rule) => rule.ruleId).join(','),
              `${CONSTANTS.ADOBE_PROCESS_TYPE} - Alerts`
            );
          }
          this.shouldSendToOrderStatusPage = this.checkToSendOrderStatusPage(this.triggeredRules);
        }),
        debounceTime(500),
        tap(() => this.handleTriggeredRulesReplacement(this.triggeredRules)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private readonly onRulesOrderTypesMoreInfo = (event) => {
    event.preventDefault();
    this.alertsModal.closeDialogModal();
    this.openOrderTypesModalEmitter.emit();
  };

  private readonly handleTriggeredRulesReplacement = (triggeredRules: TriggeredRule[]) => {
    if (triggeredRules.filter((rule) => rule.ruleId === '23103').length > 0) {
      const elements = this.windowService.$document.getElementsByClassName('stop-order__link');
      Array.prototype.forEach.call(elements, (element) =>
        this.renderer2.listen(element, 'click', this.onRulesOrderTypesMoreInfo)
      );
    }
  };

  private checkToSendOrderStatusPage(triggeredRules: TriggeredRule[]): boolean {
    return triggeredRules.length === 1 && triggeredRules[0].ruleId === CONSTANTS.CANNOT_CHANGE_ORDER_RULE_ID;
  }
}
