<ng-container
  *ngIf="{
    selectedAccount: (selectedAccount$ | async),
    etfs: (etfs$ | async),
    stocks: (stocks$ | async),
    options: (options$ | async),
    mutualFunds: (mutualFunds$ | async),
    CDs: (CDs$ | async),
    bonds: (bonds$ | async),
    accountIsTradeable: (accountIsTradeable$ | async),
    openOrders: (openOrders$ | async),
  } as view"
>
  <div class="container-fluid c11n-space-stack-5x c11n-space--force">
    <twe-accounts-control [showAccountDetails]="true"></twe-accounts-control>
    <twe-accounts-error (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"></twe-accounts-error>
    <twe-shingle
      *ngIf="!view.selectedAccount && !isRefreshingAccounts && !hasCriticalHoldingError"
      data-testid="no-selected-account"
      [message]="content.holdings.selectAccountToView"
    ></twe-shingle>

    <twe-cannot-trade-shingle></twe-cannot-trade-shingle>

    <twe-managed-account-shingle></twe-managed-account-shingle>

    <div *ngIf="view.accountIsTradeable" class="twe-scrollable">
      <div *ngIf="hasCriticalHoldingError">
        <twe-shingle
          data-testid="holdings-critical-error"
          [message]="content.holdings.criticalHoldingErrorText"
          [primaryButtonLabel]="content.retry"
          (primaryButtonClicked)="refreshHoldings()"
          [primaryButtonIconName]="'refresh'"
          [secondaryButtonLabel]="content.backToTrading"
          (secondaryButtonClicked)="backToTrading()"
        ></twe-shingle>
      </div>

      <div *ngIf="!view.selectedAccount?.holdings?.length && !isRefreshingAccounts && !hasCriticalHoldingError">
        <twe-shingle
          data-testid="no-holdings"
          [message]="content.holdings.noHoldingsText"
          [primaryButtonLabel]="content.backToTrading"
          (primaryButtonClicked)="backToTrading()"
        ></twe-shingle>
      </div>

      <div class="c11n-text-md" *ngIf="!hasCriticalHoldingError">
        <!-- Holdings tables filter -->
        <div *ngIf="isFilterAvailable" class="c11n-space-stack-4x">
          <twe-holdings-filter (filterStateChanged)="updateFilterState($event)"></twe-holdings-filter>
        </div>

        <twe-refresh-button
          class="c11n-space-stack-3x c11n-space--force justify-content-start"
          [isLoading]="isRefreshingAccounts"
          (refresh)="refreshHoldings()"
          [hasFailed]="hasCriticalHoldingError"
          [title]="content.loading.refreshHoldings"
          [isWrap]="false"
          [ariaLabel]="content.holdings.holdingsAsOf + ' ' + (view.selectedAccount?.asOfDate | tweDate)"
        >
          <tcx-timestamp
            [showTime]="true"
            [prefixText]="content.labels.asOf"
            [size]="timestampSizeEnum.XS"
            [date]="view.selectedAccount?.asOfDate | tweDate"
          ></tcx-timestamp>
        </twe-refresh-button>

        <!-- Holdings tables -->
        <twe-holdings-table
          *ngIf="view.etfs?.length && (tablesVisibility.etfs || tablesVisibility.all)"
          [title]="content.labels.etfs"
          [holdings]="view.etfs"
          [selectedAccount]="view.selectedAccount"
          [openOrders]="view.openOrders?.orders"
          (openMutualFundModal)="openMutualFundModal($event)"
          (openOrderInProgressModal)="openOrderInProgressModal($event)"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.stocks?.length && (tablesVisibility.stocks || tablesVisibility.all)"
          [title]="content.labels.stocks"
          [holdings]="view.stocks"
          [selectedAccount]="view.selectedAccount"
          [openOrders]="view.openOrders?.orders"
          (openMutualFundModal)="openMutualFundModal($event)"
          (openOrderInProgressModal)="openOrderInProgressModal($event)"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.mutualFunds?.length && (tablesVisibility.mutualFunds || tablesVisibility.all)"
          [title]="content.labels.mutualFunds"
          [holdings]="view.mutualFunds"
          [selectedAccount]="view.selectedAccount"
          [openOrders]="view.openOrders?.orders"
          (openMutualFundModal)="openMutualFundModal($event)"
          (openOrderInProgressModal)="openOrderInProgressModal($event)"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.bonds?.length && (tablesVisibility.bonds || tablesVisibility.all)"
          [title]="content.labels.bonds"
          [holdings]="view.bonds"
          [selectedAccount]="view.selectedAccount"
          [openOrders]="view.openOrders?.orders"
          (openMutualFundModal)="openMutualFundModal($event)"
          (openOrderInProgressModal)="openOrderInProgressModal($event)"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.options?.length && (tablesVisibility.options || tablesVisibility.all)"
          [title]="content.labels.options"
          [holdings]="view.options"
          [selectedAccount]="view.selectedAccount"
          [openOrders]="view.openOrders?.orders"
          (openMutualFundModal)="openMutualFundModal($event)"
          (openOrderInProgressModal)="openOrderInProgressModal($event)"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.CDs?.length && (tablesVisibility.cds || tablesVisibility.all)"
          [title]="content.labels.cds"
          [holdings]="view.CDs"
          [selectedAccount]="view.selectedAccount"
          [openOrders]="view.openOrders?.orders"
          (openMutualFundModal)="openMutualFundModal($event)"
          (openOrderInProgressModal)="openOrderInProgressModal($event)"
        ></twe-holdings-table>
      </div>

      <div class="holdings-disclaimer">
        <p class="c11n-text-sm twe-c11n-color-ink-400"> {{ content.holdings.footnoteIntraday }}</p>
        <p class="c11n-text-sm twe-c11n-color-ink-400">
          {{ content.holdings.footnoteMarket }}
          <a *ngIf="!isBeacon" c11n-link [attr.href]="content.factSetUrl" target="_blank" iconName="pop-window"
            >{{ content.holdings.footnoteLink }}
          </a>
          <span *ngIf="isBeacon">{{ content.holdings.footnoteLink }}</span
          >.
        </p>
      </div>
    </div>
  </div>

  <twe-mutual-fund-error-modal
    #mutualFundModal
    [symbol]="symbolForMutualFundModal"
    [isChangeOrder]="false"
    [selectedAccount]="view.selectedAccount"
  ></twe-mutual-fund-error-modal>

  <twe-order-in-progress-modal #orderInProgressModal></twe-order-in-progress-modal>
</ng-container>
