import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum ActiveNavTabActionTypes {
  SET_ACTIVE_NAV_TAB = '[Active Nav Tab] Set',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class SetActiveNavTabAction implements Action {
  readonly type = ActiveNavTabActionTypes.SET_ACTIVE_NAV_TAB;
  constructor(public payload: string) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createSetActiveNavTabAction = (navTab: string) => {
  return new SetActiveNavTabAction(navTab);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type ActiveNavTabActionsUnion = SetActiveNavTabAction;
