import { NgIf } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';
import { CashMarginIndicatorTypes } from '@app/etfs-equities/enums';
import { AccountSelectorAccount } from '@app/etfs-equities/models';
import {
  AccountDetailsActionHandler,
  AccountDetailsConfigProvider,
  AccountDetailsDataProvider,
} from '@app/etfs-equities/models/mfe-account-details.model';
import { WindowService } from '@app/etfs-equities/services';
import { selectAsOfDate, selectFundsAvailable, selectSelectedAccount } from '@app/etfs-equities/store';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { map, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'twe-account-details-modal',
  standalone: true,
  imports: [ModalDialogComponent, NgIf],
  templateUrl: './account-details-modal.component.html',
  styleUrl: './account-details-modal.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountDetailsModalComponent {
  // Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;

  // Public variables...
  content = require('@content/content.json');
  modalHeading: string;

  // MFE Account Details Modal Config...
  readonly accountDetailsDataProvider: AccountDetailsDataProvider;
  readonly accountDetailsActionHandler: AccountDetailsActionHandler;
  readonly accountDetailsConfigProvider: AccountDetailsConfigProvider = {
    getConfig: () => ({
      showContributionsInfo: false,
    }),
  };

  constructor(private readonly store: Store, private windowService: WindowService) {
    this.accountDetailsDataProvider = {
      getSelectedBrokerageAccount: (): Observable<AccountSelectorAccount> =>
        this.store.pipe(
          select(selectSelectedAccount),
          tap((account) => {
            const marginAccount =
              account?.marginCode === CashMarginIndicatorTypes.MARGIN ? ` (${this.content.labels.margin})` : '';
            this.modalHeading = `${this.content.labels.brokerageAccount},  ${account.brokerageAccountNumber} ${marginAccount}`;
          }),
          map((account) => ({
            ...account,
            accountId: account.toString(),
          }))
        ),
      getSelectedAccountFundsAvailable: () => this.store.pipe(select(selectFundsAvailable)),
      getContributionsInfo: () => of([]),
      getCurrentDate: () =>
        this.store.pipe(
          select(selectAsOfDate),
          map((date) => new Date(date))
        ),
    };

    this.accountDetailsActionHandler = {
      goToMyHoldingsPage: () => {
        this.windowService.navigateToExternalLink(environment.secureSiteUrls.balancesHoldings, '_blank');
      },
      goToRetirementSummaryPage: () => {
        this.windowService.navigateToExternalLink(environment.retirementSummaryUrl, '_blank');
      },
      goToCashPlusDashboardPage: () => {
        this.windowService.navigateToExternalLink(environment.cashPlusDashboardUrl, '_blank');
      },
    };
  }
}
