<div role="alert" class="twe-error-page container">
  <div class="text-center">
    <img
      src="{{ windowService.assetsPath }}assets/svg/load-fail.svg"
      [alt]="content.changeOrder.iconAlt"
      class="c11n-space-stack-2x"
      aria-hidden="true"
      loading="lazy"
    />
    <ng-content></ng-content>

    <p>
      <a c11n-link [routerLink]="OPEN_ORDERS_PATH">
        {{ content?.labels.goToOrderStatus }}
      </a>
    </p>
  </div>
</div>
