<ng-container
  *ngIf="{
    account: (selectedAccount$ | async),
    openOrders: (openOrders$ | async),
    costBasisEligible: (costBasisEligible$ | async),
    accountIsTradeable: (accountIsTradeable$ | async),
  } as view"
>
  <div class="container-fluid overflow-hidden c11n-space-stack-5x">
    <div class="row">
      <twe-accounts-control class="col-12" [showAccountDetails]="true"></twe-accounts-control>

      <twe-refresh-button
        *ngIf="view.accountIsTradeable && !orderRetrievalFailed"
        class="c11n-space-stack-3x c11n-space--force col-12 justify-content-start"
        [isLoading]="isOpenOrdersLoading"
        [hasFailed]="orderRetrievalFailed"
        (refresh)="refreshOpenOrdersForAccount(view.account, view.costBasisEligible)"
        [title]="content.loading.refreshOrders"
        [isWrap]="false"
        [ariaLabel]="'Account open orders, as of ' + (view.openOrders?.asOfDate | tweDate)"
      >
        <tcx-timestamp
          [showTime]="true"
          [prefixText]="content.labels.asOf"
          [size]="timestampSizeEnum.XS"
          [date]="view.openOrders?.asOfDate | tweDate"
        ></tcx-timestamp>
      </twe-refresh-button>
    </div>

    <twe-service-unavailable-msg *ngIf="!view.account && !hasAccountRetrievalError">
      <p class="c11n-space-stack-1x">{{ content.openOrders.noAccountSelected }}</p>
    </twe-service-unavailable-msg>

    <twe-accounts-error (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"></twe-accounts-error>

    <twe-cannot-trade-shingle></twe-cannot-trade-shingle>

    <twe-managed-account-shingle></twe-managed-account-shingle>

    <twe-generic-error-modal></twe-generic-error-modal>

    <twe-service-unavailable-msg *ngIf="orderRetrievalFailed" iconType="warning">
      <p class="twe-c11n-color-ink-400 c11n-space-stack-1x">{{ content.openOrders.openOrdersUnavailable }}</p>
      <p>
        <button c11n-link (click)="refreshOpenOrdersForAccount(view.account, view.costBasisEligible)">
          {{ content.retry }}
        </button>
      </p>
    </twe-service-unavailable-msg>

    <twe-open-orders-component
      *ngIf="view.accountIsTradeable"
      [isLoading]="isOpenOrdersLoading"
      [orderRetrievalFailed]="orderRetrievalFailed"
      [selectedAccount]="view.account"
      [openOrders]="view.openOrders?.orders"
      (resetForm)="onChildActionBtnClick()"
    ></twe-open-orders-component>
  </div>
</ng-container>
