<ng-container
  *ngIf="{
    selectedAccount: (selectedAccount$ | async)
  } as view"
>
  <twe-input-label
    for="symbol"
    [labelText]="content.labels.symbol"
    [tooltipHeader]="content.guidelet.symbolKeywordLookup.header"
  >
    <p [innerHtml]="content.guidelet.symbolKeywordLookup.content"></p>
    <button
      c11n-link
      colorMode="on-dark"
      type="button"
      (click)="
        adobeService.sendAdobeTrackingOnClick(content.guidelet.symbolKeywordLookup.link, 'link', 'SymbolToolTip');
        symbolSearchModal.open($event)
      "
    >
      {{ content.guidelet.symbolKeywordLookup.link }}
    </button>
  </twe-input-label>

  <c11n-input [hasError]="symbolHasError">
    <input
      c11nInput
      id="symbol"
      #symbolInput
      autocomplete="off"
      autocorrect="off"
      [formControl]="tradeTicketService.tradeTicket.controls.symbol"
      name="symbol"
      spellcheck="false"
      type="text"
      [attr.aria-describedby]="
        tradeTicketService.controlHasError('symbol') ? 'symbol-error' : 'symbol-search-description'
      "
      [attr.aria-invalid]="tradeTicketService.controlHasError('symbol')"
      [class.text-uppercase]="tradeTicketService.tradeTicket.controls.symbol.value?.length"
      [placeholder]="content.labels.getQuote"
      [attr.disabled]="isDisabled ? true : null"
      (blur)="fetchQuote()"
      (keydown.enter)="fetchQuote($event)"
    />
  </c11n-input>

  <c11n-hint-error
    *ngIf="symbolHasError"
    [hasError]="symbolHasError"
    hintErrorId="symbol-error"
    [errorText]="getErrorText()"
    data-testid="txt-input-validation"
  ></c11n-hint-error>
  <div id="symbol-search-description" class="visually-hidden">{{ content.symbolSearchDescription }}</div>

  <twe-mutual-fund-error-modal
    [symbol]="symbol"
    [selectedAccount]="view.selectedAccount"
    [isChangeOrder]="isChangeOrder"
  >
  </twe-mutual-fund-error-modal>
</ng-container>

<twe-symbol-modal #symbolSearchModal></twe-symbol-modal>
