<ng-container
  *ngIf="{
    cancelResponse: cancelResponse$ | async,
    orderCancel: (orderCancel$ | async),
  } as view"
>
  <div class="container-fluid c11n-space-stack-5x c11n-space--force">
    <div class="row">
      <div class="c11n-space-stack-2x">
        <h2 class="c11n-text-3xl-headline">
          {{ content.cancelSuccess.cancelOrderReceived }}
        </h2>
      </div>
      <div class="col-lg-6" role="alert">
        <twe-order-confirmation-header
          *ngIf="view.orderCancel"
          [accountId]="view.orderCancel.accountId"
          [orderId]="view.orderCancel.orderId"
          [confirmationNumber]="view.cancelResponse.confirmationNumber"
          [submittedOn]="view.cancelResponse.cancelTimestamp"
        ></twe-order-confirmation-header>

        <twe-cancel-order-detail></twe-cancel-order-detail>
      </div>
      <div class="col-lg-6 col-xl-5 offset-xl-1" role="navigation" [attr.aria-label]="content.headings.whatsNext">
        <h2 class="twe-c11n-color-ink-800 c11n-text-lg-bold c11n-space-stack-1_5x">
          {{ content.headings.whatsNext }}
        </h2>
        <ul class="twe-unstyled-list" [attr.aria-label]="content.headings.whatsNext">
          <li class="c11n-space-stack-2x">
            <c11n-button
              buttonType="button"
              size="medium"
              variant="primary"
              [fullWidth]="true"
              [labelText]="content.labels.placeAnotherTrade"
              (clickEvent)="handleNewTrade(view.orderCancel?.accountId)"
            ></c11n-button>
          </li>
          <li>
            <c11n-button
              buttonType="button"
              size="medium"
              variant="secondary"
              [fullWidth]="true"
              [labelText]="content.labels.goToHoldingsPage"
              (clickEvent)="handleViewHoldings(view.orderCancel?.accountId)"
            ></c11n-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
