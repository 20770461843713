import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { HoldingFilterStatesEnum } from '@app/etfs-equities/enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { HoldingsFilterState, MappedHolding } from '@app/etfs-equities/models/account.model';
import {
  selectBondHoldings,
  selectCDHoldings,
  selectEtfHoldings,
  selectMutualFundHoldings,
  selectOptionHoldings,
  selectSelectedAccount,
  selectStockHoldings,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { ChipSize, ChipType } from '@vanguard/trade-ui-components-lib-ng-18';
import { Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-holdings-filter',
  templateUrl: './holdings-filter.component.html',
  styleUrl: './holdings-filter.component.scss',
})
export class HoldingsFilterComponent implements OnInit, OnDestroy {
  // Decorators
  @Output() filterStateChanged = new EventEmitter<HoldingsFilterState>();

  // Public variables
  filterStateEnum = HoldingFilterStatesEnum;
  filterState: HoldingsFilterState = { all: true };
  content: JsonContent = content;
  chipType = ChipType.FILTER;
  chipSize = ChipSize.MD;

  // Public observables...
  selectedAccount$: Observable<Account.Account>;
  stocks$: Observable<MappedHolding[]>;
  etfs$: Observable<MappedHolding[]>;
  options$: Observable<MappedHolding[]>;
  mutualFunds$: Observable<MappedHolding[]>;
  CDs$: Observable<MappedHolding[]>;
  bonds$: Observable<MappedHolding[]>;

  // Private observables...
  private unsubscribe$: Subject<void> = new Subject();

  constructor(private readonly store: Store<TayneState>) {}

  ngOnInit(): void {
    this.selectedAccount$ = this.store.select(selectSelectedAccount);
    this.stocks$ = this.store.select(selectStockHoldings);
    this.etfs$ = this.store.select(selectEtfHoldings);
    this.options$ = this.store.select(selectOptionHoldings);
    this.mutualFunds$ = this.store.select(selectMutualFundHoldings);
    this.CDs$ = this.store.select(selectCDHoldings);
    this.bonds$ = this.store.select(selectBondHoldings);

    this.watchForSelectedAccount();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateFilterState(state: boolean, key: HoldingFilterStatesEnum) {
    if (key === HoldingFilterStatesEnum.ALL) {
      for (const [prop] of Object.entries(this.filterState)) {
        this.filterState[prop] = !state;
      }
      this.filterState.all = state;
    } else {
      this.filterState[key] = state;
      this.filterState.all = this.isAllSelected();
    }
    this.filterStateChanged.emit(this.filterState);
  }

  private initChipState(holdings: MappedHolding[], key: HoldingFilterStatesEnum) {
    if (holdings?.length) {
      this.filterState[key] = false;
    } else {
      delete this.filterState[key];
    }
  }

  private isAllSelected(): boolean {
    return Object.entries(this.filterState).every(([_key, value]) => value === false);
  }

  private watchForSelectedAccount(): void {
    this.selectedAccount$
      .pipe(
        withLatestFrom(this.stocks$, this.etfs$, this.options$, this.mutualFunds$, this.CDs$, this.bonds$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([_account, stocks, etfs, options, mutualFunds, cds, bonds]) => {
        this.filterState.all = true;

        this.initChipState(stocks, HoldingFilterStatesEnum.STOCKS);
        this.initChipState(etfs, HoldingFilterStatesEnum.ETFS);
        this.initChipState(options, HoldingFilterStatesEnum.OPTIONS);
        this.initChipState(mutualFunds, HoldingFilterStatesEnum.MUTUAL_FUNDS);
        this.initChipState(cds, HoldingFilterStatesEnum.CDS);
        this.initChipState(bonds, HoldingFilterStatesEnum.BONDS);

        this.filterStateChanged.emit(this.filterState);
      });
  }
}
