<ng-container
  *ngIf="{
 accountIsCashPlus: accountIsCashPlus$ | async,
} as view"
>
  <ng-container *ngIf="view.accountIsCashPlus">
    <div class="container-fluid c11n-space-stack-8x c11n-space--force">
      <div class="row">
        <div class="col-lg-5 col-xl-6">
          <h1 class="cannot-trade-header c11n-space-stack-3x">{{ content.cannotTradeShingle.title }}</h1>
          <div class="c11n-text-lg">
            <p class="c11n-space-stack-3x">{{ content.cannotTradeShingle.body }}</p>
            <p>
              <a
                c11n-link
                variant="secondary-reinforced"
                iconName="pop-window"
                target="_blank"
                href="{{ content.cannotTradeShingle.learnMoreLink }}"
              >
                {{ content.cannotTradeShingle.learnMoreText }}
              </a>
            </p>
          </div>
        </div>
        <div class="growth-image col-lg-7 col-xl-6 d-none d-lg-block">
          <img src="{{ windowService.assetsPath }}assets/svg/growth.svg" alt="" loading="lazy" />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
