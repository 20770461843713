import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SymbolRetrievalType } from '@app/etfs-equities/enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { SymbolRetrievalError } from '@app/etfs-equities/models/symbol-retrieval-error.model';
import { MarketDataService, WindowService } from '@app/etfs-equities/services';
import { selectSelectedAccount, TayneState } from '@app/etfs-equities/store';
import { selectEnvironment } from '@app/etfs-equities/store/selectors/environment/environment.selectors';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'twe-mutual-fund-error-modal',
  templateUrl: './mutual-fund-error-modal.component.html',
  styleUrls: ['./mutual-fund-error-modal.component.scss'],
})
export class MutualFundErrorModalComponent implements OnInit, OnDestroy {
  //  Decorators...

  @Input({ required: true }) symbol: string;
  @Input({ required: true }) selectedAccount: Account.Account;
  @Input() isChangeOrder = false;

  @ViewChild('modal')
  modal: ModalDialogComponent;

  //  Public observables/subjects...

  selectedAccount$: Observable<Account.Account>;
  env$: Observable<any>;

  //  Public variables...

  content: JsonContent = content;
  mfQueryParams: string;
  isBeacon = false;
  symbolFromRetrievalError = '';

  // Private observables/subjects...

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly windowService: WindowService,
    public marketDataService: MarketDataService,
    private readonly store: Store<TayneState>
  ) {}

  ngOnInit(): void {
    this.env$ = this.store.pipe(select(selectEnvironment));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.isBeacon = this.windowService.getIsBeacon();

    this.watchForMutualFundSymbolError();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setMarketDataIsLoading(value: boolean) {
    this.marketDataService.setIsLoading(value);
  }

  setQueryParams(symbol: string) {
    this.mfQueryParams = '';

    if (this.selectedAccount?.accountId) {
      this.mfQueryParams += `&accountId=${this.selectedAccount.accountId}`;
    }

    if (symbol) {
      this.mfQueryParams += `&ticker=${symbol.toUpperCase()}`;
    }
  }

  private watchForMutualFundSymbolError() {
    this.marketDataService.symbolRetrievalError$
      .pipe(
        filter(
          (error: SymbolRetrievalError) =>
            !this.isChangeOrder &&
            (error.type === SymbolRetrievalType.MUTUAL_FUND || error.type === SymbolRetrievalType.NON_VG_MUTUAL_FUND)
        ),
        tap((symbolRetrievalError: SymbolRetrievalError) => {
          this.setQueryParams(symbolRetrievalError.symbol);

          this.symbolFromRetrievalError = symbolRetrievalError.symbol;

          this.modal.openModalDialog();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
