import { Component, ViewChild } from '@angular/core';
import content from '@content/content.json';
import { CONSTANTS } from '@etfs-equities/constants';
import { JsonContent } from '@etfs-equities/models';
import { WindowService } from '@etfs-equities/services';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';

@Component({
  selector: 'twe-extended-hours-expired-modal',
  templateUrl: './extended-hours-expired-modal.component.html',
  styleUrls: ['./extended-hours-expired-modal.component.scss'],
})
export class ExtendedHoursExpiredModalComponent {
  //  Decorators...
  @ViewChild('modal') modal: ModalDialogComponent;

  //  Public variables...
  content: JsonContent = content;

  constructor(private readonly windowService: WindowService) {}

  navigateToTradePage() {
    this.windowService.navigateToExternalLink(CONSTANTS.TRADE_PATH);
  }
}
