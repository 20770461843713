import { Component, OnInit } from '@angular/core';
import content from '@content/content.json';
import { JsonContent } from '@etfs-equities/models';
import { WindowService } from '@etfs-equities/services';
import { selectAccountIsCashPlus, TayneState } from '@etfs-equities/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-cannot-trade-shingle',
  templateUrl: './cannot-trade-shingle.component.html',
  styleUrls: ['./cannot-trade-shingle.component.scss'],
})
export class CannotTradeShingleComponent implements OnInit {
  //  Public variables...
  content: JsonContent = content;

  //  Public observables/subjects...
  accountIsCashPlus$: Observable<boolean>;

  constructor(public readonly windowService: WindowService, private readonly store: Store<TayneState>) {}

  ngOnInit() {
    this.accountIsCashPlus$ = this.store.select(selectAccountIsCashPlus);
  }
}
