<ng-container
  *ngIf="{
    order: order$ | async,
    orderHasExceededFundsAvailable: orderHasExceededFundsAvailable$ | async,
    isSellingAllHeldShares: isSellingAllHeldShares$ | async,
    dollarBasedTradesEnabled: dollarBasedTradesEnabled$ | async,
    changeOrderEnabled: (changeOrderEnabled$ | async),
    cancelOrderEnabled: (cancelOrderEnabled$ | async),
    extendedTradingEnabled: (extendedTradingEnabled$ | async),
    isChangeOrder: isChangeOrder$ | async,
    isEditCostBasis: isEditCostBasis$ | async,
  } as view"
>
  <div class="container-fluid" data-testid="confirm-page-wrapper">
    <div class="row">
      <div class="c11n-space-stack-2x c11n-space--force">
        <h2 class="c11n-text-3xl-headline">
          {{ content.labels.yourOrderHasBeenSubmitted }}
        </h2>
      </div>
      <div class="col-lg-7 d-print-none order-summary-column">
        <div>
          <div class="d-none d-print-block print-vanguard-logo">
            <img src="{{ windowService.assetsPath }}assets/svg/vanguard.svg" alt="Vanguard" loading="lazy" />
            <hr class="print-vanguard-logo__hr" />
          </div>
          <twe-order-confirmation-header
            *ngIf="view.order"
            [accountId]="view.order.accountId"
            [orderId]="view.order.orderId"
            [confirmationNumber]="view.order.confirmationNumber"
            [isChangeOrder]="view.isChangeOrder"
          ></twe-order-confirmation-header>

          <twe-order-details></twe-order-details>
        </div>
      </div>

      <div class="col-lg-5 real-time-quote-column">
        <div class="real-time-quote-column__next-steps">
          <h3 class="c11n-text-lg-bold c11n-space-stack-2x">{{ content.labels.nextSteps }}</h3>
          <div class="c11n-space-stack-2x">
            <c11n-button
              [ariaLabelText]="content.labels.placeAnotherTrade"
              [labelText]="content.labels.placeAnotherTrade"
              (clickEvent)="navigateToTradePage($event)"
              [fullWidth]="true"
            ></c11n-button>
          </div>

          <div class="c11n-space-stack-3x">
            <c11n-button
              [ariaLabelText]="content.labels.goToHoldingsPage"
              [labelText]="content.labels.goToHoldingsPage"
              (clickEvent)="navigateToHoldingsPage($event)"
              [fullWidth]="true"
              variant="secondary"
            ></c11n-button>
          </div>
          <hr />
        </div>

        <div class="real-time-quote-column__what-happens-next">
          <h2 class="twe-text-bold real-time-quote-column__what-happens-next-heading">
            {{ content.headings.whatHappensNext }}
          </h2>
          <div *ngIf="!view.isEditCostBasis" class="c11n-space-stack-1x">
            <p class="c11n-text-md twe-text-black">
              {{ content.whatHappensNext.transactionConfirmation }}
            </p>
            <p
              class="c11n-text-md twe-text-black"
              *ngIf="
                view.order?.transactionType === transactionTypes.BUY ||
                view.order?.transactionType === transactionTypes.BUY_TO_COVER
              "
            >
              {{ content.whatHappensNext.buy }}
            </p>
            <p
              class="c11n-text-md twe-text-black"
              *ngIf="
                view.order?.transactionType === transactionTypes.SELL ||
                view.order?.transactionType === transactionTypes.SELL_SHORT
              "
            >
              {{ content.whatHappensNext.sell }}
            </p>
            <p class="c11n-text-md twe-text-black" *ngIf="view.dollarBasedTradesEnabled && view.isSellingAllHeldShares">
              <span class="c11n-text-md-bold">{{ content.labels.important }} </span>
              {{ content.whatHappensNext.sellAll }}
            </p>
            <p class="twe-text-bold c11n-text-md twe-text-black" *ngIf="view.orderHasExceededFundsAvailable">
              {{ content.whatHappensNext?.coverYourTrade?.part1 }}
              <a c11n-link fontWeight="normal" variant="primary-reinforced" [attr.href]="secureSiteUrls.investHub">{{
                content.whatHappensNext?.coverYourTrade?.part2
              }}</a>
              {{ content.whatHappensNext?.coverYourTrade?.part3 }}
            </p>
          </div>
          <p class="c11n-text-md twe-text-black" *ngIf="view.isEditCostBasis">
            {{ content.whatHappensNext.transactionConfirmationEditCostBasis }}
          </p>

          <!-- Static content -->
          <div *ngIf="!view.isEditCostBasis">
            <p>
              {{ content.whatHappensNext.openOrderStatement.part1 }}
            </p>
            <p
              *ngIf="
                isChangeOrderLinkVisible(view.changeOrderEnabled, view.extendedTradingEnabled, view.order) ||
                view.cancelOrderEnabled
              "
            >
              {{ content.whatHappensNext.openOrderStatement.part2 }}
              <ng-container
                *ngIf="isChangeOrderLinkVisible(view.changeOrderEnabled, view.extendedTradingEnabled, view.order)"
              >
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [routerLink]="TRADE_PATH"
                  [queryParams]="{ accountId: view.order.accountId, orderId: view.order.orderId }"
                >
                  {{ content.whatHappensNext.openOrderStatement.part3 }}
                </a>
                <span *ngIf="view.cancelOrderEnabled">{{ content.whatHappensNext.openOrderStatement.part4 }}</span>
              </ng-container>
              <a
                *ngIf="view.cancelOrderEnabled"
                c11n-link
                variant="primary-reinforced"
                [routerLink]="CANCEL_PATH"
                [queryParams]="{ accountId: view.order.accountId, orderId: view.order.orderId }"
              >
                {{ content.whatHappensNext.openOrderStatement.part5 }}</a
              >.
              {{ content.whatHappensNext.openOrderStatement.part6 }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
