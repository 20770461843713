<div [class.c11n-space-stack-3x]="!dismissed">
  <c11n-banner
    #marketVolatilityBanner
    *ngIf="!dismissed"
    [size]="'large'"
    (closeEvent)="dismiss()"
    [headingText]="content?.marketVolatilityAlertBanner.header"
    [hasCloseControl]="true"
    [hasDetails]="false"
    [closeAriaLabel]="content?.labels.close"
    [iconName]="'warning'"
    [variant]="'basic'"
  >
    <div class="c11n-text-md--crop">
      <p class="c11n-space-stack-1x mt-2 c11n-space--force">
        {{ content?.marketVolatilityAlertBanner.body }}
      </p>
      <button
        c11n-link
        type="button"
        (click)="
          adobeService.sendAdobeTrackingOnClick('clickVolatileTips', 'link'); tipsForTradingVolatilityModal.open()
        "
        variant="secondary-reinforced"
        fontWeight="normal"
      >
        {{ content?.marketVolatilityAlertBanner.link }}
      </button>
    </div>
  </c11n-banner>
</div>

<twe-tips-for-trading-volatility-modal #tipsForTradingVolatilityModal></twe-tips-for-trading-volatility-modal>
