import { Component, OnInit } from '@angular/core';
import { AdobeAnalyticsService } from '@app/core/services';
import { Account, JsonContent } from '@app/etfs-equities/models';
import {
  selectAccountIsMargin,
  selectDollarValueOfSharesHeld,
  selectSelectedAccount,
  selectSharesHeldForCash,
  selectSharesHeldForMargin,
  selectSharesHeldForShort,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-18';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-shares-held',
  templateUrl: './shares-held.component.html',
  styleUrls: ['./shares-held.component.scss'],
})
export class SharesHeldComponent implements OnInit {
  sharesHeldForCash$: Observable<number>;

  dollarValueOfSharesHeld$: Observable<number>;

  selectedAccount$: Observable<Account.Account>;

  accountIsMargin$: Observable<boolean>;

  sharesHeldForMargin$: Observable<number>;

  sharesHeldForShort$: Observable<number>;

  content: JsonContent = content;

  constructor(private readonly store: Store<TayneState>, public readonly adobeService: AdobeAnalyticsService) {}

  ngOnInit() {
    this.sharesHeldForCash$ = this.store.pipe(select(selectSharesHeldForCash));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.dollarValueOfSharesHeld$ = this.store.pipe(select(selectDollarValueOfSharesHeld));
    this.accountIsMargin$ = this.store.pipe(select(selectAccountIsMargin));
    this.sharesHeldForMargin$ = this.store.pipe(select(selectSharesHeldForMargin));
    this.sharesHeldForShort$ = this.store.pipe(select(selectSharesHeldForShort));
  }

  protected readonly timestampSizeEnum = TimestampSize;
}
