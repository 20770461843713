<c11n-modal-dialog
  #modal
  [headingText]="content.singleStockPriceBandsModal.title"
  [primaryButtonLabel]="content.labels.ok"
  maxWidth="900"
>
  <div
    c11n-modal-dialog-body
    class="singleStockPriceBandsModal"
    *ngIf="content.singleStockPriceBandsModal.body as body"
  >
    <p>{{ body.descr }}</p>

    <h4>{{ body.howRuleWork.title }}</h4>
    <p>{{ body.howRuleWork.p1 }}</p>
    <p>{{ body.howRuleWork.p2 }}</p>

    <h4>{{ body.howPriceBandsCalculated.title }}</h4>
    <p>{{ body.howPriceBandsCalculated.p1 }}</p>
    <table class="mb-3">
      <thead>
        <tr>
          <th>{{ body.howPriceBandsCalculated.table.header.securityType }}</th>
          <th>{{ body.howPriceBandsCalculated.table.header.pricePercentages }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of body.howPriceBandsCalculated.table.rows">
          <td [innerHTML]="row.securityType"></td>
          <td [innerHTML]="row.pricePercentages"></td>
        </tr>
      </tbody>
    </table>

    <h4>{{ body.whenTradingHalted.title }}</h4>
    <p>{{ body.whenTradingHalted.p1 }}</p>
    <p>{{ body.whenTradingHalted.p2 }}</p>

    <h4>{{ body.howRuleImpactDisplayQuotations.title }}</h4>
    <p>{{ body.howRuleImpactDisplayQuotations.p1 }}</p>
    <table>
      <tr>
        <td>{{ body.howRuleImpactDisplayQuotations.example.lowerPriceBand }}</td>
        <td>{{ body.howRuleImpactDisplayQuotations.example.upperPriceBand }}</td>
      </tr>
    </table>
    <p>{{ body.howRuleImpactDisplayQuotations.p2 }}</p>
    <p>{{ body.howRuleImpactDisplayQuotations.p3 }}</p>

    <h4>{{ body.whereInfoBeAvailable.title }}</h4>
    <p>
      <span>
        {{ body.whereInfoBeAvailable.p1.part1 }}
        <c11n-icon
          name="warning"
          size="small"
          scale="normal"
          class="icon-container d-inline-block align-middle"
        ></c11n-icon>
      </span>
      {{ body.whereInfoBeAvailable.p1.part2 }}
    </p>
    <p>{{ body.whereInfoBeAvailable.p2 }}</p>

    <h4>{{ body.whatMeanForMe.title }}</h4>
    <p>{{ body.whatMeanForMe.p1 }}</p>
    <ol>
      <li *ngFor="let item of body.whatMeanForMe.list">
        {{ item }}
      </li>
    </ol>

    <h4>{{ body.additionalInfo.title }}</h4>
    <p>
      {{ body.additionalInfo.p1 }}
      <a *ngIf="!isBeacon" c11n-link [attr.href]="body.additionalInfo.link" class="text-break" target="_blank">
        {{ body.additionalInfo.link }}
      </a>
      <ng-container *ngIf="isBeacon">
        {{ body.additionalInfo.link }}
      </ng-container>
    </p>
  </div>
</c11n-modal-dialog>
