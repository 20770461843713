<ng-container
  *ngIf="{
    env: (env$ | async)
  } as view"
>
  <c11n-modal-dialog
    #modal
    [headingText]="content.mutualFundErrorModal.title"
    (closeEvent)="setMarketDataIsLoading(false)"
  >
    <div c11n-modal-dialog-body>
      <div *ngIf="!isBeacon">
        <p class="c11n-space-stack-4x">{{ content.mutualFundErrorModal.body }}</p>

        <div class="anchor-group">
          <a
            c11n-link
            variant="secondary-independent"
            data-exit-dialog="no"
            href="{{
              view.env?.origin_personal
            }}/us/BuySellRouter?investmentType=MUTL&transactionType=BUY&nonRetirementMode=true{{ mfQueryParams }}"
            target="_blank"
            >{{ content.labels.buy }} {{ symbol || symbolFromRetrievalError | uppercase }}</a
          >
          <a
            c11n-link
            variant="secondary-independent"
            data-exit-dialog="no"
            href="{{
              view.env?.origin_personal
            }}/us/BuySellRouter?investmentType=MUTL&transactionType=SEL&nonRetirementMode=true{{ mfQueryParams }}"
            target="_blank"
            >{{ content.labels.sell }} {{ symbol || symbolFromRetrievalError | uppercase }}</a
          >
        </div>
      </div>

      <div *ngIf="isBeacon">
        <p class="c11n-text-md" [innerHtml]="content.mutualFundErrorModal.bodyBeacon"></p>
      </div>
    </div>
  </c11n-modal-dialog>
</ng-container>
