import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { CostBasisControlComponent } from '@app/etfs-equities/components';
import { OrderEnums, SessionStorageKeys } from '@app/etfs-equities/enums';
import { Account, JsonContent, Order, Quote } from '@app/etfs-equities/models';
import {
  AccountService,
  CrewDisclaimerService,
  KeywordSearchService,
  MarketDataService,
  OrderService,
  SessionStorageService,
  TradeTicketService,
} from '@app/etfs-equities/services';
import {
  createClearOrderAction,
  createLoadFundsAvailableAction,
  createLoadLotsAction,
  createLoadQuoteAction,
  createSetIsEditCostBasisAction,
  createValidateOrderAction,
  selectAccountIsMargin,
  selectAccountIsTradeable,
  selectAccounts,
  selectAction,
  selectActionIsBuyToCoverOrSellShort,
  selectAmountType,
  selectCostBasisTradeMethodsError,
  selectHasEnoughCashMarginShares,
  selectIsSellingAllHeldShares,
  selectOrder,
  selectOrderEveningDuration,
  selectOrigOrder,
  selectPositionForCurrentQuoteBySecurityType,
  selectQuote,
  selectQuoteIsVgETF,
  selectSelectedAccount,
  selectSharesHeld,
  selectShowNoBuyOrdersBannerForLowTierOtc,
  TayneState,
} from '@app/etfs-equities/store';
import { createSetIsChangeOrderAction } from '@app/etfs-equities/store/actions/change-order/change-order.actions';
import { selectEnvironment } from '@app/etfs-equities/store/selectors/environment/environment.selectors';
import { SharesUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { AcceptedRulesNextStep } from '@etfs-equities/enums/triggered-rule.enums';
import { selectIsIncapacitated } from '@etfs-equities/store/selectors/client-data/client-data.selectors';
import { select, Store } from '@ngrx/store';
import { CheckboxInputComponent } from '@vg-constellation/angular-18/checkbox';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { NoChangesErrorModalComponent } from './modals/change-order/no-changes-error-modal/no-changes-error-modal.component';

@Component({
  selector: 'twe-trade',
  templateUrl: './trade-page.component.html',
  styleUrls: ['./trade-page.component.scss'],
})
export class TradePageComponent implements OnInit, OnDestroy {
  AcceptedRulesNextStep = AcceptedRulesNextStep;

  @ViewChild('sellAllCheckbox')
  sellAllCheckbox: CheckboxInputComponent;

  @ViewChild(NoChangesErrorModalComponent)
  noChangesErrorModal?: NoChangesErrorModalComponent;

  @ViewChild('costBasisControl')
  costBasisControl: CostBasisControlComponent;

  //  Public observables/subjects...

  selectedAccount$: Observable<Account.Account>;

  accounts$: Observable<Account.Account[]>;

  quote$: Observable<Quote>;

  quoteIsVgEtf$: Observable<boolean>;

  volatilityBannerEnabled$: Observable<boolean>;

  dollarBasedTradesEnabled$: Observable<boolean>;

  changeOrderEnabled$: Observable<boolean>;

  sharesHeld$: Observable<number>;

  isSellingAllHeldShares$: Observable<boolean>;

  isIncapacitated$: Observable<boolean>;

  order$: Observable<Order.Order>;

  origOrder$: Observable<Order.Order>;

  selectedAction$: Observable<OrderEnums.TransactionTypes>;

  accountIsMargin$: Observable<boolean>;

  actionIsBuyToCoverOrSellShort$: Observable<boolean>;

  env$: Observable<any>;

  hasEnoughCashMarginShares$: Observable<boolean>;

  isAnnouncementBannerEnabled$: Observable<boolean>;

  selectShowNoBuyOrdersBannerForLowTierOtc$: Observable<boolean>;

  orderIsEveningDuration$: Observable<boolean>;

  amountType$: Observable<string>;

  accountIsTradeable$: Observable<boolean>;

  amountOnBlur$ = new Subject<void>();

  //  Public variables...

  content: JsonContent = content;

  isChangeOrder = false;

  hasAccountRetrievalError = false;

  orderIsModifiable = true;

  autoSelectSellAll = false;

  symbol: string;

  // Private observables/subjects...

  private readonly unsubscribe$ = new Subject<void>();

  /*
  |=====================================================================
  | Lifecycle Methods
  |=====================================================================
  */
  // eslint-disable-next-line max-params
  constructor(
    public crewDisclaimerService: CrewDisclaimerService,
    public keywordSearchService: KeywordSearchService,
    public tradeTicketService: TradeTicketService,
    public marketDataService: MarketDataService,
    public readonly adobeService: AdobeAnalyticsService,
    public accountService: AccountService,
    public orderService: OrderService,
    private readonly store: Store<TayneState>,
    private readonly route: ActivatedRoute,
    private readonly sessionStorageService: SessionStorageService,
    private readonly cdr: ChangeDetectorRef,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  // Getters/Setters...

  get formOrderId() {
    return this.tradeTicketService.tradeTicket.controls.orderId.value;
  }

  get formGroupName() {
    return this.tradeTicketService.tradeTicket;
  }

  get changeOrderHasCriticalOrderDetailsError() {
    return (
      this.isChangeOrder &&
      (this.orderService.loadOrderFailed ||
        this.hasAccountRetrievalError ||
        this.marketDataService.loadSymbolFailed ||
        this.tradeTicketService.amountTypeIsDollars())
    );
  }

  get changeOrderIsLoading() {
    return (
      this.isChangeOrder &&
      (this.orderService.isOrderLoading || this.accountService.isLoadingAccounts || this.marketDataService.isLoading)
    );
  }

  get checkboxElement() {
    return !!this.sellAllCheckbox?.elRef?.nativeElement;
  }

  ngOnInit() {
    this.clearOrderStateIfNeeded();
    this.checkToResetControlsForDayTrades();

    this.env$ = this.store.pipe(select(selectEnvironment));

    // Store selectors...
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.accounts$ = this.store.pipe(select(selectAccounts));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.quoteIsVgEtf$ = this.store.pipe(select(selectQuoteIsVgETF));
    this.sharesHeld$ = this.store.pipe(select(selectSharesHeld));
    this.isSellingAllHeldShares$ = this.store.pipe(select(selectIsSellingAllHeldShares));
    this.isIncapacitated$ = this.store.pipe(select(selectIsIncapacitated));
    this.order$ = this.store.pipe(select(selectOrder));
    this.origOrder$ = this.store.pipe(select(selectOrigOrder));
    this.selectedAction$ = this.store.pipe(select(selectAction));
    this.accountIsMargin$ = this.store.pipe(select(selectAccountIsMargin));
    this.actionIsBuyToCoverOrSellShort$ = this.store.pipe(select(selectActionIsBuyToCoverOrSellShort));
    this.hasEnoughCashMarginShares$ = this.store.pipe(select(selectHasEnoughCashMarginShares));
    this.selectShowNoBuyOrdersBannerForLowTierOtc$ = this.store.pipe(select(selectShowNoBuyOrdersBannerForLowTierOtc));
    this.orderIsEveningDuration$ = this.store.select(selectOrderEveningDuration);
    this.amountType$ = this.store.select(selectAmountType);
    this.accountIsTradeable$ = this.store.select(selectAccountIsTradeable);

    this.changeOrderEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CHANGE_ORDER);
    this.dollarBasedTradesEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );
    this.isAnnouncementBannerEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_ANNOUNCEMENTS_BANNER
    );
    this.volatilityBannerEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_VOLATILITY_BANNER
    );

    // Make sure that IsEditCostBasis flag is false for Trade Path
    this.store.dispatch(createSetIsEditCostBasisAction(false));

    // Subscriptions...
    this.watchForAutoselectSellAll();
    this.watchForAccountSelectionChanges();
    this.watchForSymbolChanges();
    this.watchForQuoteChanges();
    this.watchForVgEtfQuote();
    this.watchForChangeOrder();
    this.watchForLoadedOrder();
    this.watchForAmountTypeChanges();
    this.watchForSellAllCheckboxChanges();
    this.watchForActionChanges();
    this.watchForOrderTypeChanges();
  }

  watchForAutoselectSellAll() {
    const actionsAffectingSellAll$ = merge(
      this.isSellingAllHeldShares$.pipe(map((value) => ({ source: 'isSellingAllHeldShares$', value }))),
      this.selectedAction$.pipe(map((value) => ({ source: 'selectedAction$', value }))),
      this.amountType$.pipe(map((value) => ({ source: 'amountType$', value }))),
      this.quote$.pipe(map((value) => ({ source: 'quote$', value }))),
      this.selectedAccount$.pipe(map((value) => ({ source: 'selectedAccount$', value }))),
      this.amountOnBlur$.pipe(map((value) => ({ source: 'amountOnBlur$', value })))
    );
    actionsAffectingSellAll$
      .pipe(
        debounceTime(0),
        withLatestFrom(
          this.isSellingAllHeldShares$,
          this.selectedAction$,
          this.amountType$,
          this.sharesHeld$,
          this.dollarBasedTradesEnabled$,
          this.quoteIsVgEtf$
        ),
        tap(([latest, isSellingAllHeldShares, selectedAction, amountType, sharesHeld, dollarTrade, quoteIsVgEtf]) => {
          this.autoSelectSellAll =
            dollarTrade &&
            quoteIsVgEtf &&
            (isSellingAllHeldShares ||
              (latest.source !== 'isSellingAllHeldShares$' &&
                selectedAction === OrderEnums.TransactionTypes.SELL &&
                SharesUtil.isSellAllNumberOfShares(sharesHeld) &&
                amountType === OrderEnums.AmountTypes.SHARES));
          if (this.autoSelectSellAll) {
            this.tradeTicketService.tradeTicket.patchValue({
              sellAll: true,
              amount: String(sharesHeld),
            });

            this.tradeTicketService.setSellAllValidators();

            if (this.checkboxElement) {
              this.sellAllCheckbox.elRef.nativeElement.focus();
            }
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private clearOrderStateIfNeeded() {
    // prevents clearing the order when changing between tabs for change orders
    if (!this.formOrderId) {
      this.route.queryParamMap
        .pipe(
          map((params) => params.get('orderId')),
          withLatestFrom(this.store.select(selectOrder)),
          tap(([orderId, order]) => {
            if (order && order.orderId !== orderId) {
              this.store.dispatch(createClearOrderAction());
            }
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    }
  }

  /*
  |=====================================================================
  | Template Helpers
  |=====================================================================
  */

  resetTradeTicket = () => {
    this.tradeTicketService.clearForm();
  };

  /*
  |=====================================================================
  | Validation
  |=====================================================================
  */

  validate() {
    if (this.tradeTicketService.tradeTicket.invalid) {
      this.tradeTicketService.validateForm();
      return;
    }

    this.origOrder$
      .pipe(
        withLatestFrom(this.store.select(selectCostBasisTradeMethodsError)),
        tap(([order, costBasisMethodError]) => {
          if (this.tradeTicketService.specIdIsSelected()) {
            this.loadLotsAndContinueToSelection();
          } else if (
            this.tradeTicketService.isNullSpecIdForNotMarketOrder() &&
            !costBasisMethodError &&
            this.costBasisControl
          ) {
            this.costBasisControl.loadCostBasisMethods();
          } else if (this.isChangeOrder && this.tradeTicketService.isOrderEqualTradeTicketForm(order)) {
            this.noChangesErrorModal.modal.openModalDialog();
          } else {
            this.store.dispatch(createValidateOrderAction(this.isChangeOrder));
          }
        }),
        take(1)
      )
      .subscribe();
  }

  loadLotsAndContinueToSelection() {
    combineLatest([this.selectedAccount$, this.store.pipe(select(selectPositionForCurrentQuoteBySecurityType))])
      .pipe(take(1))
      .subscribe(([account, holding]) => {
        this.store.dispatch(
          createLoadLotsAction(
            account.accountId,
            holding.positionId,
            this.tradeTicketService.tradeTicket.controls.orderId.value,
            this.tradeTicketService.tradeTicket.controls.action.value
          )
        );
      });
  }

  setHasAccountRetrievalError(hasError: boolean) {
    this.hasAccountRetrievalError = hasError;
  }

  isAnnouncementBannerVisible(isAnnouncementBannerEnabled: boolean): boolean {
    return (
      isAnnouncementBannerEnabled &&
      !this.sessionStorageService.get(SessionStorageKeys.HAS_DISMISSED_ANNOUNCEMENT_BANNER)
    );
  }

  closeAnnouncementBanner(): void {
    this.sessionStorageService.set(SessionStorageKeys.HAS_DISMISSED_ANNOUNCEMENT_BANNER, true);
  }

  /*
  |=====================================================================
  | Private Helpers
  |=====================================================================
  */

  private readonly resetSellAll = () => {
    if (
      (!this.autoSelectSellAll || !this.tradeTicketService.orderActionIsSell()) &&
      this.tradeTicketService.isSellAllChecked()
    ) {
      this.tradeTicketService.uncheckSellAll();
    }
  };

  resetOrderTypeAndSetSharesValidators() {
    this.tradeTicketService.setOrderType(null);
    this.tradeTicketService.tradeTicket.controls.orderType.markAsPristine();
    this.tradeTicketService.setSharesValidators();
  }

  /*
  |=====================================================================
  | Subscriptions
  |=====================================================================
  */

  private watchForSymbolChanges() {
    this.tradeTicketService.tradeTicket.controls.symbol.valueChanges
      .pipe(
        // and uncheck sellAll checkbox if checked
        tap(() => {
          this.resetSellAll();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForAmountTypeChanges() {
    this.tradeTicketService.tradeTicket.controls.amountType.valueChanges
      .pipe(
        // Clear Amount input.
        tap((amountType) => {
          this.tradeTicketService.setAmount(null);
          this.tradeTicketService.tradeTicket.controls.amount.markAsPristine();
          switch (amountType) {
            case OrderEnums.AmountTypes.SHARES:
              this.tradeTicketService.setOrderType(null);
              this.tradeTicketService.tradeTicket.controls.orderType.markAsPristine();
              this.tradeTicketService.setSharesValidators();
              break;
            case OrderEnums.AmountTypes.DOLLARS:
              this.tradeTicketService.setOrderType(OrderEnums.Types.MARKET);
              this.tradeTicketService.setDollarsValidators();
              this.autoSelectSellAll = false;
              this.tradeTicketService.tradeTicket.controls.sellAll.setValue(false, {
                emitEvent: false,
                onlySelf: true,
              });
              break;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForAccountSelectionChanges() {
    this.tradeTicketService.tradeTicket.controls.brokerageAccountNumber.valueChanges
      .pipe(
        // Uncheck the sell all checkbox if the user is moving to another account
        tap(() => {
          this.resetSellAll();
          this.tradeTicketService.validateAmountControl();
        }),

        // Do not proceed if the field was cleared.
        filter((brokerageAccountNumber) => brokerageAccountNumber !== null),

        // The funds available action needs the accountId (not brokerageAccountNumber),
        // so lets switch to the selected account so that we have access to the ID.
        switchMap(() => this.selectedAccount$.pipe(take(1))),

        // Dispatch the action to update the funds available.
        tap((selectedAccount) => this.store.dispatch(createLoadFundsAvailableAction(selectedAccount.accountId))),

        // Check to determine if crew disclaimer flag should display due to account selection change
        // If a crew member can transact on the account, we must show the disclaimer
        tap((selectedAccount) =>
          this.crewDisclaimerService.fetchCrewDisclaimerFlag(selectedAccount.accountId).subscribe()
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForQuoteChanges() {
    this.quote$
      .pipe(
        tap(() => this.tradeTicketService.validateAmountControl()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForVgEtfQuote() {
    this.quoteIsVgEtf$
      .pipe(
        withLatestFrom(this.dollarBasedTradesEnabled$),
        filter(
          ([quoteIsVgETF, dollarBasedTradesEnabled]) =>
            dollarBasedTradesEnabled && !quoteIsVgETF && this.tradeTicketService.amountTypeIsDollars()
        ),
        tap(() => {
          this.tradeTicketService.setAmount(null);
          this.tradeTicketService.setAmountType(OrderEnums.AmountTypes.SHARES);
          this.tradeTicketService.tradeTicket.controls.amount.markAsPristine();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForSellAllCheckboxChanges() {
    this.tradeTicketService.tradeTicket.controls.sellAll.valueChanges
      .pipe(
        withLatestFrom(this.sharesHeld$),
        tap(([checked, sharesHeld]) => {
          if (!this.tradeTicketService.amountTypeIsShares()) {
            this.tradeTicketService.setAmountType(OrderEnums.AmountTypes.SHARES, false);
            this.resetOrderTypeAndSetSharesValidators();
          }
          if (checked) {
            this.tradeTicketService.setSellAllValidators();
            this.tradeTicketService.tradeTicket.controls.amount.markAsTouched();
            this.tradeTicketService.tradeTicket.controls.amount.markAsDirty();
            this.tradeTicketService.tradeTicket.controls.brokerageAccountNumber.markAsTouched();
            this.tradeTicketService.tradeTicket.controls.brokerageAccountNumber.markAsDirty();
            this.tradeTicketService.tradeTicket.controls.symbol.markAsTouched();
            this.tradeTicketService.tradeTicket.controls.symbol.markAsDirty();
            if (SharesUtil.isSellAllMarketNumberOfShares(sharesHeld)) {
              this.tradeTicketService.setOrderType(OrderEnums.Types.MARKET);
            }

            this.tradeTicketService.setAmount(sharesHeld);
          } else {
            this.tradeTicketService.resetOnlyTheFollowingControls(['amount', 'orderType', 'duration']);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForActionChanges() {
    this.tradeTicketService.tradeTicket.controls.action.valueChanges
      .pipe(
        tap((action) => {
          this.resetSellAll();

          if (action) {
            this.tradeTicketService.resetEverythingBut([
              'symbol',
              'action',
              'brokerageAccountNumber',
              'accountId',
              'isCostBasisEligible',
              'hasQuote',
            ]);
            this.adobeService.sendAdobeLaunchProcess(`equityETFTicket-capture: ${action}`);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForChangeOrder() {
    this.route.queryParamMap
      .pipe(
        map((params) => [params.get('accountId'), params.get('orderId')]),
        withLatestFrom(this.changeOrderEnabled$),
        tap(([[accountId, orderId], changeOrderEnabled]) => {
          this.isChangeOrder = changeOrderEnabled && !!accountId && !!orderId;
          this.store.dispatch(createSetIsChangeOrderAction(this.isChangeOrder));
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForLoadedOrder() {
    this.origOrder$
      .pipe(
        filter((order) => !!order && this.isChangeOrder && this.formOrderId !== order.orderId),
        tap((order: Order.Order) => {
          this.orderIsModifiable = order.parentOrderStatus === OrderEnums.ParentOrderStatus.OPEN;

          if (order.ticker) {
            this.store.dispatch(createLoadQuoteAction(order.ticker));
          }
          this.tradeTicketService.updateChangeOrderTicketForm(order);

          this.adobeService.sendAdobeLaunchProcess(`equityETFTicket-capture: ${order.transactionType}`);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForOrderTypeChanges() {
    this.tradeTicketService.tradeTicket.controls.orderType.valueChanges
      .pipe(
        tap(() => this.cdr.detectChanges()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private checkToResetControlsForDayTrades() {
    if (
      this.tradeTicketService.tradeTicket.controls.duration.value === OrderEnums.Durations.EVENING &&
      !this.isChangeOrder
    ) {
      this.tradeTicketService.resetOrderFieldsOnTabSwitch();
    }
  }
}
