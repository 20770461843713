<ng-container
  *ngIf="{
    isIncapacitated: (isIncapacitated$ | async),
    isScreenMediumOrLess: (isScreenMediumOrLess$ | async),
  } as view"
>
  <div class="twe-trade-buttons">
    <c11n-button
      #previewButton
      [attr.data-testid]="isExtendedTrading ? 'btn-ext-trade-preview-order' : 'btn-trade-preview-order'"
      buttonType="button"
      size="medium"
      variant="primary"
      [fullWidth]="view.isScreenMediumOrLess"
      [labelText]="
        tradeTicketService?.specIdIsSelected()
          ? content.labels.selectShares
          : isChangeOrder
          ? content.labels.previewChangeOrder
          : content.labels.previewOrder
      "
      [isWaiting]="costBasisService.isLoadingLots"
      [isInvalid]="view.isIncapacitated"
      (clickEvent)="handlePreviewBtnClick()"
    ></c11n-button>

    <c11n-button
      [attr.data-testid]="
        isChangeOrder
          ? 'btn-trade-keep-order'
          : isExtendedTrading
          ? 'btn-ext-trade-clear-ticket'
          : 'btn-trade-clear-ticket'
      "
      class="pt-4 pt-lg-0 ps-lg-3"
      buttonType="button"
      size="medium"
      variant="secondary"
      [fullWidth]="view.isScreenMediumOrLess"
      [labelText]="isChangeOrder ? content.labels.keepOrder : content.labels.clearTicket"
      [isInvalid]="view.isIncapacitated"
      (clickEvent)="handleActionBtnClick()"
    ></c11n-button>
  </div>
</ng-container>
