<c11n-modal-dialog
  #modal
  [headingText]="content.tipsForTradingVolatilityModal.title"
  [primaryButtonLabel]="content.labels.ok"
>
  <div c11n-modal-dialog-body>
    <p>{{ content.tipsForTradingVolatilityModal.body }}</p>
    <ol>
      <li>
        <p class="c11n-text-md-bold c11n-space-stack-1x">{{ content.tipsForTradingVolatilityModal.subtitle1 }}</p>
        <p>
          {{ content.tipsForTradingVolatilityModal.text1 }}
          <button c11n-link variant="primary-reinforced" (click)="openOrderTypesModal($event)">
            {{ content.tipsForTradingVolatilityModal.text1Link }}
          </button>
        </p>
      </li>
      <li>
        <p class="c11n-text-md-bold c11n-space-stack-1x">{{ content.tipsForTradingVolatilityModal.subtitle2 }}</p>
        <p>{{ content.tipsForTradingVolatilityModal.text2 }}</p>
      </li>
      <li>
        <p class="c11n-text-md-bold c11n-space-stack-1x">{{ content.tipsForTradingVolatilityModal.subtitle3 }}</p>
        <p>{{ content.tipsForTradingVolatilityModal.text3 }}</p>
      </li>
    </ol>
    <p class="c11n-text-sm">{{ content.tipsForTradingVolatilityModal.legalText }}</p>

    <a
      *ngIf="!isBeacon"
      c11n-link
      variant="primary-reinforced"
      [href]="content.tipsForTradingVolatilityModal.learnMoreLink"
      target="_blank"
    >
      {{ content.tipsForTradingVolatilityModal.learnMoreLinkText }}
    </a>
  </div>
</c11n-modal-dialog>

<twe-order-types-modal #orderTypesModal></twe-order-types-modal>
