import { cloneDeep } from 'lodash';

import {
  AccountActionTypes,
  AccountActionTypesUnion,
  LoadAccountsSuccessAction,
  RefreshSelectedAccountAction,
  SelectAccountAction,
  SetExtendedTradeAgreementAction,
} from '../../actions';
import { AccountState, initialAccountState } from '../../states';

const selectAccountState = (action: SelectAccountAction, state: AccountState) => {
  const selectedAccount = action.payload
    ? state.accounts.find((a) => a.brokerageAccountNumber === action.payload)
    : null;
  return { ...state, selectedAccount: selectedAccount ? { ...selectedAccount } : null };
};

const loadAccountsSuccessState = (action: LoadAccountsSuccessAction, state: AccountState) => {
  const accounts = action.payload.accounts.map((account) => {
    const mappedAccount = cloneDeep(account);
    mappedAccount.errors = action.payload.errors;
    return mappedAccount;
  });

  return { ...state, accounts };
};

const refreshSelectedAccountState = (action: RefreshSelectedAccountAction, state: AccountState) => {
  const selectedAccount = action.payload.accounts.find(
    (account) => account.accountId === state.selectedAccount.accountId
  );
  return { ...state, selectedAccount: { ...selectedAccount } };
};

const setExtendedTradeAgreementState = (action: SetExtendedTradeAgreementAction, state: AccountState) => {
  return { ...state, extendedTradeAgreement: action.payload };
};

export const accountReducer = (
  state: AccountState = initialAccountState,
  action: AccountActionTypesUnion
): AccountState => {
  switch (action.type) {
    case AccountActionTypes.LOAD_ACCOUNTS_SUCCESS:
      return loadAccountsSuccessState(action, state);

    case AccountActionTypes.REFRESH_SELECTED_ACCOUNT:
      return refreshSelectedAccountState(action, state);

    case AccountActionTypes.SELECT_ACCOUNT:
      return selectAccountState(action, state);

    case AccountActionTypes.SET_EXTENDED_TRADE_AGREEMENT:
      return setExtendedTradeAgreementState(action, state);

    default:
      return state;
  }
};
