export enum HoldingFilterStatesEnum {
  ALL = 'all',
  ETFS = 'etfs',
  STOCKS = 'stocks',
  MUTUAL_FUNDS = 'mutualFunds',
  BONDS = 'bonds',
  OPTIONS = 'options',
  CDS = 'cds',
}

export enum HoldingTypeEnum {
  ETF = 'ETF',
  STOCK = 'STOCK',
  MUTUAL_FUND = 'MUTUAL_FUND',
  BOND = 'BOND',
  OPTION = 'OPTION',
  CD = 'CD',
  OTHER = 'OTHER',
}
