import { OrderEnums } from '@app/etfs-equities/enums';
import { CostBasis, Order, TradeTicketForm } from '@app/etfs-equities/models';
import { RehydratableFormGroup } from '@vanguard/trade-standard-forms-lib-ng-18';
import {
  CostBasisMethod,
  DenominationType,
  LotTableRowControls,
  parseNumberFromFormControlValue,
} from '@vanguard/trade-ui-components-lib-ng-18';
import { AnyMaskedOptions } from 'imask';
import { floor } from 'lodash';

export class CostBasisUtil {
  // We do not currently support all possible cost basis method codes.
  // Method codes other than those listed below will be ignored.
  static approvedMethods: CostBasisMethod[] = [
    CostBasisMethod.AVG_COST,
    CostBasisMethod.FIFO,
    CostBasisMethod.HIFO,
    CostBasisMethod.SPEC_ID,
    CostBasisMethod.MIN_TAX,
  ];

  // The original intent here was to try to help limit the number of actions being
  // dispatched to update the trade ticket value in the store. This may be less
  // of an issue with the current codebase, but it probably doesn't hurt either.
  static onlySharesOrNothingChanged(prev: CostBasis.AutoSelectSnapshot, curr: CostBasis.AutoSelectSnapshot): boolean {
    return (
      prev &&
      curr &&
      prev.isChangeOrder === curr.isChangeOrder &&
      prev.isCostBasisEligible === curr.isCostBasisEligible &&
      prev.costBasisMethod === curr.costBasisMethod &&
      prev.transactionType === curr.transactionType
    );
  }

  static isSellingAllHeldShares(snapshot: CostBasis.AutoSelectSnapshot): boolean {
    return (
      snapshot.sharesHeld > 0 &&
      (snapshot.sharesHeld === snapshot.sharesRequested ||
        floor(snapshot.sharesHeld) === Number(snapshot.sharesRequested))
    );
  }

  // The cost basis method should be cleared under any of the following conditions:
  // - The trade is a buy
  // - The account is a retirement account
  // - The client's default method is not supported in this path
  static methodShouldBeCleared(snapshot: CostBasis.AutoSelectSnapshot): boolean {
    return (
      snapshot.transactionType === OrderEnums.TransactionTypes.BUY ||
      snapshot.transactionType === OrderEnums.TransactionTypes.SELL_SHORT ||
      !snapshot.isCostBasisEligible ||
      CostBasisUtil.methodIsNotSupported(snapshot.costBasisMethod)
    );
  }

  static methodIsAvgCost(method: CostBasisMethod): boolean {
    return method === CostBasisMethod.AVG_COST;
  }

  static methodIsHifo(method: CostBasisMethod): boolean {
    return method === CostBasisMethod.HIFO;
  }

  static methodIsSpecId(method: CostBasisMethod): boolean {
    return method === CostBasisMethod.SPEC_ID;
  }

  static methodIsNotSupported(method: CostBasisMethod) {
    return CostBasisUtil.approvedMethods.indexOf(method) === -1 && method !== null;
  }

  static methodIsSupported(method: CostBasisMethod) {
    return !CostBasisUtil.methodIsNotSupported(method);
  }

  // Autoselect FIFO if the client is selling all held shares as long
  // as the default method they've selected is not average cost or HFIO.
  static methodMustBeFifo(snapshot: CostBasis.AutoSelectSnapshot) {
    return (
      (CostBasisUtil.isSellingAllHeldShares(snapshot) || snapshot.amountType === OrderEnums.AmountTypes.DOLLARS) &&
      !CostBasisUtil.methodShouldBeCleared(snapshot) &&
      !CostBasisUtil.methodIsAvgCost(snapshot.costBasisMethod) &&
      !CostBasisUtil.methodIsHifo(snapshot.costBasisMethod)
    );
  }

  // The cost basis method should be updated if shares isn't the only value
  // that has changed since the last stream UNLESS the client is selling
  // all held shares AND the method is not avg cost.
  static methodShouldBeUpdated(prev: CostBasis.AutoSelectSnapshot, curr: CostBasis.AutoSelectSnapshot): boolean {
    /*
     for change order it should not be auto updated unless the
     method selected for the order is not valid anymore
     e.g order is SpecID but user changed it to sell all shares
     */
    const changeOrderSpecialCase = !curr.isChangeOrder || (curr.isChangeOrder && CostBasisUtil.methodMustBeFifo(curr));

    return (
      changeOrderSpecialCase &&
      (!CostBasisUtil.onlySharesOrNothingChanged(prev, curr) ||
        (curr.costBasisMethod !== curr.costBasisFormValue && this.methodMustBeFifo(curr)) ||
        (CostBasisUtil.isSellingAllHeldShares(curr) && !CostBasisUtil.methodIsAvgCost(curr.costBasisMethod)))
    );
  }

  static makeSelectedLotsFromLotForm(lots: CostBasis.Lot[], lotForm: { [key: string]: any }) {
    const selectedLots: CostBasis.SelectedLot[] = [];
    lots.forEach((lot) => {
      if (lotForm[lot.lotID] && lotForm[lot.lotID] > 0) {
        selectedLots.push({
          ...lot,
          selectedLotQuantity: Number(lotForm[lot.lotID]),
        });
      }
    });
    return selectedLots;
  }

  static makeSelectedLotsFromLotFormGroups(
    lots: CostBasis.Lot[],
    lotForms: RehydratableFormGroup<LotTableRowControls>[]
  ) {
    const selectedLots: CostBasis.SelectedLot[] = [];
    lots.forEach((lot) => {
      const selectedLotFormGroup = lotForms.find((form) => form.formGroupID === lot.lotID);
      if (
        selectedLotFormGroup &&
        CostBasisUtil.formatLotShares(selectedLotFormGroup.controls.numberOfSharesSelected.value) > 0
      ) {
        selectedLots.push({
          ...lot,
          selectedLotQuantity: CostBasisUtil.formatLotShares(
            selectedLotFormGroup.controls.numberOfSharesSelected.value
          ),
        });
      }
    });
    return selectedLots;
  }

  static makeLotsFormLotsGroups(lotForms: RehydratableFormGroup<LotTableRowControls>[]) {
    const mappedForm = {};

    lotForms.forEach((form) => {
      const scrubbedValue = CostBasisUtil.formatLotShares(form.controls.numberOfSharesSelected.value);
      if (scrubbedValue) {
        mappedForm[form.formGroupID] = CostBasisUtil.formatLotQuantity(scrubbedValue);
      }
    });

    return mappedForm;
  }

  // Return an object containing lot IDs (properties) and their trade quantities (values). For lots that are contained within
  // rolled-up lots, we need to sum the component lot quantities and return sum with the rolled-up lot ID.
  static makeLotFormDataFromOrder(order: Order.Order, costBasisLots: CostBasis.Lot[]): { [key: string]: string } {
    // build a map of lotIDs to their matching ID in the specid lot form (which could be their rollup ID)
    const idMap: { [lotId: string]: string } = {}; // map of IDs to rollup IDs
    const lots: { [lotId: string]: number } = {}; // will hold totaled lot quantities
    order.lotIds.forEach((orderLotId) => {
      idMap[orderLotId.lotId] = orderLotId.lotId;
      lots[orderLotId.lotId] = 0;
    });
    costBasisLots.forEach((costBasisLot) => {
      if (costBasisLot.rollUp) {
        costBasisLot.rolledUpLots.forEach((rolledUpLot) => {
          if (!!idMap[rolledUpLot.lotID]) {
            idMap[rolledUpLot.lotID] = costBasisLot.lotID;
            lots[costBasisLot.lotID] = 0;
          }
        });
      }
    });

    // now iterate through the lots in the order and collect the quantities, summing up by rollup ID
    order.lotIds.forEach((orderLotId) => (lots[idMap[orderLotId.lotId]] += Number(orderLotId.tradeQuantity)));
    // reformat the quantities and return the result
    const orderLots = {};
    Object.entries(lots).forEach(([lotId, value]) => {
      if (value > 0) {
        orderLots[lotId] = CostBasisUtil.formatLotQuantity(value);
      }
    });
    return orderLots;
  }

  static formatLotQuantity(q: any): string {
    return Number(q).toFixed(4);
  }

  /**
   * A method to check if the method should be changed to FIFO.
   *
   * @param {CostBasisMethod} method - The current cost basis method.
   * @param {OrderEnums.AmountTypes} amountType - The amount type of the order.
   * @param {boolean} isSellAll - A boolean value to determine if the user is selling all held shares.
   * @returns {method: CostBasisMethod; methodChanged?: boolean}
   */
  static checkAndChangeMethodToFIFO(
    method: CostBasisMethod | null,
    amountType: OrderEnums.AmountTypes,
    isSellAll: boolean
  ): { method: CostBasisMethod; methodChanged?: boolean } {
    // If the method is SPEC_ID and the amount type is DOLLARS, or the user is selling all
    // held shares, then return method as FIFO and set methodChanged to true.
    if (method === CostBasisMethod.SPEC_ID && (amountType === OrderEnums.AmountTypes.DOLLARS || isSellAll)) {
      return { method: CostBasisMethod.FIFO, methodChanged: true };
    }

    // return the method without any changes and set methodChanged to false.
    return { method, methodChanged: false };
  }

  /**
   * A method to determine the cost basis method to be set to the trade ticket.
   *
   * @param {boolean} isChangeOrder - A boolean value to determine if the order is a change order.
   * @param {Object} method
   * @param {CostBasisMethod} method.fromTicket - The cost basis method from the trade ticket.
   * @param {CostBasisMethod} method.fromPosition - The cost basis method from the position.
   * @param {CostBasisMethod} method.fromOrder - The cost basis method from the order.
   *
   * @returns {CostBasisMethod | null}
   */
  static getCostBasisMethod(
    isChangeOrder: boolean,
    method: { [key in 'fromTicket' | 'fromPosition' | 'fromOrder']: CostBasisMethod | null }
  ): CostBasisMethod | null {
    if (isChangeOrder && !method.fromTicket) {
      return method.fromOrder;
    } else if (method.fromPosition && !method.fromTicket) {
      return method.fromPosition;
    } else {
      return method.fromTicket;
    }
  }

  /**
   * Scrubs the lot shares value and parses it into a number.
   *
   * @static
   * @param {string} lotValue - The lot shares value to scrub out commas and parse.
   * @returns {number} The parsed number from the form control value.
   */
  static formatLotShares(lotValue: string): number {
    if (!lotValue) return 0;

    const imaskConfig: AnyMaskedOptions = {
      mask: Number,
      signed: true,
      scale: 4,
      padFractionalZeros: true,
      radix: '.',
      thousandsSeparator: ',',
    };

    return parseNumberFromFormControlValue(lotValue, DenominationType.SHARES, imaskConfig);
  }

  /**
   * Calculate the estimated proceeds based on the trade ticket and lot.
   *
   * @param {TradeTicketForm} tradeTicket - The trade ticket form.
   * @param {CostBasis.Lot} lot - The lot to calculate the estimated proceeds.
   * @returns {number | null} The estimated proceeds.
   */
  static calculateEstimatedProceeds(tradeTicket: TradeTicketForm, lot: CostBasis.Lot) {
    if (!tradeTicket) {
      return null;
    }
    switch (tradeTicket.orderType) {
      case OrderEnums.Types.LIMIT:
      case OrderEnums.Types.STOP_LIMIT:
        return parseFloat(tradeTicket.limitPrice) * lot.quantity;
      case OrderEnums.Types.STOP:
        return parseFloat(tradeTicket.stopPrice) * lot.quantity;
      default:
        return lot.marketValue;
    }
  }
}
