import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import content from '@content/content.json';
import { AdobeAnalyticsService } from '@core/services';
import { OrderEnums } from '@etfs-equities/enums';
import { JsonContent, Quote } from '@etfs-equities/models';
import { TradeTicketService } from '@etfs-equities/services';
import {
  selectIsClientBuyingHaltedStock,
  selectOrderEveningDuration,
  selectOrderType,
  selectQuote,
  selectShowLowLiquidityHint,
  TayneState,
} from '@etfs-equities/store';
import { select, Store } from '@ngrx/store';
import { SegmentedControlComponent } from '@vg-constellation/angular-18/segmented-control';
import { combineLatest, filter, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-order-type-control',
  templateUrl: './order-type-control.component.html',
  styleUrls: ['./order-type-control.component.scss'],
})
export class OrderTypeControlComponent implements OnInit, OnDestroy {
  @ViewChild('orderTypeSegmentedControl')
  orderTypeSegmentedControl: SegmentedControlComponent;

  //  Public variables...

  content: JsonContent | undefined = content;

  orderTypes = OrderEnums.Types;

  quote$: Observable<Quote>;

  selectShowLowLiquidityHint$: Observable<boolean>;

  isClientBuyingHaltedStock$: Observable<boolean>;

  orderIsEveningDuration$: Observable<boolean>;

  private readonly unsubscribe$ = new Subject<void>();

  orderTypeDropdownOptions: { value: string; label: string }[] = [
    { value: OrderEnums.Types.MARKET, label: content.labels.market },
    { value: OrderEnums.Types.LIMIT, label: content.labels.limit },
    { value: OrderEnums.Types.STOP, label: content.labels.stop },
    { value: OrderEnums.Types.STOP_LIMIT, label: content.labels.stopLimit },
  ];

  constructor(
    public tradeTicketService: TradeTicketService,
    public adobeService: AdobeAnalyticsService,
    private readonly store: Store<TayneState>
  ) {}

  get orderTypeValue() {
    return this.tradeTicketService.orderType() ?? '';
  }

  ngOnInit() {
    this.quote$ = this.store.pipe(select(selectQuote));

    this.selectShowLowLiquidityHint$ = this.store.pipe(select(selectShowLowLiquidityHint));
    this.isClientBuyingHaltedStock$ = this.store.pipe(select(selectIsClientBuyingHaltedStock));
    this.orderIsEveningDuration$ = this.store.select(selectOrderEveningDuration);

    this.watchForClientBuyingHaltedStock();
    this.watchForLimitPresetConditions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private watchForClientBuyingHaltedStock() {
    this.isClientBuyingHaltedStock$
      .pipe(
        tap((isClientBuyingHaltedStock) => {
          if (isClientBuyingHaltedStock) {
            this.tradeTicketService.setOrderType(OrderEnums.Types.LIMIT);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForLimitPresetConditions() {
    const { Types } = OrderEnums;

    combineLatest([this.quote$, this.store.select(selectOrderType)])
      .pipe(
        withLatestFrom(this.orderIsEveningDuration$),
        filter(
          ([[quote], durationIsEvening]) => this.orderTypeValue !== Types.LIMIT && (quote?.isOtc || durationIsEvening)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.tradeTicketService.setOrderType(Types.LIMIT);
      });
  }

  getOrderTypeContent() {
    return this.content?.labels[this.orderTypeValue.toLowerCase()] || this.orderTypeValue;
  }

  syncOrderTypeWithSegmentControl() {
    // update view for segment control in the end of events chain
    setTimeout(() => {
      this.orderTypeSegmentedControl.handleStateChange();
    }, 0);
  }
}
