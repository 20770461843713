import { SecurityClassTypes } from '@etfs-equities/enums';
import {
  TweOpenOrdersOrderCategoryTypesEnum,
  TweOpenOrdersOrderDurationTypesEnum,
  TweOpenOrdersOrderInstructionCodeEnum,
  TweOpenOrdersOrderStatusCodeTypesEnum,
  TweOpenOrdersOrderStatusTypesEnum,
  TweOpenOrderStatusDisplayText,
} from '@etfs-equities/enums/open-orders/open-orders.enum';
import { AmountIndicators } from '@etfs-equities/enums/order.enums';
import { BrokerageNumberToAccountIdMap, OpenOrder } from '@etfs-equities/models';
import {
  SecurityDetailsSecurityTypeEnum,
  VgaOrdersResponseV2,
  VgaOrdersResponseV2OrderDurationEnum,
  VgaOrdersResponseV2OrderStatusDetailEnum,
  VgaOrdersResponseV2OrderStatusEnum,
  VgaOrdersResponseV2OrderTypeEnum,
  VgaOrdersResponseV2RequestedAmountTypeEnum,
  VgaOrdersResponseV2TransactionTypeEnum,
} from '@vanguard/invest-api-client-typescript-axios';

import { OrderMappingUtil } from '../order-mapping/order-mapping.util';

// Provides methods for mapping from the VGA to the TWE OpenOrder data model.
// (For historical reasons the OpenOrder and Order models are different types.)
export class OpenOrderMappingUtil {
  static mapToTweOpenOrder(orders: VgaOrdersResponseV2[], acctIdMap: BrokerageNumberToAccountIdMap): OpenOrder[] {
    return orders.map((order) => ({
      accountId: acctIdMap[order.brokerageAccountNumber],
      amountIndicator: OpenOrderMappingUtil.mapAmountIndicator(order),
      tradeDate: order.tradeDate,
      orderNumber: order.orderId,
      statusDetail: OpenOrderMappingUtil.mapStatusDetail(order),
      statusDetailText: OpenOrderMappingUtil.mapStatusDetailText(order),
      ticker: order.security.tickerSymbol,
      cusip: order.security?.cusip,
      orderStatusCode: OpenOrderMappingUtil.mapOrderStatusCode(order),
      securityType: OpenOrderMappingUtil.mapSecurityType(order),
      securityDescription: order.security?.securityDescription,
      orderInstructionCode: OpenOrderMappingUtil.mapTransactionType(order),
      allOrNone: OrderMappingUtil.mapBooleanToString(!!order.allOrNone),
      originalShareQuantity: order.requestedAmount,
      orderValue: OpenOrderMappingUtil.mapOrderValue(order),
      orderSecurityType: order.security?.securityType?.valueOf(),
      executedShareQuantity: order.executedShareQuantity,
      remainingQuantity: order.remainingShareQuantity,
      orderCategory: OpenOrderMappingUtil.mapOrderCategory(order),
      stopLimitPrice: OpenOrderMappingUtil.mapStopLimitPrice(order),
      price: OpenOrderMappingUtil.mapPrice(order),
      executedPrice: order.executedPrice,
      orderDuration: OpenOrderMappingUtil.mapOrderDuration(order),
      settlementDate: order.goodTillDate || order.settlementDate,
      enteredTimeStamp: order.initiatedDateTime,
      businessOrigin: {
        businessWorkStream: order.businessOrigin?.workStream,
      },
      costBasisMethod:
        // JT9 will provide a costBasisMethod for orders other than SELL/BTC, so we filter them out here
        order.transactionType === VgaOrdersResponseV2TransactionTypeEnum.SELL ||
        order.transactionType === VgaOrdersResponseV2TransactionTypeEnum.BUY_TO_COVER
          ? OrderMappingUtil.mapCostBasisMethod(order.costBasisDetails?.costBasisMethod)
          : undefined,
      securityAccountType: order.accountType.valueOf(),
      postingCode: OrderMappingUtil.mapBooleanToString(!!order.filesOnly),
      orderSource: order.businessOrigin?.channelType,
    }));
  }

  private static mapAmountIndicator(order: VgaOrdersResponseV2) {
    switch (order.requestedAmountType) {
      case VgaOrdersResponseV2RequestedAmountTypeEnum.DOLLARS:
        return OrderMappingUtil.isMutualFund(order.security?.securityType) ? 'DOLS' : AmountIndicators.DOLLARS;
      case VgaOrdersResponseV2RequestedAmountTypeEnum.SHARES:
        return AmountIndicators.SHARES;
      default:
        return undefined;
    }
  }

  private static mapStatusDetail(order: VgaOrdersResponseV2): TweOpenOrdersOrderStatusTypesEnum {
    switch (order.orderStatusDetail) {
      case VgaOrdersResponseV2OrderStatusDetailEnum.OPEN:
        return TweOpenOrdersOrderStatusTypesEnum.OPEN;
      case VgaOrdersResponseV2OrderStatusDetailEnum.IN_PROGRESS:
        return TweOpenOrdersOrderStatusTypesEnum.IN_PROGRESS;
      case VgaOrdersResponseV2OrderStatusDetailEnum.ENTERED:
        return TweOpenOrdersOrderStatusTypesEnum.ENTERED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.EXPIRED:
        return TweOpenOrdersOrderStatusTypesEnum.EXPIRED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.EXECUTED:
        return TweOpenOrdersOrderStatusTypesEnum.EXECUTED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.CANCELED:
        return TweOpenOrdersOrderStatusTypesEnum.CANCELED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.DROPPED:
        return TweOpenOrdersOrderStatusTypesEnum.DROPPED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.REJECTED:
        return TweOpenOrdersOrderStatusTypesEnum.REJECTED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PARTIAL_CANCEL:
        return TweOpenOrdersOrderStatusTypesEnum.PARTIAL_CANCEL;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PARTIAL_EXECUTION:
        return TweOpenOrdersOrderStatusTypesEnum.PARTIAL_EXECUTION;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING_CANCEL:
        return TweOpenOrdersOrderStatusTypesEnum.PENDING_CANCEL;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING_CHANGE:
        return TweOpenOrdersOrderStatusTypesEnum.PENDING_CHANGE;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING:
        return TweOpenOrdersOrderStatusTypesEnum.PENDING;
      case VgaOrdersResponseV2OrderStatusDetailEnum.COMPLETE:
        return TweOpenOrdersOrderStatusTypesEnum.COMPLETE;
      case VgaOrdersResponseV2OrderStatusDetailEnum.UNKNOWN:
        return TweOpenOrdersOrderStatusTypesEnum.UNKNOWN;
      default:
        return undefined;
    }
  }

  private static mapStatusDetailText(order: VgaOrdersResponseV2): TweOpenOrderStatusDisplayText {
    switch (order.orderStatusDetail) {
      case VgaOrdersResponseV2OrderStatusDetailEnum.OPEN:
        return TweOpenOrderStatusDisplayText.OPEN;
      case VgaOrdersResponseV2OrderStatusDetailEnum.IN_PROGRESS:
        return TweOpenOrderStatusDisplayText.IN_PROGRESS;
      case VgaOrdersResponseV2OrderStatusDetailEnum.ENTERED:
        return TweOpenOrderStatusDisplayText.ENTERED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.EXPIRED:
        return TweOpenOrderStatusDisplayText.EXPIRED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.EXECUTED:
        return TweOpenOrderStatusDisplayText.EXECUTED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.CANCELED:
        return TweOpenOrderStatusDisplayText.CANCELED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.DROPPED:
        return TweOpenOrderStatusDisplayText.DROPPED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.REJECTED:
        return TweOpenOrderStatusDisplayText.REJECTED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PARTIAL_CANCEL:
        return TweOpenOrderStatusDisplayText.PARTIAL_CANCEL;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PARTIAL_EXECUTION:
        return TweOpenOrderStatusDisplayText.PARTIAL_EXECUTION;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING_CANCEL:
        return TweOpenOrderStatusDisplayText.PENDING_CANCEL;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING_CHANGE:
        return TweOpenOrderStatusDisplayText.PENDING_CHANGE;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING:
        return TweOpenOrderStatusDisplayText.PENDING;
      case VgaOrdersResponseV2OrderStatusDetailEnum.COMPLETE:
        return TweOpenOrderStatusDisplayText.COMPLETE;
      case VgaOrdersResponseV2OrderStatusDetailEnum.UNKNOWN:
        return TweOpenOrderStatusDisplayText.UNKNOWN;
      default:
        return undefined;
    }
  }

  private static mapOrderStatusCode(order: VgaOrdersResponseV2): TweOpenOrdersOrderStatusCodeTypesEnum {
    switch (order.orderStatus) {
      case VgaOrdersResponseV2OrderStatusEnum.OPEN:
        return TweOpenOrdersOrderStatusCodeTypesEnum.OPEN;
      case VgaOrdersResponseV2OrderStatusEnum.ENTERED:
        return TweOpenOrdersOrderStatusCodeTypesEnum.ENTERED;
      case VgaOrdersResponseV2OrderStatusEnum.EXPIRED:
        return TweOpenOrdersOrderStatusCodeTypesEnum.EXPIRED;
      case VgaOrdersResponseV2OrderStatusEnum.COMPLETE:
        return TweOpenOrdersOrderStatusCodeTypesEnum.COMPLETE;
      case VgaOrdersResponseV2OrderStatusEnum.REJECTED:
        return TweOpenOrdersOrderStatusCodeTypesEnum.REJECTED;
      case VgaOrdersResponseV2OrderStatusEnum.PENDING:
        return TweOpenOrdersOrderStatusCodeTypesEnum.PENDING;
      default:
        return undefined;
    }
  }

  private static mapTransactionType(order: VgaOrdersResponseV2): TweOpenOrdersOrderInstructionCodeEnum {
    switch (order.transactionType) {
      case VgaOrdersResponseV2TransactionTypeEnum.BUY:
        return TweOpenOrdersOrderInstructionCodeEnum.BUY;
      case VgaOrdersResponseV2TransactionTypeEnum.BUY_TO_COVER:
        return TweOpenOrdersOrderInstructionCodeEnum.BUY_TO_COVER;
      case VgaOrdersResponseV2TransactionTypeEnum.EXCHANGE:
        return TweOpenOrdersOrderInstructionCodeEnum.EXCHANGE;
      case VgaOrdersResponseV2TransactionTypeEnum.SELL:
        return TweOpenOrdersOrderInstructionCodeEnum.SELL;
      case VgaOrdersResponseV2TransactionTypeEnum.SELL_SHORT:
        return TweOpenOrdersOrderInstructionCodeEnum.SELL_SHORT;
      case VgaOrdersResponseV2TransactionTypeEnum.SELL_SHORT_LOCATE_EXEMPT:
        return TweOpenOrdersOrderInstructionCodeEnum.SSLOCEX;
      default:
        return undefined;
    }
  }

  private static mapOrderValue(order: VgaOrdersResponseV2): string {
    return OrderMappingUtil.isMutualFund(order.security?.securityType) ? order.requestedAmount : order.orderValue;
  }

  private static mapOrderCategory(order: VgaOrdersResponseV2): TweOpenOrdersOrderCategoryTypesEnum {
    switch (order.orderType) {
      case VgaOrdersResponseV2OrderTypeEnum.MARKET:
        return TweOpenOrdersOrderCategoryTypesEnum.MARKET;
      case VgaOrdersResponseV2OrderTypeEnum.LIMIT:
        return TweOpenOrdersOrderCategoryTypesEnum.LIMIT;
      case VgaOrdersResponseV2OrderTypeEnum.STOP:
        return TweOpenOrdersOrderCategoryTypesEnum.STOP;
      case VgaOrdersResponseV2OrderTypeEnum.STOP_LIMIT:
        return TweOpenOrdersOrderCategoryTypesEnum.STOP_LIMIT;
      case VgaOrdersResponseV2OrderTypeEnum.MARKET_ON_CLOSE:
        return TweOpenOrdersOrderCategoryTypesEnum.MARKET_ON_CLOSE;
      default:
        return undefined;
    }
  }

  // eslint-disable-next-line complexity
  private static mapSecurityType(order: VgaOrdersResponseV2): string {
    switch (order.security?.securityType) {
      case SecurityDetailsSecurityTypeEnum.COMMON_STOCK:
        return SecurityClassTypes.COMMON_STOCK.toString();
      case SecurityDetailsSecurityTypeEnum.PREFERRED_STOCK:
        return SecurityClassTypes.PREFERRED_STOCK.toString();
      case SecurityDetailsSecurityTypeEnum.CONVERTIBLE_PREFERRED_STOCK:
        return SecurityClassTypes.CONVERTIBLE_PREFERRED_STOCK.toString();
      case SecurityDetailsSecurityTypeEnum.RIGHTS:
        return SecurityClassTypes.RIGHTS.toString();
      case SecurityDetailsSecurityTypeEnum.WARRANT:
        return SecurityClassTypes.WARRANT.toString();
      case SecurityDetailsSecurityTypeEnum.UNITS:
        return SecurityClassTypes.UNITS.toString();
      case SecurityDetailsSecurityTypeEnum.NON_VANGUARD_MUTUAL_FUND:
        return SecurityClassTypes.MUTUAL_FUND.toString();
      case SecurityDetailsSecurityTypeEnum.VANGUARD_MUTUAL_FUND:
        return SecurityClassTypes.MUTUAL_FUND.toString();
      case SecurityDetailsSecurityTypeEnum.CALL_OPTION:
        return SecurityClassTypes.CALL_OPTION.toString();
      case SecurityDetailsSecurityTypeEnum.PUT_OPTION:
        return SecurityClassTypes.PUT_OPTION.toString();
      case SecurityDetailsSecurityTypeEnum.CORPORATE_BOND:
        return SecurityClassTypes.CORPORATE_BOND.toString();
      case SecurityDetailsSecurityTypeEnum.CONVERTIBLE_BOND:
        return SecurityClassTypes.CONVERTIBLE_BOND.toString();
      case SecurityDetailsSecurityTypeEnum.MUNICIPAL_BOND:
        return SecurityClassTypes.MUNICIPAL_BOND.toString();
      case SecurityDetailsSecurityTypeEnum.US_TREASURY_BILL:
        return SecurityClassTypes.US_TREASURY_BILL.toString();
      case SecurityDetailsSecurityTypeEnum.US_TREASURY_NOTE:
        return SecurityClassTypes.US_TREASURY_NOTE.toString();
      case SecurityDetailsSecurityTypeEnum.US_TREASURY_BOND:
        return SecurityClassTypes.US_TREASURY_BOND.toString();
      case SecurityDetailsSecurityTypeEnum.OTHER_GOVERNMENT:
        return SecurityClassTypes.OTHER_GOVERNMENT.toString();
      case SecurityDetailsSecurityTypeEnum.US_TREASURY_ZERO_COUPON:
        return SecurityClassTypes.US_TREASURY_ZERO_COUPON.toString();
      case SecurityDetailsSecurityTypeEnum.GOVERNMENT_MORTGAGE:
        return SecurityClassTypes.GOVERNMENT_MORTGAGE.toString();
      case SecurityDetailsSecurityTypeEnum.CERTIFICATES_OF_DEPOSIT:
        return SecurityClassTypes.CERTIFICATES_OF_DEPOSIT.toString();
      case SecurityDetailsSecurityTypeEnum.NON_VANGUARD_ETF:
        return SecurityClassTypes.ETF.toString();
      case SecurityDetailsSecurityTypeEnum.VANGUARD_ETF:
        return SecurityClassTypes.VANGUARD_ETF.toString();
      case SecurityDetailsSecurityTypeEnum.MISCELLANEOUS_FIXED_INCOME:
        return SecurityClassTypes.MISCELLANEOUS_FIXED_INCOME.toString();
      default:
        return undefined;
    }
  }

  private static mapStopLimitPrice(order: VgaOrdersResponseV2): string {
    return order.orderType === VgaOrdersResponseV2OrderTypeEnum.STOP_LIMIT ? order.limitPrice : undefined;
  }

  private static mapPrice(order: VgaOrdersResponseV2): string {
    switch (order.orderType) {
      case VgaOrdersResponseV2OrderTypeEnum.LIMIT:
        return order.limitPrice;
      case VgaOrdersResponseV2OrderTypeEnum.STOP_LIMIT:
      case VgaOrdersResponseV2OrderTypeEnum.STOP:
        return order.stopPrice;
      default:
        return undefined;
    }
  }

  private static mapOrderDuration(order: VgaOrdersResponseV2): string {
    switch (order.orderDuration) {
      case VgaOrdersResponseV2OrderDurationEnum.DAY:
        return TweOpenOrdersOrderDurationTypesEnum.DAY;
      case VgaOrdersResponseV2OrderDurationEnum.EVENING:
        return TweOpenOrdersOrderDurationTypesEnum.EVENING;
      case VgaOrdersResponseV2OrderDurationEnum.GOOD_TILL_CANCEL:
        return TweOpenOrdersOrderDurationTypesEnum.SIXTY_DAY;
      case VgaOrdersResponseV2OrderDurationEnum.GOOD_TILL_DATE:
        return TweOpenOrdersOrderDurationTypesEnum.GOOD_TILL_DAY;
      default:
        return undefined;
    }
  }
}
