import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ButtonComponent } from '@vg-constellation/angular-18/button';
import { fromEvent, Subscription, tap, throttleTime } from 'rxjs';

@Directive({
  selector: '[appSubmitButtonOnce]',
})
export class SubmitButtonOnceDirective implements OnDestroy, AfterViewInit {
  // Decorators...
  @Input() set disabled(val) {
    if (val && this.button?.nativeElement) {
      this.button.nativeElement.disabled = true;
    }
  }

  @Input() clickLockTime = 500;
  @Output() clickFunc = new EventEmitter();

  // Private variables..
  private timeout: ReturnType<typeof setTimeout>;
  private subscription: Subscription;
  button: ElementRef;

  constructor(private readonly buttonComponent: ButtonComponent) {}

  ngAfterViewInit(): void {
    this.button = this.buttonComponent.elRef;

    this.subscription = fromEvent(this.button.nativeElement, 'click')
      .pipe(
        tap(() => {
          this.button.nativeElement.disabled = true;
          this.timeout = setTimeout(() => {
            if (this.button?.nativeElement) {
              this.button.nativeElement.removeAttribute('disabled');
            }
          }, this.clickLockTime);
        }),
        throttleTime(this.clickLockTime)
      )
      .subscribe((e) => {
        this.clickFunc.emit(e);
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
    this.subscription.unsubscribe();
    this.clickFunc.unsubscribe();
  }
}
