import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdobeAnalyticsService } from '@app/core/services';
import {
  Account,
  AccountSelectorAccount,
  AccountSelectorComponentTrackingEventHandler,
  AccountSelectorConfig,
  AccountSelectorConfigProvider,
  AccountSelectorControlPlaneProvider,
  AccountSelectorTrackingEvent,
  BrokerageAccountProvider,
  FormControlProvider,
  JsonContent,
} from '@app/etfs-equities/models';
import { AccountService, TradeTicketService, WindowService } from '@app/etfs-equities/services';
import { createLoadAccountsAction, createSelectAccountAction, TayneState } from '@app/etfs-equities/store';
import { NgZoneUtility } from '@app/shared/utilities/ngZone/ngzone.utility';
import content from '@content/content.json';
import { selectAccounts, selectSelectedAccount } from '@etfs-equities/store/selectors';
import { selectIsIncapacitated } from '@etfs-equities/store/selectors/client-data/client-data.selectors';
import { select, Store } from '@ngrx/store';
import { SingleErrorFormControl } from '@vanguard/trade-standard-forms-lib-ng-18';
import { filter, first, map, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-accounts-control',
  templateUrl: './accounts-control.component.html',
  styleUrls: ['./accounts-control.component.scss'],
})
export class AccountsControlComponent implements OnInit, OnDestroy {
  // Decorators
  @Input() isChangeOrder = false;
  @Input() showAccountDetails = false;
  @Output() hasAccountRetrievalErrorEmitter = new EventEmitter<boolean>();

  // Public Properties
  public content: JsonContent = content;
  public hasAccountRetrievalError = false;

  public accounts$: Observable<Account.Account[]>;
  public selectedAccount$: Observable<Account.Account>;
  public isIncapacitated$: Observable<boolean>;

  // MFE Account Selector Config
  readonly accountSelectorConfigProvider: AccountSelectorConfigProvider;
  readonly brokerageAccountProvider: BrokerageAccountProvider;
  readonly formControlProvider: FormControlProvider;
  readonly accountSelectorControlPlaneProvider: AccountSelectorControlPlaneProvider;
  readonly selectedAccountProvider: BrokerageAccountProvider;
  readonly accountSelectorTrackingEvent: AccountSelectorComponentTrackingEventHandler;

  // Private Properties
  private readonly accountControl: SingleErrorFormControl<string>;
  private readonly unsubscribe$ = new Subject<void>();
  private readonly cdr = inject(ChangeDetectorRef);

  constructor(
    private readonly store: Store<TayneState>,
    private readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly tradeTicketService: TradeTicketService,
    private readonly adobeService: AdobeAnalyticsService,
    private readonly ngZoneUtility: NgZoneUtility,
    private readonly windowService: WindowService
  ) {
    this.accountControl = tradeTicketService.tradeTicket.controls.accountId;

    this.brokerageAccountProvider = {
      getBrokerageAccounts: (): Observable<AccountSelectorAccount[]> =>
        this.store
          .select(selectAccounts)
          .pipe(
            map((accounts) => accounts.map((account) => ({ ...account, accountId: account?.accountId?.toString() })))
          ),
    };

    this.selectedAccountProvider = {
      getBrokerageAccounts: (): Observable<AccountSelectorAccount[]> =>
        this.store.pipe(
          select(selectSelectedAccount),
          map((account) => [{ ...account, accountId: account?.accountId?.toString() }])
        ),
    };

    this.accountSelectorConfigProvider = {
      getConfig: (): AccountSelectorConfig => ({
        hideDetails: !this.showAccountDetails,
        showBalanceInAccountSelector: false,
        autoSelectOnlyAccount: true,
        displayMarginBuyingPower: true,
        suppressProvisionalVbaAccountAlerts: this.windowService.getIsBeacon(),
        readOnly: this.isChangeOrder,
      }),
    };

    this.formControlProvider = {
      getFormControl: () => this.accountControl,
    };

    this.accountSelectorControlPlaneProvider = {
      getControlPlane: () => this.tradeTicketService.accountSelectorControlPlane$,
    };

    this.accountSelectorTrackingEvent = {
      onTrackingEvent: (trackingEvent: AccountSelectorTrackingEvent) => this.trackAccountSelectorEvent(trackingEvent),
    };
  }

  ngOnInit(): void {
    this.isIncapacitated$ = this.store.pipe(select(selectIsIncapacitated));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.accounts$ = this.store.pipe(select(selectAccounts));

    this.watchForAccountAutoSelectConditions();
    this.watchForAccountRetry();
    this.watchForAccountRetrievalError();
    this.watchForAccountSelection();

    this.loadAccounts();
  }

  selectAccount(brokerageAccountNumber: string) {
    this.store.dispatch(createSelectAccountAction(brokerageAccountNumber));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Loads accounts if there are none in the store and the user is not incapacitated
   */
  private loadAccounts() {
    this.hasAccountRetrievalError = false;
    // Fetch accounts if there are none in the store.
    this.accounts$
      .pipe(
        first(),
        withLatestFrom(this.isIncapacitated$),
        filter(([accounts, isIncapacitated]) => accounts.length === 0 && !isIncapacitated),
        tap(() => this.store.dispatch(createLoadAccountsAction()))
      )
      .subscribe();
  }

  private watchForAccountAutoSelectConditions() {
    this.accounts$
      .pipe(
        withLatestFrom(this.selectedAccount$, this.route.queryParamMap.pipe(map((params) => params.get('accountId')))),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([accounts, selectedAccount, prefillAccountId]) => {
        // If an account was already selected, do nothing.
        if (selectedAccount) return;

        // Autoselect the account given in the route query params.
        if (accounts.length && prefillAccountId) {
          const account = accounts.find(({ accountId }) => accountId.toString() === prefillAccountId);

          if (account) {
            this.accountControl.setValue(`${account.accountId}`);
            this.selectAccount(account.brokerageAccountNumber);
          } else if (this.isChangeOrder) {
            this.hasAccountRetrievalErrorEmitter.emit(true);
          }
        }
      });
  }

  /**
   * Watch for a retry request and load accounts when received
   */
  private watchForAccountRetry() {
    this.accountService.accountRetrievalRetry$
      .pipe(
        tap(() => this.loadAccounts()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  /**
   * Watch for selected Account and sync with MFE
   */
  private watchForAccountSelection() {
    // sync store with MFE selection
    this.accountControl.valueChanges
      .pipe(
        this.ngZoneUtility.bridgeZones(),
        withLatestFrom(this.selectedAccount$, this.accounts$),
        filter(
          ([accountId, selectedAccount, accounts]) => accounts?.length > 0 && +accountId !== selectedAccount?.accountId
        ),
        tap(([accountId, _, accounts]) => {
          const selectedAccount = accounts.find((account) => account.accountId === +accountId);

          this.selectAccount(selectedAccount?.brokerageAccountNumber || null);

          if (selectedAccount) {
            this.adobeService.sendAdobeLaunchProcess('Select Account');
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        // when MFE sets form control value, we need to trigger change detection in TWE
        this.cdr.detectChanges();
      });

    // sync MFE selection with store
    this.selectedAccount$
      .pipe(
        filter(
          (selectedAccount) =>
            selectedAccount?.accountId !== undefined && selectedAccount.accountId !== +this.accountControl.value
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((selectedAccount) => {
        this.accountControl.setValue(`${selectedAccount.accountId || ''}`, {
          emitEvent: false,
          onlySelf: true,
        });
      });
  }

  /**
   * Waits for a notification of an accounts retrieval error and sets the error flag
   */
  private watchForAccountRetrievalError() {
    this.accountService.accountRetrievalError$
      .pipe(
        tap(() => {
          this.hasAccountRetrievalError = true;
          this.hasAccountRetrievalErrorEmitter.emit(true);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  /**
   * Handler for tracking events from the Account Selector MFE
   * @param trackingEvent event type
   * @returns void
   */
  private trackAccountSelectorEvent(trackingEvent: AccountSelectorTrackingEvent): void {
    switch (trackingEvent) {
      case AccountSelectorTrackingEvent.CLICK_PVBA_RESTRICTIONS_BUTTON:
        this.adobeService.sendAdobeTrackingOnClick('Remove Restriction', 'button');
        break;
      default:
        return;
    }
  }
}
