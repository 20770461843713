@import '@vg-constellation/angular-18/styles/variables';

tcx-product-list-table {
  p {
    margin-bottom: 0;
  }

  tcx-product-list-table-cell:last-child .cell-line {
    min-width: 180px;
  }
}

.holdings-table-header {
  padding-left: $c11n-size-space-1x;
}
