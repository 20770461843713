import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { selectIsScreenXSmall } from '@app/etfs-equities/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-shingle',
  templateUrl: './shingle.component.html',
  styleUrl: './shingle.component.scss',
})
export class ShingleComponent implements OnInit {
  @Input() iconType = 'error';
  @Input() message: string;
  @Input() primaryButtonLabel: string;
  @Input() primaryButtonIconName = '';
  @Input() secondaryButtonLabel: string;
  @Input() secondaryButtonIconName = '';

  @Output() primaryButtonClicked = new EventEmitter();
  @Output() secondaryButtonClicked = new EventEmitter();

  isScreenXSmall$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.isScreenXSmall$ = this.store.select(selectIsScreenXSmall);
  }
}
