import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CostBasisService } from '@app/etfs-equities/services/cost-basis/cost-basis.service';
import content from '@content/content.json';
import { JsonContent } from '@etfs-equities/models';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'twe-cost-basis-unavailable-modal',
  templateUrl: './cost-basis-unavailable-modal.component.html',
  styleUrls: ['./cost-basis-unavailable-modal.component.scss'],
})
export class CostBasisUnavailableModalComponent implements OnInit, OnDestroy {
  // Decorators...
  @ViewChild('costBasisMethodUnavailableModal')
  modal: ModalDialogComponent;

  // Public variables...
  content: JsonContent = content;

  // Public observables/subjects...
  openUnavailableModalSubscription: Subscription;

  public constructor(private readonly costBasisService: CostBasisService) {}

  ngOnInit() {
    this.openUnavailableModalSubscription = this.costBasisService.openUnavailableModal$.subscribe(() =>
      this.modal.openModalDialog()
    );
  }

  ngOnDestroy() {
    this.openUnavailableModalSubscription.unsubscribe();
  }
}
