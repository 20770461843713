import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';

@Component({
  selector: 'twe-spec-id-info-modal',
  templateUrl: './spec-id-info-modal.component.html',
  styleUrls: ['./spec-id-info-modal.component.scss'],
})
export class SpecIdInfoModalComponent {
  // Decorators
  @ViewChild('modal') modal: ModalDialogComponent;

  // Properties
  content: JsonContent = content;
}
