import { HttpErrorResponse } from '@angular/common/http';
import { CostBasis } from '@app/etfs-equities/models';
import { Action } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-18';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum CostBasisActionTypes {
  LOAD_LOTS = '[Cost Basis] Load Lots',
  LOAD_LOTS_SUCCESS = '[Cost Basis] Load Lots Success',
  LOAD_LOTS_ERROR = '[Cost Basis] Load Lots Error',
  CLEAR_LOAD_LOTS_ERROR = '[Cost Basis] Clear Load Lots Error',
  UPDATE_LOT_FORM = '[Cost Basis] Update Lot Form',
  LOAD_AVAILABLE_METHODS = '[Cost Basis] Load Available Trading Methods',
  LOAD_AVAILABLE_METHODS_SUCCESS = '[Cost Basis] Load Available Trading Methods Success',
  LOAD_AVAILABLE_METHODS_ERROR = '[Cost Basis] Load Available Trading Methods Error',
  AVAILABLE_METHODS_LOADED = '[Cost Basis] Available Methods already loaded',
  AUTO_SELECT_METHOD = '[Cost Basis] Auto Select Cost Basis Method',
  CLEAR_LOAD_AVAILABLE_METHODS_ERROR = '[Cost Basis] Clear Load Available Methods Error',
  CLEAR_AVAILABLE_METHODS = '[Cost Basis] Clear Available Methods',
  SUBMIT_EDIT_COST_BASIS = '[Cost Basis] Submit',
  SET_IS_EDIT_COST_BASIS = '[ChangeOrder] Set isEditCostBasis',
  REFRESH_COST_BASIS_FOR_SELECTED_ACCOUNT = '[Cost Basis] Refresh Cost Basis For Account',
  LOAD_COST_BASIS_FOR_ACCOUNT_SUCCESS = '[Cost Basis] Load Cost Basis For Account Success',
  LOAD_COST_BASIS_FOR_ACCOUNT_ERROR = '[Cost Basis] Load Cost Basis For Account Error',
  SET_PRESELECTED_COSTBASIS_METHOD = '[Cost Basis] Set Pre-Selected Cost Basis Method',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class LoadLotsAction implements Action {
  readonly type = CostBasisActionTypes.LOAD_LOTS;
  constructor(public payload: CostBasis.LoadLotsActionPayload) {}
}

export class LoadLotsSuccessAction implements Action {
  readonly type = CostBasisActionTypes.LOAD_LOTS_SUCCESS;
  constructor(public payload: CostBasis.LotResponse) {}
}

export class UpdateLotFormAction implements Action {
  readonly type = CostBasisActionTypes.UPDATE_LOT_FORM;
  constructor(public payload: { [key: string]: any }) {}
}

export class LoadLotsErrorAction implements Action {
  readonly type = CostBasisActionTypes.LOAD_LOTS_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearLoadLotsErrorAction implements Action {
  readonly type = CostBasisActionTypes.CLEAR_LOAD_LOTS_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadAvailableMethodsAction implements Action {
  constructor(public payload: { openErrorModal?: boolean }) {}
  readonly type = CostBasisActionTypes.LOAD_AVAILABLE_METHODS;
}

export class LoadAvailableMethodsSuccessAction implements Action {
  readonly type = CostBasisActionTypes.LOAD_AVAILABLE_METHODS_SUCCESS;
  constructor(public payload: CostBasis.CostBasisTradeMethodsResponse) {}
}

export class LoadAvailableMethodsErrorAction implements Action {
  readonly type = CostBasisActionTypes.LOAD_AVAILABLE_METHODS_ERROR;
  constructor(public payload: { error: HttpErrorResponse; options?: { openErrorModal: boolean } }) {}
}

export class AvailableMethodsLoadedAction implements Action {
  readonly type = CostBasisActionTypes.AVAILABLE_METHODS_LOADED;
}

export class AutoSelectMethodAction implements Action {
  readonly type = CostBasisActionTypes.AUTO_SELECT_METHOD;
  constructor(public payload: CostBasis.MethodAutoSelectionModel) {}
}

export class ClearLoadAvailableMethodsErrorAction implements Action {
  readonly type = CostBasisActionTypes.CLEAR_LOAD_AVAILABLE_METHODS_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearAvailableMethodsAction implements Action {
  readonly type = CostBasisActionTypes.CLEAR_AVAILABLE_METHODS;
}

export class SubmitEditCostBasisAction implements Action {
  readonly type = CostBasisActionTypes.SUBMIT_EDIT_COST_BASIS;
}

export class SetIsEditCostBasisAction implements Action {
  readonly type = CostBasisActionTypes.SET_IS_EDIT_COST_BASIS;
  constructor(public payload: boolean) {}
}

export class RefreshCostBasisForSelectedAccountAction implements Action {
  readonly type = CostBasisActionTypes.REFRESH_COST_BASIS_FOR_SELECTED_ACCOUNT;
}

export class LoadCostBasisForAccountSuccessAction implements Action {
  readonly type = CostBasisActionTypes.LOAD_COST_BASIS_FOR_ACCOUNT_SUCCESS;
  constructor(public payload: CostBasis.CostBasisForSelectedAccountResponse) {}
}

export class LoadCostBasisForAccountErrorAction implements Action {
  readonly type = CostBasisActionTypes.LOAD_COST_BASIS_FOR_ACCOUNT_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetPreselectedCostbasisMethodAction implements Action {
  readonly type = CostBasisActionTypes.SET_PRESELECTED_COSTBASIS_METHOD;
  constructor(
    public payload: {
      accountId: number;
      holdingId: number;
      preSelectedMethod: CostBasisMethod;
    }
  ) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createLoadLotsAction = (
  accountId: number,
  positionId: number,
  orderId?: string,
  transactionType?: string
) => {
  return new LoadLotsAction({ accountId, positionId, orderId, transactionType });
};

export const createLoadLotsSuccessAction = (lots: CostBasis.LotResponse) => {
  return new LoadLotsSuccessAction(lots);
};

export const createUpdateLotFormAction = (formValue: { [key: string]: any }) => {
  return new UpdateLotFormAction(formValue);
};

export const createLoadLotsErrorAction = (error: HttpErrorResponse) => {
  return new LoadLotsErrorAction(error);
};

export const createClearLoadLotsErrorAction = (error: HttpErrorResponse) => {
  return new ClearLoadLotsErrorAction(error);
};

export const createLoadAvailableMethodsAction = (options: { openErrorModal: boolean } = { openErrorModal: true }) => {
  return new LoadAvailableMethodsAction(options);
};

export const createLoadAvailableMethodsSuccessAction = (response: CostBasis.CostBasisTradeMethodsResponse) => {
  return new LoadAvailableMethodsSuccessAction(response);
};

export const createLoadAvailableMethodsErrorAction = (
  error: HttpErrorResponse,
  options: { openErrorModal: boolean } = { openErrorModal: true }
) => {
  return new LoadAvailableMethodsErrorAction({ error, options });
};

export const createAvailableMethodsLoadedAction = () => {
  return new AvailableMethodsLoadedAction();
};

export const createAutoSelectMethodAction = (autoSelectPayload: CostBasis.MethodAutoSelectionModel) => {
  return new AutoSelectMethodAction(autoSelectPayload);
};

export const createClearLoadAvailableMethodsErrorAction = (error: HttpErrorResponse) => {
  return new ClearLoadAvailableMethodsErrorAction(error);
};

export const createClearAvailableMethodsAction = () => {
  return new ClearAvailableMethodsAction();
};

export const createSubmitEditCostBasisAction = () => {
  return new SubmitEditCostBasisAction();
};

export const createSetIsEditCostBasisAction = (flag: boolean) => {
  return new SetIsEditCostBasisAction(flag);
};

export const createRefreshCostBasisForSelectedAccountAction = () => {
  return new RefreshCostBasisForSelectedAccountAction();
};

export const createLoadCostBasisForAccountSuccessAction = (response: CostBasis.CostBasisForSelectedAccountResponse) => {
  return new LoadCostBasisForAccountSuccessAction(response);
};

export const createLoadCostBasisForAccountErrorAction = (error: HttpErrorResponse) => {
  return new LoadCostBasisForAccountErrorAction(error);
};

export const createSetPreselectedCostbasisMethodAction = (
  accountId: number,
  holdingId: number,
  preSelectedMethod: CostBasisMethod
) => {
  return new SetPreselectedCostbasisMethodAction({ accountId, holdingId, preSelectedMethod });
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type CostBasisActionTypesUnion =
  | LoadLotsAction
  | LoadLotsSuccessAction
  | LoadLotsErrorAction
  | ClearLoadLotsErrorAction
  | UpdateLotFormAction
  | LoadAvailableMethodsAction
  | LoadAvailableMethodsSuccessAction
  | LoadAvailableMethodsErrorAction
  | AvailableMethodsLoadedAction
  | AutoSelectMethodAction
  | ClearLoadAvailableMethodsErrorAction
  | ClearAvailableMethodsAction
  | SubmitEditCostBasisAction
  | SetIsEditCostBasisAction
  | RefreshCostBasisForSelectedAccountAction
  | LoadCostBasisForAccountSuccessAction
  | LoadCostBasisForAccountErrorAction
  | SetPreselectedCostbasisMethodAction;
