import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { CancelOrderDuration, OrderEnums } from '@app/etfs-equities/enums';
import { JsonContent, Order, TweQuote } from '@app/etfs-equities/models';
import { OrderCancel } from '@app/etfs-equities/models/order-cancel';
import { MarketDataService, WindowService } from '@app/etfs-equities/services';
import {
  createRefreshQuoteAction,
  selectActionIsBuyToCoverOrSellShort,
  selectOrder,
  selectOrderEveningDuration,
  selectQuote,
  selectQuoteIsSubPenny,
  selectQuoteIsVgETF,
  TayneState,
} from '@app/etfs-equities/store';
import {
  selectOrderCancel,
  selectOrderCancelEveningDuration,
} from '@app/etfs-equities/store/selectors/order-cancel/order-cancel.selector';
import content from '@content/content.json';
import { ExtendedHoursService } from '@etfs-equities/services/extended-hours/extended-hours.service';
import { mapToTweEveningQuoteModel, mapToTweQuoteModel } from '@etfs-equities/utils';
import { select, Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-18';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'twe-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements OnInit {
  //  Static variables...

  public static componentCount = 0;

  //  Decorators...

  @Input() isChangeOrder = false;

  @Input() isCancelOrder = false;

  @Input() isAfterMarket = false;

  @ViewChild('exchangeNameModal')
  exchangeNameModal: ModalDialogComponent;

  //  Public observables/subjects...

  quote$: Observable<TweQuote>;

  isSubPenny$: Observable<boolean>;

  quoteIsVgEtf$: Observable<boolean>;

  dollarBasedEnabled$: Observable<boolean>;

  actionIsBuyToCoverOrSellShort$: Observable<boolean>;

  order$: Observable<Order.Order>;

  orderCancel$: Observable<OrderCancel>;

  //  Public variables...

  content: JsonContent = content;
  uniqueID: string;
  exchangeId: string;
  exchangeName: string;
  initialState = this.marketDataService.panelIsOpen;
  readonly orderDurationEnum = OrderEnums.Durations;
  readonly cancelOrderDurationEnum = CancelOrderDuration;
  protected readonly timestampSizeEnum = TimestampSize;

  constructor(
    public marketDataService: MarketDataService,
    private readonly store: Store<TayneState>,
    public readonly adobeService: AdobeAnalyticsService,
    public readonly windowService: WindowService,
    private readonly extendedHoursService: ExtendedHoursService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  // Getters/Setters...

  get loading() {
    if (this.marketDataService.isLoading) {
      return 'Loading...';
    }

    if (this.marketDataService.isRefreshing) {
      return 'Refreshing...';
    }
    return undefined;
  }

  ngOnInit() {
    QuoteComponent.componentCount++;
    this.uniqueID = `${QuoteComponent.componentCount}`;
    this.quote$ = this.getQuote();
    this.isSubPenny$ = this.store.pipe(select(selectQuoteIsSubPenny));
    this.quoteIsVgEtf$ = this.store.pipe(select(selectQuoteIsVgETF));
    this.actionIsBuyToCoverOrSellShort$ = this.store.pipe(select(selectActionIsBuyToCoverOrSellShort));
    this.order$ = this.store.pipe(select(selectOrder));
    this.orderCancel$ = this.store.pipe(select(selectOrderCancel));

    this.dollarBasedEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );
  }

  refresh() {
    this.store.dispatch(createRefreshQuoteAction());
    this.adobeService.sendAdobeTrackingOnClick('Real-Time Quote: Refresh', 'icon');
  }

  openExchangeNameModal(id: string, exchangeName: string) {
    this.exchangeId = id;
    this.exchangeName = exchangeName;
    this.exchangeNameModal.openModalDialog();
  }

  handlePanelToggle(event: string) {
    const isOpen = event === 'open';
    const actionType = isOpen ? 'Show' : 'Hide';

    // Sync the panel state to a stateful service.
    this.marketDataService.panelIsOpen = isOpen;

    this.adobeService.sendAdobeTrackingOnClick(`Real-Time Quote: ${actionType} detailed quote`, 'link');
  }

  getQuote() {
    return combineLatest([
      this.store.select(selectQuote),
      this.store.select(selectOrderEveningDuration),
      this.store.select(selectOrderCancelEveningDuration),
    ]).pipe(
      map(([quote, isEveningDuration, isCancelEveningDuration]) => {
        if (!quote || (quote?.eveningTradingEnabled === 'NO' && this.isAfterMarket)) {
          return null;
        }
        const isWithinEcnHours = this.extendedHoursService.isWithinEcnHours();
        if ((isWithinEcnHours && isEveningDuration) || isCancelEveningDuration) {
          return mapToTweEveningQuoteModel(quote);
        } else {
          return mapToTweQuoteModel(quote);
        }
      })
    );
  }
}
