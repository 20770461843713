import { Injectable } from '@angular/core';
import { UILogCode } from '@app/core/enums/ui-log-code-enum';
import { LoggerService } from '@app/core/services';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { OrderEnums } from '@app/etfs-equities/enums';
import { Order, ProspectusAudit } from '@app/etfs-equities/models';
import { environment } from '@env/environment';
import { APP_PREFIX } from '@env/environment.constants';
import { OrderUtil } from '@etfs-equities/utils';
import { AngularOpenTelemetryService, SpawningSpan } from '@vanguard/invest-otel-lib/angular';
import { InvestSemanticAttributes } from '@vanguard/invest-semantic-conventions';
import { ProspectusData } from '@vanguard/trade-ui-components-lib-ng-18';

declare global {
  let auditScript: ProspectusAudit;
}
@Injectable({
  providedIn: 'root',
})
export class ProspectusService {
  readonly content = require('@content/content.json');

  constructor(
    private readonly loggerService: LoggerService,
    private readonly tracerService: AngularOpenTelemetryService
  ) {}

  getProspectusUrl(order: Order.Order) {
    return `${environment.prospectus.baseUrl}?securityId=${order?.cusip}&securityIdType=CUSIP&consumerApplicationCode=${CONSTANTS.APP_PREFIX}`;
  }

  isProspectusEligible(order: Order.Order): boolean {
    return order?.transactionType === OrderEnums.TransactionTypes.BUY && order?.prospectusEligibility === true;
  }

  getProspectusData(order: Order.Order): ProspectusData {
    return {
      brokerageAccountNumber: order.accountNumber,
      prospectusUrl: this.getProspectusUrl(order),
      prospectusEligible: this.isProspectusEligible(order),
      appPrefix: CONSTANTS.APP_PREFIX,
      productType: 'BROKERAGE',
      securityId: order.cusip,
      securityIdType: 'CUSIP',
      prospectusAuditService: {
        auditProspectus: (data, prospectusLinkId, postFormId, appPrefix = CONSTANTS.APP_PREFIX, prospectusLinkUrl) => {
          if (auditScript) {
            auditScript.auditProspectusWithLinkUrl(data, prospectusLinkId, postFormId, appPrefix, prospectusLinkUrl);
          } else {
            this.loggerService.error(UILogCode.PROSPECTUS_LINK_ERROR, 'Prospectus Audit Script is not available.');
          }
        },
      },
      viewProspectus: this.content.labels.viewProspectus,
    };
  }

  logProspectusData(order: Order.Order): void {
    this.tracerService.createActive(UILogCode.PROSPECTUS_LINK, (span: SpawningSpan) => {
      span.setAttributes({
        [InvestSemanticAttributes.SERVICE_APP_PREFIX]: APP_PREFIX,
        accountNumber: OrderUtil.mask(order.accountNumber),
        orderId: order.orderId,
      });
      span.addEvent('Prospectus Link Clicked');
      span.end();
    });
  }
}
