import { Component, Input, ViewChild } from '@angular/core';
import { AdobeAnalyticsService } from '@app/core/services';
import { WindowService } from '@app/etfs-equities/services';
import { createSetExitBtnStatusAction } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { JsonContent } from '@etfs-equities/models';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';

@Component({
  selector: 'twe-exit-trade-modal',
  templateUrl: './exit-trade-modal.component.html',
  styleUrls: ['./exit-trade-modal.component.scss'],
})
export class ExitTradeModalComponent {
  @ViewChild('modal')
  modal: ModalDialogComponent;

  @Input()
  exitUrl = environment.secureSiteUrls.dashboard;

  content: JsonContent = content;

  constructor(
    private readonly store: Store,
    private readonly adobeService: AdobeAnalyticsService,
    private windowService: WindowService
  ) {}

  continueInTradeApp() {
    this.adobeService.sendAdobeLaunchProcess('equityETFTicket-Continue Order');
    this.store.dispatch(createSetExitBtnStatusAction(false));
  }

  exitTradeApp() {
    this.adobeService.sendProcessAbandoned({ processAbandonmentType: 'confirm exit' });
    this.windowService.navigateToExternalLink(this.exitUrl);
  }
}
