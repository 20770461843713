import { Component, ViewChild } from '@angular/core';
import content from '@content/content.json';
import { JsonContent } from '@etfs-equities/models';
import { FocusUtil } from '@etfs-equities/utils';
import { ModalDialogComponent } from '@vg-constellation/angular-18/modal-dialog';

@Component({
  selector: 'twe-symbol-modal',
  templateUrl: './symbol-modal.component.html',
  styleUrls: ['./symbol-modal.component.scss'],
})
export class SymbolModalComponent {
  @ViewChild('modal')
  modal: ModalDialogComponent;

  content: JsonContent = content;

  open(event: Event) {
    FocusUtil.setTooltipFocusElement(event);
    this.modal.openModalDialog();
  }

  onClose() {
    FocusUtil.setFocus();
  }
}
