import { Component, Input } from '@angular/core';
import { LabelSize } from '@vg-constellation/angular-18/label';
import { TooltipDirection } from '@vg-constellation/angular-18/tooltip';

@Component({
  selector: 'twe-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent {
  @Input()
  for = '';

  @Input()
  labelText = '';

  @Input()
  size: LabelSize = 'medium';

  @Input()
  direction: TooltipDirection = 'right';

  @Input()
  tooltipHeader: string;

  @Input()
  noLabel = false;

  @Input()
  autoOrientation = true;
}
