import { CostBasisUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import {
  createSingleValidationError,
  RehydratableFormGroup,
  SingleErrorValidationError,
} from '@vanguard/trade-standard-forms-lib-ng-18';
import { LotTableRowControls } from '@vanguard/trade-ui-components-lib-ng-18';

export const createNumberOfSharesSelectedValidator = (
  lotTableRowGroup: RehydratableFormGroup<LotTableRowControls>,
  quantity: number
): SingleErrorValidationError | null => {
  if (CostBasisUtil.formatLotShares(lotTableRowGroup.controls.numberOfSharesSelected.value) > Math.abs(quantity)) {
    return createSingleValidationError(
      'max',
      content.selectSharesPage.lotsSelectionContent.lotTableContent.numberOfSharesValidationError
    );
  }
  return null;
};
